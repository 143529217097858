@media (max-width: 1367px) {
  div {
    .MuiInputBase-formControl {
      .MuiInputBase-input {
        padding: 14.15px 20px 10.15px;
        font-size: 18px;
        line-height: 20px;
        min-height: 48px;
      }
      textarea.MuiInputBase-input {
        padding: 10px;
      }
    }
    .custom-dropdowninput > span {
      top: 10px;
    }
    .circle-card {
      .box-wrap {
        padding: 16px 20px 8px 20px;
      }
    }
  }
  .help-center-wrapper {
    .started-mainpage {
      .resource-section {
        .MuiGrid-root {
          .MuiGrid-grid-xs-3 {
            &:first-child {
              max-width: 35%;
              flex-basis: 35%;
              .category-list {
                .categories {
                  ul {
                    .category-item {
                      a {
                        padding-left: 16px;
                        padding-right: 16px;
                        min-height: 48px;
                        .header-primary {
                          font-size: 18px;
                          line-height: normal;
                        }
                      }
                    }
                  }
                }
              }
            }
            &:nth-child(3) {
              max-width: 65%;
              flex-basis: 65%;
              margin-left: auto;
              .create-alert {
                margin-bottom: 16px;
                .title {
                  font-size: 24px !important;
                  margin: 0 auto 12px;
                }
              }
              .title-primary {
                font-size: 24px;
                line-height: normal;
              }
            }
          }
          .MuiGrid-grid-xs-6 {
            max-width: 65%;
            flex-basis: 65%;
            .started-middle-page {
              .faq-section {
                .MuiPaper-root {
                  padding: 16px;
                  .MuiButtonBase-root {
                    .MuiAccordionSummary-content {
                      p {
                        font-size: 18px;
                        line-height: normal;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .topics-wrapper {
    .chatModule {
      .row {
        > .col-md-3 {
          flex: 0 0 35%;
          max-width: 35%;
        }
        > .col-md-6 {
          flex: 0 0 65%;
          max-width: 65%;
        }
        > .col-md-3:last-child {
          flex: 0 0 65%;
          max-width: 65%;
          margin-left: auto;
        }
        .ChatFilter {
          .ChatFilterOptions {
            .MuiPaper-root {
              .MuiAccordionSummary-root {
                padding: 20px 12px 16px 12px;
                .MuiIconButton-edgeEnd {
                  margin-right: 0 !important;
                }
              }
            }
            .chatUserItems {
              .ChatItem {
                padding: 12px 12px;
              }
            }
          }
        }
        .chatboxWrapper {
          .PostContentWrap {
            .wizzard-form {
              .CreatePostWrap {
                padding: 16px 12px 16px;
              }
            }
            .PostContentItem {
              padding: 16px 12px 16px;
              .PostHead {
                margin: 0 0 12px 0;
                .profileImg {
                  margin: 0 12px 0 0;
                }
              }
              .topic-post {
                .header-primary {
                  margin-bottom: 8px;
                  margin-top: 12px;
                  h3 {
                    margin: 0;
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
        .ChatUserProfile {
          padding: 16px;
        }
      }
    }
  }
}
@media (max-width: 1280px) {
  .main-content {
    padding: 75px 10px 70px 60px;
    overflow-x: hidden;
    header {
      &.header {
        width: calc(100% - 50px);
        left: 50px;
        padding: 8px 15px;
        height: 60px;
        .HeaderLeft {
          width: 50%;
        }
      }
    }
    .nav-sidebar {
      width: 50px;
      z-index: 1;
      .nav-header {
        min-height: 58px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
      }
      .navmenu {
        height: calc(100% - 75px);
        .left-menu {
          padding: 0 4px;
          ul.left-ul {
            li {
              a {
                height: 34px;
                width: 34px;
                .icon-wrapper {
                  width: 42px !important;
                  height: 42px !important;
                  + span {
                    font-size: 0;
                    display: none;
                  }
                }
              }
              + li {
                margin-top: 8px;
              }
            }
          }
          .left-ul.bottom {
            left: 4px !important;
            bottom: 60px !important;
          }
        }
        .right-menu {
          h3 {
            font-size: 10px;
            line-height: 15px;
            margin: 0 0 8px 11px;
          }
          ul {
            li {
              a {
                font-size: 15px;
                padding: 7px 8px 5px;
              }
            }
          }
        }
      }
    }
    &.Sidebar-open {
      padding: 75px 15px 70px 235px;
      header.header {
        width: calc(100% - 220px);
        left: 220px;
      }
      .nav-sidebar {
        width: 220px;
        .left-menu {
          width: 50px;
          ul.left-ul {
            li {
              .right-menu {
                left: 50px !important;
                padding: 0 5px !important;
                width: 168px !important;
              }
            }
          }
        }
      }
      .wizzard-content {
        .wizard-button {
          padding-left: 0;
        }
      }
    }
    .page-content-wrap {
      .welcome-page {
        h2 {
          margin: 0 0 15px 0;
        }
        .lookingFor {
          margin: 0 0 15px 0;
          .col-md-8 {
            flex: 0 0 100%;
            max-width: 100%;
          }
          h3 {
            margin: 0 0 15px 0;
          }
          ul {
            font-size: 15px;
            line-height: 17px;
            li {
              margin: 0 0 15px 0;
            }
          }
        }
        .platform-div {
          h3 {
            font-size: 18px;
            line-height: 20px;
            margin: 0 0 15px 0;
          }
          .bg-div {
            padding: 15px 15px;
            min-height: 140px;
            h1 {
              font-size: 24px;
              line-height: 30px;
            }
            p {
              font-size: 15px;
              line-height: 17px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  div {
    h2 {
      font-size: 24px;
      line-height: 24px;
    }
    h3 {
      font-size: 20px;
      line-height: 20px;
    }
    .page-header {
      margin: 0 0 10px 0;
      min-height: 32px;
      h3 {
        font-size: 24px;
        line-height: 24px;
      }
      .import-options {
        img {
          width: 32px;
        }
      }
      .tabs-div {
        margin-left: 15px;
        div {
          font-size: 18px;
          margin: 0 15px 0 0 !important;
          padding: 0px 0 0 0 !important;
        }
      }
    }
    .filter-wrap {
      margin: 0 0 15px 0;
      .filter-left {
        a {
          min-width: 32px;
          min-height: 32px;
          max-width: 32px;
          max-height: 32px;
          overflow: hidden;
          img {
            width: 16px;
          }
          + .selected {
            img {
              width: 36px;
            }
          }
        }
        .FilterSearchBox {
          + .selected {
            img {
              width: 36px;
            }
          }
        }
      }
    }
    .FilterMainWrap {
      padding: 0 0;
      .FilterBgWrap {
        padding: 15px 15px 10px 15px;
        margin: 0 auto 15px;
        .FilterTitleRow {
          margin: 0 0 15px 0;
        }
      }
    }
    .year-checkbox-ul {
      li {
        width: 20%;
      }
    }
    .filter-left {
      flex-wrap: wrap;
      .FilterSearchBox {
        margin: 0 5px 0 5px;
        .MuiInputBase-formControl {
          .MuiInputBase-input {
            min-width: 180px;
            font-size: 14px;
          }
        }
        + .FilterSorting {
          margin: 0 0 0 38px;
          width: 100%;
        }
      }
    }
    .filter-right {
      > div {
        + div {
          margin-left: 15px;
        }
        .MuiButton-root.btn {
          padding: 11.65px 10px 7.65px;
          height: 36px;
        }
      }
    }
    .MuiButton-root {
      &.btn {
        font-size: 15px;
        line-height: 15px;
        min-height: 34px;
        min-width: 110px;
      }
    }
    .custom-table {
      margin: 0px -5px 0;
      > .MuiPaper-root {
        padding: 0 0;
        > table {
          border-spacing: 5px 8px !important;
          min-width: 900px;
        }
        .thead-row {
          td {
            table {
              tr {
                td {
                  padding: 10px 10px 10px;
                  word-break: break-word;
                  &:first-child {
                    padding-left: 10px;
                  }
                }
              }
            }
          }
        }
        .tbody-row {
          box-shadow: 0 0px 2px 5px rgba(209, 221, 229, 0.1);
          td {
            table {
              tr {
                td {
                  padding: 10px 10px 6px;
                  min-height: auto;
                  font-size: 12px;
                  line-height: 15px;
                  word-break: initial;
                  white-space: normal;
                  white-space: normal;
                  word-break: break-word;
                  &:first-child {
                    padding-left: 10px;
                  }
                  &:last-child {
                    padding-left: 10px;
                  }
                  .table-actions {
                    opacity: 1;
                    img {
                      width: 28px;
                      height: 28px;
                    }
                  }
                }
              }
            }
            .collapse-table {
              tr {
                td {
                  padding: 12px 10px 10px;
                  .row {
                    flex-wrap: nowrap;
                  }
                  &:first-child {
                    width: 0 !important;
                  }
                  &:last-child {
                    width: 0 !important;
                  }
                }
              }
            }
          }
        }
      }
      &.empty-table {
        > .MuiPaper-root {
          table {
            min-width: auto;
            .EmptyMessage {
              margin: 30px 0 0 0;
              h2 {
                font-size: 24px;
                line-height: normal;
                max-width: 250px;
                margin: 0 auto 15px;
              }
              h3 {
                font-size: 18px;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
    .homefeed {
      .homefeed-content {
        .homefeed-item {
          padding: 15px 15px 15px;
          .text-content {
            h5 {
              font-size: 12px;
              margin: 0 0 8px 0;
            }
            h3 {
              margin: 0 0 10px 0;
            }
            h6 {
              margin: 0 0 10px 0;
            }
            h6:last-child {
              margin-bottom: 0;
            }
          }
          .actions-div {
            opacity: 1;
          }
        }
      }
    }
    .profile-content {
      .page-title {
        margin-bottom: 30px;
      }
      .profile-form {
        max-width: 100%;
        .row {
          margin: 0 -15px;
          .col-md-6 {
            padding: 0 15px;
          }
        }
      }
    }
    .wizzard-content {
      padding-bottom: 80px !important;
      .back-div {
        position: initial;
        margin-bottom: 20px;
      }
      .wizzard-form {
        min-height: calc(100vh - 285px);
      }
      .wizard-button {
        padding: 15px 0 0;
        position: fixed;
        margin: 0;
        width: 100%;
        left: 0;
        right: 0;
        padding-left: 50px;
        bottom: 0;
        background: #fff;
        padding-bottom: 15px;
        z-index: 0;
        padding-left: 50px;
      }
    }
    .single-select-wrap {
      .single-select-content {
        > div {
          margin-bottom: 10px;
        }
      }
    }
    .multiselect-wrap {
      .multiselect-content {
        > div {
          width: 33.33%;
          max-width: 100%;
          padding-bottom: 10px;
          .MuiPaper-root {
            height: 100%;
          }
        }
      }
    }
    .post-card {
      .box-wrap {
        padding: 10px;
      }
    }
    .custom-dropdownul {
      padding: 10px;
    }
    .webinar-registerd {
      .box-wrap {
        padding: 15px;
        .left {
          min-width: 140px;
          max-width: 140px;
          padding-right: 10px;
          padding-left: 0;
        }
        .right {
          padding-top: 0;
          padding-bottom: 0;
          padding-right: 0;
          padding-left: 10px;
          .rounded-btn {
            position: initial;
            margin-top: 10px;
            transform: none !important;
          }
        }
      }
      &.webinar-student-services {
        .box-wrap {
          .right {
            .rounded-btn {
              transform: none !important;
            }
            h4 {
              img.star {
                margin: -4px 0 0 6px;
              }
            }
          }
        }
      }
      &.webinar-job-intern {
        .box-wrap {
          .left {
            margin: 0 0px;
          }
          .right {
            width: calc(100% - 50px);
            padding-left: 15px;
            .rounded-btn {
              button {
                transform: none;
              }
            }
          }
        }
      }
    }
  }
  .drag-wrapper {
    .drag-content-wrap {
      .bg-wrap {
        .drag-main-row {
          height: auto;
          .drag-item {
            padding: 12px 15px 10px;
            .left {
              width: calc(100% - 64px);
              .img-wrap {
                max-width: 50px;
              }
            }
            .drag-icon {
              margin: 0 15px 0 0;
            }
            .actions-div {
              opacity: 1;
              a {
                min-width: 32px;
                svg {
                  width: 32px;
                  height: 32px;
                }
              }
            }
          }
        }
        .drag-collapse-row {
          padding: 0 15px 0 15px;
        }
      }
    }
    + .drag-wrapper {
      margin: 10px 0 0 0;
    }
  }
  .collapse-item {
    padding: 0 0 0 0;
    + .collapse-item {
      margin: 10px 0 0 0;
      padding-top: 10px;
    }
    &:last-child {
      padding-bottom: 10px;
    }
    .resources-img-wrap {
      margin: 0 10px 0 0;
    }
    .actions-div {
      opacity: 1;
      a {
        min-width: 32px;
        img {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
  .country-insights-table-wrapper {
    > .custom-table {
      > .MuiPaper-root {
        > table {
          min-width: 1200px;
          .thead-row {
            td {
              width: 90px;
              &:nth-child(1) {
                width: 200px;
              }
            }
          }
          .tbody-row {
            td {
              table {
                tr {
                  td {
                    width: 90px;
                    &:nth-child(1) {
                      width: 200px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  body {
    &.checklist-dnd {
      .checklist-categories {
        ul {
          margin: 0;
          li {
            > a {
              padding: 14px 10px 10px !important;
              font-size: 18px !important;
              .actions-div {
                opacity: 1 !important;
                margin-right: 0 !important;
                a {
                  svg {
                    width: 24px !important;
                    height: 24px !important;
                  }
                }
              }
            }
          }
        }
      }
      .checklist-items {
        li {
          .checklist-card {
            padding: 10px !important;
            .checklist-task {
              .checklist-control {
                width: 100% !important;
                .title {
                  width: 100% !important;
                  .primaryTask {
                    font-size: 18px !important;
                    line-height: normal !important;
                  }
                  a {
                    font-size: 15px !important;
                    line-height: normal !important;
                  }
                  .wizzard-form {
                    margin-right: 0 !important;
                  }
                }
                .actions-div {
                  opacity: 1 !important;
                  a {
                    svg {
                      width: 24px !important;
                      height: 24px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .chat-wrapper {
    .filter-wrap {
      margin: -44px 0 20px 0;
    }
  }
  .student-feedback-table {
    .custom-table {
      > .MuiPaper-root {
        > table {
          min-width: 1100px;
          td:last-child {
            width: 50px !important;
          }
        }
      }
    }
  }
  .pending-req-wrapper {
    .custom-table {
      > .MuiPaper-root {
        > table {
          min-width: 1100px;
          td:nth-child(7),
          td:nth-child(8) {
            width: 81px !important;
          }
          td:last-child {
            width: 80px !important;
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  div {
    .main-content {
      padding-top: 120px !important;
      padding: 75px 15px 30px 65px;
      header {
        &.header {
          height: auto;
          padding: 8px;
          .HeaderLeft {
            width: 100%;
            padding-right: 8px;
            .dropdown-wrapper {
              .custom-dropdowninput {
                input {
                  min-height: 40px;
                  padding: 12.15px 20px 8.15px;
                }
              }
            }
          }
          .HeaderRight {
            margin: 0 auto;
            a {
              margin: 0;
              width: 40px;
              height: 40px;
              svg {
                width: 16px;
              }
              + a {
                margin-left: 8px;
              }
            }
          }
        }
      }
      .nav-sidebar {
        z-index: 1;
      }
      &.Sidebar-open {
        padding: 75px 15px 30px 65px;
        .nav-sidebar {
          width: 275px;
          z-index: 1060;
          .navmenu {
            .left-menu {
              padding: 0 4px;
              .left-ul.bottom {
                left: 4px !important;
              }
              ul.left-ul {
                li {
                  .right-menu {
                    left: 50px !important;
                    padding: 0 10px !important;
                    width: 200px !important;
                    overflow-y: auto;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
        header {
          &.header {
            width: calc(100% - 65px);
            left: 65px;
          }
        }
      }
      .page-content-wrap {
        .welcome-page {
          .platform-div {
            + .platform-div {
              .row {
                flex-wrap: wrap;
                .col-md-2 {
                  flex: 0 0 33.33%;
                  max-width: 33.33%;
                  margin-bottom: 15px;
                }
              }
            }
          }
        }
      }
      .table-pagination {
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 15px;
        .pagination-dropdown {
          width: 100%;
          justify-content: center;
        }
        .MuiPagination-root {
          width: 100%;
          .MuiPagination-ul {
            margin: 10px 0 10px 0px !important;
            justify-content: center;
            li {
              margin: 0 2px;
              button {
                height: 30px;
                min-width: 30px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  body {
    .categories-grid {
      .MuiGrid-grid-xs-3 {
        max-width: 35%;
        flex-basis: 35%;
      }
      .MuiGrid-grid-xs-9 {
        max-width: 65%;
        flex-basis: 65%;
      }
    }
    &.checklist-dnd {
      li#checklist-category-li {
        a {
          > div {
            margin-right: 8px !important;
            img {
              width: 10px;
            }
          }
          &.link {
            font-size: 18px;
            padding: 13px 10px 10px;
          }
        }
      }
      #checklist-li {
        .checklist-card {
          padding: 13px 10px 10px 10px;
          .checklist-task {
            > span {
              margin: 0 8px 0px 0;
              img {
                width: 10px;
              }
            }
            .checklist-control {
              width: calc(100% - 18px);
              .title {
                width: calc(100% - 48px);
                .primaryTask {
                  font-size: 18px !important;
                  line-height: 18px !important;
                }
                a {
                  font-size: 15px;
                }
                .wizzard-form {
                  margin-left: 0;
                  min-height: auto;
                  margin-right: -66px;
                }
              }
              .actions-div {
                opacity: 1;
                a {
                  svg {
                    width: 24px;
                    height: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .topics-wrapper {
    .chatModule {
      .row {
        > .col-md-3 {
          flex: 0 0 40%;
          max-width: 40%;
        }
        > .col-md-6 {
          flex: 0 0 60%;
          max-width: 60%;
        }
        > .col-md-3:last-child {
          flex: 0 0 60%;
          max-width: 60%;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  div {
    .loader {
      margin-left: 25px !important;
    }
    .main-content {
      padding-top: 75px;
      header {
        &.header {
          height: auto;
          padding: 8px;
          .HeaderLeft {
            width: 100%;
            padding-right: 8px;
            .dropdown-wrapper {
              .custom-dropdowninput {
                input {
                  min-height: 40px;
                  padding: 12.15px 20px 8.15px;
                }
              }
            }
          }
          .HeaderRight {
            margin: 0 auto;
            a {
              margin: 0;
              width: 40px;
              height: 40px;
              svg {
                width: 16px;
              }
              + a {
                margin-left: 8px;
              }
            }
          }
        }
      }
      .nav-sidebar {
        z-index: 1;
      }
      &.Sidebar-open {
        padding: 75px 15px 30px 65px;
        .nav-sidebar {
          width: 275px;
          z-index: 1060;
          .navmenu {
            .left-menu {
              padding: 0 4px;
              .left-ul.bottom {
                left: 4px !important;
              }
              ul.left-ul {
                li {
                  .right-menu {
                    left: 50px !important;
                    padding: 0 10px !important;
                    width: 200px !important;
                    overflow-y: auto;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
        header {
          &.header {
            width: calc(100% - 65px);
            left: 65px;
          }
        }
      }
      .page-content-wrap {
        .welcome-page {
          .lookingFor {
            ul {
              font-size: 14px;
              line-height: 15px;
              li {
                width: 100%;
                margin: 0 0 10px 0;
                &:last-child {
                  margin-bottom: 0;
                }
                img {
                  &:first-child {
                    width: 24px;
                  }
                  &:last-child {
                    width: 10px;
                  }
                }
              }
            }
          }
          .platform-div {
            .row {
              .col-md-2 {
                flex: 0 0 100% !important;
                max-width: 100% !important;
                margin-bottom: 10px !important;
                .bg-div {
                  min-height: 100px;
                  h1 {
                    font-size: 30px;
                    line-height: 32px;
                  }
                  p {
                    font-size: 18px;
                    line-height: 20px;
                  }
                }
              }
              .col {
                flex-basis: initial;
                width: 100%;
                .bg-div {
                  min-height: 100px;
                  h1 {
                    font-size: 30px;
                    line-height: 32px;
                  }
                  p {
                    font-size: 18px;
                    line-height: 20px;
                  }
                }
                + .col {
                  margin-top: 12px;
                }
              }
            }
            + .platform-div {
              margin: 12px 0 0 0;
            }
          }
        }
      }
      .page-header {
        flex-wrap: wrap;
        h3 {
          text-align: center;
          width: 100%;
        }
        .left {
          width: 100%;
          flex-wrap: wrap;
        }
        .import-options {
          margin: 0 auto;
        }
        .tabs-div {
          margin-left: 0;
          margin-top: 8px;
          margin-bottom: 8px;
          width: 100%;
          justify-content: center;
        }
      }
      .filter-wrap {
        justify-content: center;
        flex-wrap: wrap;
        .filter-left {
          flex-wrap: wrap;
          a {
            + .selected {
              img {
                width: 36px;
              }
            }
          }
          .FilterSearchBox {
            width: calc(100% - 38px);
            margin: 0 0 0 5px;
            * {
              width: 100%;
            }

            + .FilterSorting {
              margin-left: 38px;
            }
            + a {
              margin-top: 10px;
            }

            + .selected {
              img {
                width: 36px;
              }
            }
          }
          .FilterSorting {
            margin: 4px 0px 0 10px;
          }
        }
        .filter-right {
          width: 100%;
          justify-content: center;
          margin-top: 10px;
        }
      }
      .FilterCheckList {
        ul {
          li {
            > div {
              margin: 0 8px 0 0;
            }
          }
        }
        ul.admin-filters {
          li {
            width: 50%;
            margin: 0 0 10px 0;
          }
        }
      }
      .FilterMainWrap {
        .FilterBgWrap {
          .FilterCheckList {
            h6 {
              margin: 0 0 10px 0;
            }
            .col-md-9 {
              margin-top: 10px;
            }
          }
        }
      }
      .year-checkbox-ul {
        li {
          width: 25%;
        }
      }
      .degree-checkbox-ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 50%;
          margin: 0 0 10px 0 !important;
        }
      }
      .country-insights-links-wrapper {
        .row {
          flex-wrap: wrap !important;
          + .row {
            margin-top: 10px !important;
          }
        }
      }
      .table-pagination {
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 15px;
        .pagination-dropdown {
          width: 100%;
          justify-content: center;
        }
        .MuiPagination-root {
          width: 100%;
          .MuiPagination-ul {
            margin: 10px 0 10px 0px !important;
            justify-content: center;
            li {
              margin: 0 2px;
              button {
                height: 24px;
                min-width: 24px;
                font-size: 12px;
              }
            }
          }
        }
      }
      .homefeed {
        .homefeed-content {
          .homefeed-item {
            flex-wrap: wrap;
            position: relative;
            padding: 10px;
            .no-user-icon {
              width: 145px !important;
              margin-left: auto !important;
              margin-right: auto !important;
            }
            .img-wrap {
              max-width: 100%;
              margin: 0 0 10px 0;
              max-height: initial;
              width: 100%;
              img {
                height: auto;
              }
            }
            .img-wrapper {
              margin: 15px 0 15px 0;
              text-align: center;
              display: block;
              max-width: 100%;
            }
            .text-content {
              h3 {
                font-size: 18px;
                line-height: normal;
                margin: 0 0 8px 0;
              }
              p {
                font-size: 16px;
                line-height: 18px;
              }
              h6 {
                font-size: 15px;
                line-height: normal;
              }
            }
            .actions-div {
              position: absolute;
              top: 15px;
              right: 15px;
              background: #fff;
              border-radius: 100px;
              box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
              padding: 0 4px;
              a {
                padding: 0 0px;
                min-width: 24px;
                svg {
                  width: 24px;
                  height: 24px;
                }
                img {
                  width: 16px;
                  height: 16px;
                }
                + a {
                  border-left: 1px solid #f1f1f1;
                }
              }
              span {
                padding: 0 0px;
                min-width: 24px;
                width: auto;
                height: auto;
                &:first-child {
                  img {
                    width: 15px;
                    height: 15px;
                  }
                }
                img {
                  width: 24px;
                  height: 24px;
                }
                + span {
                  border-left: 1px solid #f1f1f1;
                }
              }
            }
            &.promote-item {
              flex-wrap: nowrap;
              padding: 10px !important;
              .promote-img-wrap {
                margin-left: 8px !important;
                max-width: 40px !important;
                margin-bottom: 0 !important;
              }
              .drag-icon {
                span {
                  img {
                    width: 10px;
                  }
                }
              }
              .text-content {
                padding-left: 12px;
                h3 {
                  margin-bottom: 4px;
                }
                .promote-head-content {
                  @media (max-width: 480px) {
                    flex-wrap: wrap;
                  }
                  h6 {
                    margin-bottom: 0;
                  }
                }
              }
              .actions-div {
                top: 10px;
                right: 10px;
                > img {
                  width: 15px;
                  height: 15px;
                  margin: 0 5px 0 4px;
                  + a {
                    border-left: 1px solid #f1f1f1;
                  }
                }
              }
            }
          }
        }
      }
      .profile-content {
        form {
          .profile-form {
            h3 {
              margin: 0 0 15px 0;
            }
            .row {
              margin: 0;
              .col-md-6 {
                padding: 0;
                + .col-md-6 {
                  border-top: 1.5px solid #d1dde5;
                  border-left: 0;
                  margin-top: 30px;
                  padding-top: 30px;
                }
              }
            }
          }
          .profile-action {
            margin-top: 30px !important;
          }
        }
      }
      .MuiDialog-container {
        .MuiPaper-root {
        }
      }
      .wizzard-content {
        padding-bottom: 44px !important;
        .mb-30 {
          margin-bottom: 15px !important;
        }
        .mt-30 {
          margin-top: 15px !important;
        }
        .mb-50 {
          margin-bottom: 30px !important;
        }
        .mt-50 {
          margin-top: 30px !important;
        }
        .back-div {
          position: initial;
          width: 100%;
          margin: 0 0 20px 0;
        }
        .page-title {
          margin-bottom: 15px !important;
          font-size: 22px;
          line-height: 22px;
        }
        form {
          padding-bottom: 30px;
        }
        .wizzard-form {
          min-height: calc(100vh - 278px);
        }
        .wizard-button {
          padding: 10px 0 0;
          padding-bottom: 10px;
          padding-left: 50px;
          z-index: 0;
          button {
            min-width: 100px;
            margin: 0 5px;
          }
        }
      }
      .single-select-wrap {
        h3 {
          margin: 0 0 20px 0;
        }
        .single-select-content {
          margin: 0 -5px;
          > div {
            width: 50%;
            max-width: 100% !important;
            padding: 0 5px;
            margin-bottom: 5px;
            > div {
              height: 100%;
            }
            .MuiPaper-root {
              padding: 15px;
              h6 {
                font-size: 16px;
                line-height: 16px;
              }
              p {
                font-size: 13px;
                line-height: 13px;
              }
            }
          }
        }
      }
      .multiselect-wrap {
        .multiselect-content {
          margin: 0 -5px;
          > div {
            width: 50%;
            padding: 0 5px 10px;
            margin-bottom: 0 !important;
            > div {
              height: 100%;
            }
            .MuiPaper-root {
              padding: 15px;
              h6 {
                font-size: 16px;
                line-height: 16px;
              }
              p {
                font-size: 13px;
                line-height: 13px;
              }
            }
          }
        }
      }
      .dropdown-array-option {
        .MuiChip-root {
          border-radius: 100px;
          padding: 6px 8px 6px 8px;
          margin: 2px 2px 2px;
          font-size: 14px;
          line-height: 14px;
          .MuiChip-label {
            padding: 2px 0 0 0;
          }
          svg {
            margin: 0px 0 0 0;
            width: 12px;
            height: 12px;
          }
        }
      }
    }
    .FilterMainWrap {
      .FilterBgWrap {
        .dropdown-wrapper {
          margin-bottom: 12px;
        }
      }
    }
  }
  .drag-wrapper {
    .drag-content-wrap {
      .bg-wrap {
        .drag-main-row {
          height: auto;
          .drag-item {
            padding: 14px 10px 8px !important;
            height: auto;
            position: relative;
            .left {
              width: calc(100% - 64px);
              flex-wrap: nowrap;
              .drag-icon {
                margin: 0 10px 0 0;
                img {
                  width: 10px;
                }
              }
              .row {
                margin: 0;
                width: calc(100% - 20px);
                > div {
                  padding: 0;
                  flex-wrap: wrap;
                }
              }
              .img-wrap {
                margin: 0 10px 0 0;
                max-width: 30px;
                border-radius: 4px;
                overflow: hidden;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
                min-width: 30px;
              }
              h3 {
                font-size: 18px;
                line-height: 18px;
                width: calc(100% - 50px);
                margin: 0;
              }
              a {
                width: 100%;
                margin: 0px 0 0 0px !important;
                img {
                  position: absolute;
                  right: 65px;
                  top: 50%;
                  transform: translateY(-50%);
                  margin-top: 4px;
                }
              }
            }
            .actions-div {
              > img {
                width: 15px;
                height: 15px;
                margin: 0 5px 0 4px;
              }
              a {
                min-width: auto;
                svg {
                  width: 24px;
                  height: 24px;
                }
              }
            }
          }
        }
        .drag-collapse-row {
          border-top: 1.5px solid #e8eef2;
          padding: 10px;
          > div {
            .collapse-item {
              padding: 0 0 0 18px;
              .drag-icon {
                margin: 0;
              }
              .resources-img-wrap {
                margin-right: 0 !important;
                img {
                  margin: 0;
                }
                &.normal-image {
                  margin-top: -4px;
                }
              }
              .img-wrap {
                max-width: 100% !important;
                margin: 0 !important;
              }
              .collapse-content {
                margin-top: 8px;
                h4 {
                  margin: 0 0 6px 0;
                }
                p {
                  margin: 6px 0 0 0;
                }
              }
            }
          }
        }
      }
    }
    + .drag-wrapper {
      margin: 10px 0 0 0;
    }
  }
  .student-services-categories {
    .bg-wrap {
      .drag-main-row {
        .drag-item {
          .left {
            h3 {
              width: calc(100% - 70px) !important;
            }
            a {
              margin: 0px 0 0 62px !important;
            }
          }
        }
      }
    }
  }
  .collapse-item {
    padding: 0 0 0 20px;
    flex-wrap: wrap;
    position: relative;
    .drag-icon {
      margin: 0 0px 0 0;
      position: absolute;
      left: 0;
      top: 15px;
      img {
        width: 10px;
      }
    }
    + .collapse-item {
      margin: 15px 0 0 0;
      padding-top: 15px;
    }
    &:first-child {
      padding-bottom: 10px;
      .drag-icon {
        top: 0px;
      }
    }
    &:last-child {
      padding-bottom: 15px !important;
    }

    .img-wrap {
      max-width: 100%;
      margin: 0 0 15px 0;
      img {
        width: 100%;
        max-width: 100%;
      }
    }
    .resources-img-wrap {
      margin: 0 0px 10px 0;
      width: 100% !important;
      height: auto !important;
      &.normal-image {
        img.default-image-100 {
          max-width: 100px;
          display: block;
          margin: 0 auto;
        }
      }
      img {
        object-fit: initial;
        height: auto;
      }
      video {
        height: auto !important;
        width: 100% !important;
      }
      .img-wrap {
        width: 100%;
        max-width: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
        }
      }
    }
    .collapse-content {
      width: 100%;
      margin-top: 15px;
      h4 {
        font-size: 18px;
        line-height: 18px;
      }
      p {
        font-size: 16px;
        line-height: 18px;
      }
    }
    .actions-div {
      position: absolute;
      top: 10px;
      right: 10px;
      background: #fff;
      border-radius: 100px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      padding: 0 4px !important;
      a {
        padding: 0 0px;
        min-width: auto !important;
        img {
          width: 24px;
          height: 24px;
        }
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  body {
    .categories-grid {
      .MuiGrid-grid-xs-3 {
        max-width: 100%;
        flex-basis: 100%;
      }
      .MuiGrid-grid-xs-9 {
        max-width: 100%;
        flex-basis: 100%;
        .EmptyMessage {
          margin: 15px 0 0 0;
        }
      }
    }
    &.checklist-dnd {
      .checklist-category-name {
        position: initial;
        width: 100%;
        text-align: center;
        margin: 15px 0 5px 0;
      }
      .checklist-categories {
        ul {
          margin-bottom: 0;
        }
      }
      #checklist-li {
        .checklist-card {
          .checklist-task {
            align-items: flex-start;
            .checklist-control {
              align-items: flex-start;
              .title {
                .wizzard-form {
                  form {
                    padding-bottom: 0;
                    .max-width {
                      margin-top: 0;
                      .row {
                        > div {
                          + div {
                            margin-top: 15px !important;
                          }
                        }
                      }
                      .add-task-actions {
                        margin-top: 15px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .Toastify {
    .Toastify__toast-container {
      padding: 0 15px !important;
      bottom: 15px !important;
      .Toastify__toast {
        padding: 15px 15px 12px !important;
      }
    }
  }
  .country-insights-table-wrapper {
    > .custom-table {
      > .MuiPaper-root {
        > table {
          min-width: 1250px;
        }
      }
    }
  }
  .help-center-wrapper {
    .help-center-title {
      margin-bottom: 24px;
      margin-top: 16px;
    }
    .started-mainpage {
      .resource-section {
        .MuiGrid-root {
          .MuiGrid-grid-xs-3 {
            &:first-child {
              max-width: 100%;
              flex-basis: 100%;
            }
            &:nth-child(3) {
              max-width: 100%;
              flex-basis: 100%;
              margin-left: auto;
              .create-alert,
              .feedback-alert {
                padding: 24px;
              }
            }
          }
          .MuiGrid-grid-xs-6 {
            max-width: 100%;
            flex-basis: 100%;
          }
        }
      }
    }
  }
  .feedback-dialog {
    .MuiDialog-paper {
      padding: 8px !important;
      width: 100%;
      max-width: 100% !important;
      margin: 0 12px;
      .MuiDialogContent-root {
        width: 100% !important;
        padding: 16px !important;
        .modal-title {
          max-width: 260px;
        }
        .model-body {
          margin: 16px auto 0;
          .btn-contact-us {
            margin: 0 0 16px;
          }
          .file-attch {
            margin: 0 0 16px;
          }
          ul {
            li {
              &.point {
                height: 22px;
                width: 22px;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
  }
  .topics-wrapper {
    .chatModule {
      .row {
        > .col-md-3 {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 16px;
        }
        > .col-md-6 {
          flex: 0 0 100%;
          max-width: 100%;
        }
        > .col-md-3:last-child {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .ChatFilter {
        .ChatFilterOptions {
          .MuiPaper-root {
            .MuiAccordionSummary-root {
              padding: 14px 12px 10px 12px !important;
              .accordionHeader {
                font-size: 18px;
              }
            }
          }
          .chatUserItems {
            max-height: 290px;
            overflow-y: auto;
            .ChatItem {
              .img {
                width: 32px;
                height: 32px;
                min-width: 32px;
              }
              .usreDescription {
                h4 {
                  margin-bottom: 0;
                  padding-top: 4px;
                }
              }
            }
          }
        }
      }
      .chat-wrapper {
        .hide-search {
          display: none;
        }
      }
      .CreatePostWrap {
        .TypingBox {
          flex-wrap: wrap;
          .AttachmentPin {
            left: 12px;
            transform: none;
            top: 15px;
            img {
              width: 15px;
            }
          }
          > div {
            &:nth-child(2) {
              padding-right: 0;
              .MuiInputBase-input {
                padding-left: 32px;
              }
            }
            &:nth-child(3) {
              margin-top: 8px;
              width: 100%;
              button {
                width: 100%;
              }
            }
          }
        }
      }
      .chatboxWrapper {
        .PostContentWrap {
          .PostContentItem {
            padding: 16px 12px 16px;
            .PostHead {
              align-items: flex-start;
              .profileImg {
                height: 32px;
                width: 32px;
                margin: 0 8px 0 0 !important;
                min-width: 32px;
              }
              .post-actions {
                display: flex;
                img {
                  width: 16px;
                }
              }
            }
          }
        }
      }
    }
    .hide-search {
      display: none;
    }
    .filter-wrap {
      margin: 0 0 16px 0 !important;
      .filter-right {
        margin-top: 0 !important;
      }
    }
  }
  .socialmedia-list {
    .homefeed-content {
      .homefeed-item {
        flex-wrap: nowrap !important;
        > span {
          img {
            width: 10px;
          }
        }
        .image-wrapper {
          min-width: 80px;
          min-height: 60px;
          max-width: 80px;
          max-height: 60px;
          margin: 0 8px 0 8px;
          img {
            max-width: 60px;
          }
        }
        .video-wrapper {
          min-height: 100px;
          max-width: 160px;
          max-height: 100px;
          margin: 0 8px 0 8px;
          video {
            max-width: 160px;
            max-height: 100px;
          }
        }
        .text-content {
          padding-right: 82px;
          h5 {
            margin: 0 0 4px 0 !important;
          }
          h3 {
            font-size: 16px !important;
            line-height: normal !important;
            margin: 0 0 4px 0 !important;
          }
        }
        .actions-div {
          top: 10px !important;
          right: 10px !important;
        }
      }
    }
  }
  .kommunicate-custom-iframe {
    right: 0 !important;
  }
}
@media (max-width: 480px) {
  div {
    .MuiDialog-container .MuiPaper-root {
      padding: 25px;
    }
    .PresenterDiv {
      .PresenterDiv-row {
        > div {
          max-width: 100%;
          + div {
            margin-top: 10px;
          }
        }
      }
    }
    .timeZoneselect {
      flex-wrap: wrap;
      .form-group {
        max-width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .webinar-registerd {
      .box-wrap {
        flex-wrap: wrap;
        .left {
          width: 100%;
          max-width: 100%;
          padding: 0;
          margin: 0 0 15px 0;
          img {
            width: auto;
          }
        }
        .right {
          width: 100%;
          max-width: 100%;
          padding-left: 0;
        }
      }
    }
  }
  .socialmedia-list {
    .homefeed-content {
      .homefeed-item {
        flex-wrap: wrap !important;
        .video-wrapper {
          max-width: calc(100% - 18px);
          margin: 0 0 0 auto;
          max-height: initial;
          video {
            max-width: 100%;
            margin: 0;
            max-height: initial;
          }
          + .text-content {
            + .actions-div {
              top: 15px !important;
              right: 15px !important;
            }
          }
        }
        .image-wrapper {
          justify-content: flex-start;
        }
        .text-content {
          margin-left: 18px;
          margin-top: 8px;
          padding-right: 0 !important;
        }
      }
    }
  }
  body {
    .nadmin-card-modal {
      .MuiDialog-container {
        .MuiPaper-root {
          max-width: 92% !important;
        }
      }
    }
  }
}
