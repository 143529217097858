$color-brand-blue: #0030b5;
$color-blue-2: #000485;
$color-white: #ffffff;
$color-light-grey: #e8eef2;
$color-grey: #566d82;
$color-grey-2: #607790;
$color-grey-4: #bfced8;
$color-light: #e5eaf7;
$color-orange: #f76155;
$color-light-grey-2: #d1dde5;
$color-brand-orange: #f76155;

:root {
  --primary-dark-color: #000c3d;
  --primary-color: #000485;
  --secondary-color: #f7645f;
  --white-color: #fff;
  --gray-color: #607790;
  --gray-color-light: #e8eef2;
  --gray-color-600: #bfced8;
  --gray-outline-color: #d1dde5;
  --field-label-color: #8094ab;
  --link-color: #0030b5;
  --icon_background: #f1f4f7;
  --icon_background_hover: #eaeef3;
  --card-box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  --box-shadow: 0 1px 14px 3px #d1dde5;
}
