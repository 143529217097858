.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  border: 3px solid #f76155;
  outline: 0;
  border-radius: 0 !important;
}

.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-point.point-se,
.cropper-point.point-sw,
.cropper-point.point-nw,
.cropper-point.point-ne,
.cropper-line {
  display: none !important;
}

.cropper-view-box {
  outline: inherit !important;
}

.square-croppper .cropper-point.point-w {
  left: -1px;
}
.square-croppper .cropper-point.point-s {
  bottom: -1px;
}
.square-croppper .cropper-point.point-n {
  top: -1px;
}
.square-croppper .cropper-point.point-e {
  right: -1px;
}
.error-content {
  text-align: center;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-content h2 {
  margin: 0;
  color: #000485;
  font-family: "TTCommons-Medium";
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
}

.error-content h3 {
  color: #000485;
  font-family: "TTCommons-Medium";
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 31px;
  text-align: center;
  margin: 0;
}

.error-content .img-wrap {
  margin: 50px auto;
  max-width: 625px;
}

.img-wrap img {
  width: 100%;
}

.error-content button {
  max-width: 270px;
  border-radius: 45px;
  background-color: #f76155;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  padding: 21.85px 26px 16.85px;
  height: auto;
  margin: 30px 0 0;
  text-transform: capitalize;
  font-family: "TTCommons-Medium";
}

.error-content button:hover {
  background: rgb(248, 120, 110);
}

div[type="submit"] {
  -webkit-appearance: none;
}
div#confirmation-dialog-title + .MuiDialogContent-root {
  overflow: hidden;
}
