$angle-down-arrow-img: url("../assets/svg/AngleDownArrow.svg");

// style-guid-css
* {
  box-sizing: border-box !important;
}
body {
  &::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent !important; /* color of the tracking area */
    border-radius: 50px;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d1dde5 !important; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: none;
    height: 10px !important;
  }

  &::-webkit-scrollbar-corner {
    border-radius: 50%;
  }

  &::-webkit-scrollbar-thumb:hover {
    border: 0px;
  }
}
.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.-mt-12 {
  margin-top: -12px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.w-full {
  width: 100%;
}

h1 {
  font-family: "TTCommons-DemiBold";
  font-size: 42px;
  font-weight: normal;
  line-height: 42px;
  margin: 0;
}

h2 {
  font-family: "TTCommons-Medium";
  font-size: 30px;
  font-weight: normal;
  line-height: 30px;
  margin: 0;
}

h3 {
  font-family: "TTCommons-Medium";
  font-size: 22px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
}

h4 {
  font-family: "TTCommons-Medium";
  font-size: 18px;
  font-weight: normal;
  line-height: 20px;
  margin: 0;
}

h5 {
  font-family: "TTCommons-Regular";
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
  margin: 0;
}

h6 {
  font-family: "TTCommons-Regular";
  font-size: 12px;
  font-weight: normal;
  line-height: 12px;
  margin: 0;
}

p {
  color: var(--primary-dark-color);
  font-family: "TTCommons-Regular";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.text-gray {
  color: #607790 !important;
}

.text-primary {
  color: var(--primary-dark-color) !important;
}

.MuiFormHelperText-root {
  &.Mui-error {
    text-align: center;
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    margin-top: 8px;
    color: var(--secondary-color) !important;
  }
}
.ml-75 {
  margin-left: 75px;
}

.link {
  color: #0030b5 !important;
  font-family: "TTCommons-Regular";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
  border-bottom: 1px solid #0030b5;
  display: inline-block;
  text-decoration: none !important;
  cursor: pointer;

  + .link {
    margin-left: 15px;
  }
}

.ngray-link {
  color: var(--gray-color);
  font-family: "TTCommons-Medium";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  border-bottom: 2px solid var(--gray-color);
  text-decoration: none !important;
  &:hover {
    color: var(--gray-color);
    opacity: 0.9;
  }
}

.row {
  margin-left: -10px;
  margin-right: -10px;
  row-gap: 20px;
  > div {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.text-center {
  text-align: center !important;
}

.form-group {
  margin-bottom: 15px;

  h3 {
    color: var(--primary-dark-color);
  }
}

.textarea-left-aligned {
  textarea {
    text-align: left !important;
  }
}

.MuiButton-root.btn {
  padding: 16px 12px 12px;
  min-width: 136px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  text-transform: none;
  font-family: "TTCommons-Medium";
  border-radius: 3px;
  border: 1px solid;
  box-shadow: none !important;
  min-height: 50px;
  transition: all 0.3s;
  border-radius: 100px;

  &.btn-active {
    background-color: #0030b5;
    border-color: #0030b5;
    color: #fff;

    &:hover {
      opacity: 0.9;
    }
  }
  &.color-blue {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: #fff;
  }

  &.btn-dark {
    background-color: #000c3d;
    border-color: #000c3d;
    color: #fff;

    &:hover {
      opacity: 0.9;
    }
  }

  &.btn-white {
    background-color: #fff;
    border-color: #fff;
    color: #000c3d;

    span {
      margin-top: 3px;
    }

    &:hover {
      opacity: 0.9;
    }
  }

  &.btn-activedisabled {
    background-color: rgba(0, 48, 181, 0.6);
    border-color: rgba(0, 48, 181, 0.6);
    color: #fff;
  }

  &.btn-negative {
    background-color: #ffffff;
    color: var(--gray-color);
    border-color: #ffffff;
    box-shadow: var(--card-box-shadow) !important;

    &:hover {
      opacity: 0.9;
      box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
    }
  }

  &.btn-negativedisabled {
    background-color: rgba(128, 148, 171, 0.6);
    border-color: rgba(128, 148, 171, 0.6);
    color: #fff;
  }

  &.rounded-btn {
    border-radius: 45px !important;
    background-color: var(--link-color) !important;
    min-width: 145px !important;
    min-height: 30px !important;
    padding: 1px 0 0 0 !important;
    border: 0 !important;
    color: #fff !important;

    &.promote-btn-styling {
      padding: 12px 22px 8px !important;
      min-width: 185px !important;
      min-height: 50px !important;
      width: 100%;
    }

    &:hover {
      opacity: 0.9;
    }
  }

  &.btn-border {
    border-color: #0030b5;
    background: transparent;
    color: #0030b5;

    &:hover {
      opacity: 0.9;
    }
  }
}

button,
.import-options {
  .button-spin {
    position: inherit;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    right: auto !important;
    margin: -2px 0 0 10px;
  }
}

.send-button {
  height: 35px;
  min-height: 35px !important;
  padding: 4px 0 0 0 !important;
  width: 78px;
  min-width: 78px !important;
}

.MuiInputBase-formControl {
  .MuiInputBase-input {
    border: 2px solid var(--gray-color-light);
    border-radius: 45px !important;
    padding: 18px 20px 12px 20px;
    color: var(--primary-dark-color);
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    font-family: "TTCommons-Regular";
    opacity: 1;
    min-height: 50px;
    background-color: var(--white-color);
    transition: all 0.3s;

    &::-webkit-input-placeholder {
      color: var(--gray-color);
      opacity: 1;
    }

    &::-moz-placeholder {
      color: var(--gray-color);
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: var(--gray-color);
      opacity: 1;
    }

    &:-moz-placeholder {
      color: var(--gray-color);
      opacity: 1;
    }

    &:-internal-autofill-selected {
      background-color: var(--white-color) !important;
      color: var(--primary-dark-color) !important;
    }

    &.Mui-disabled {
      background-color: var(--icon_background);
      border-color: var(--icon_background);
      pointer-events: none;
    }

    &:hover {
      border-color: var(--gray-color-600);
    }

    &:hover {
      border-color: var(--gray-color-600);
    }
  }

  textarea.MuiInputBase-input {
    border-radius: 20px !important;
    padding: 20px;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--gray-color);
      opacity: 1;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--gray-color);
      opacity: 1;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: var(--gray-color);
      opacity: 1;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: var(--gray-color);
      opacity: 1;
    }
  }

  fieldset {
    display: none;
  }

  &.Mui-error {
    .MuiInputBase-input {
      border-color: var(--secondary-color);
    }
  }
  &.Mui-focused {
    .MuiInputBase-input {
      border-color: var(--gray-color);
    }
  }
}

.react-custom-dropdown {
  > div {
    border: 2px solid var(--gray-color-light) !important;
    color: var(--gray-color);
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    font-family: "TTCommons-Medium";
    opacity: 1;
    min-height: 50px;
    background-color: #ffffff;
    box-shadow: none !important;
    width: 100%;
    transition: all 0.3s;
    &:hover {
      border-color: var(--gray-color-600) !important;
    }
    &:focus {
      border-color: var(--gray-color) !important;
    }
  }
}

.selected-items-wrap {
  display: flex;
  align-items: center;
  margin: 20px 20px 0;
  justify-content: center;
  flex-wrap: wrap;

  .MuiChip-root {
    border-radius: 18.5px;
    background-color: #f1f4f7;
    padding: 7px 10px 4px;
    height: auto;
    color: #0030b5;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    font-family: "TTCommons-Regular";
    margin: 0 15px 15px 0;

    span {
      padding: 0;
    }

    svg {
      margin: -2px 0 0 8px;
      fill: #0030b5;
      opacity: 1;
      vertical-align: middle;
    }
  }
}

.custom-switch-div {
  display: flex;
  align-items: flex-end;

  .MuiSwitch-root {
    margin: 0 auto;

    .MuiSwitch-track {
      border-width: 2px;
    }
  }

  span.label {
    margin: 0 0 0 10px;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
  }

  &--in-active {
    span.label {
      color: #607790;
    }
  }
}

.custom-datepicker {
  position: relative;

  .MuiFormControl-root {
    width: 100%;
  }

  .react-datepicker__tab-loop {
    width: 100%;

    .react-datepicker-popper {
      width: 100%;
      z-index: 1;
      max-width: 301px;

      .react-datepicker {
        border: 2px solid var(--icon_background);
        border-radius: 10px;
        background-color: #fff;
        box-shadow: var(--card-box-shadow);
        padding: 20px;
        position: relative;
        margin-top: 10px;

        .react-datepicker__navigation--previous {
          position: absolute;
          left: 17px;
          padding: 10px;
          font-size: 0;
          width: 15px;
          height: 15px;
          background-size: 15px 15px;
        }

        .react-datepicker__navigation--next {
          position: absolute;
          right: 17px;
          padding: 10px;
          font-size: 0;
          width: 15px;
          height: 15px;
          background-size: 15px 15px;
        }

        .react-datepicker__header {
          padding: 0;
          background: transparent;

          .react-datepicker__current-month {
            padding: 2px 0 0 0;
          }

          .react-datepicker__day-names {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            padding: 0 0;
            margin: 20px 0;

            .react-datepicker__day-name {
              font-family: "TTCommons-Medium";
              font-weight: 500;
              font-size: 14px;
              letter-spacing: 0;
              line-height: 14px;
              text-align: center;
              width: 30px;
            }
          }
        }

        .react-datepicker__month {
          .react-datepicker__week {
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: center;

            > div {
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              letter-spacing: 0;
              line-height: 14px;
              font-family: "TTCommons-Medium";
              padding: 3px 0 0 0;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.custom-timepicker {
  position: relative;

  > div {
    height: 60px !important;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .react-datepicker__tab-loop {
    width: 100%;

    .react-datepicker-popper {
      width: 100%;
      max-width: 200px;
      z-index: 1;

      .react-datepicker {
        border: 2px solid #d1dde5;
        border-radius: 6px;
        background-color: #fafbfd;
        padding: 20px;
        max-height: 280px;
        overflow-y: auto;

        ul {
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            color: #607790 !important;
            font-family: "TTCommons-Regular";
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            padding: 15px 10px 11px !important;

            &:hover {
              color: #fff !important;
              background: var(--primary-color) !important;
            }

            &.react-datepicker__time-list-item--selected {
              color: white !important;
            }
          }
        }
      }
    }
  }
}

.fileupload-custom {
  color: #607790;
  font-family: "TTCommons-Medium";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;

  .dropzone {
    border-radius: 7.55px;
    background-color: var(--gray-outline-color);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    // min-height: 140px;
    position: relative;
    max-width: 300px;
    margin: 0 auto 10px;

    .upload-img {
      width: 100%;
      height: 100%;
      position: relative;
      // z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        // width: 100%;
        // height: 100%;
        // max-height: 200px;
        max-width: 100%;
        max-height: 140px;
      }

      &.uploaded {
        position: relative;
        // z-index: 1;
        img {
          width: 100%;
          // height: auto;
          max-height: none;
          max-width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          // max-width: 100%;
          // max-height: 140px;
        }
      }
    }

    video {
      height: auto;
      width: 100%;
    }
  }

  &.error {
    .dropzone {
      border: 2px solid var(--secondary-color);
    }

    .error-text {
      color: var(--secondary-color);
      margin: 5px 0 0 0;
    }
  }
}

.small-fileupload-wrap {
  .fileupload-custom {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .dropzone {
      margin: 12px auto 10px;
      height: auto;
      // min-height: 100px;

      .upload-img {
        &.placeholder {
          img {
            max-width: 150px;
            height: 150px;
          }
        }
      }
    }
  }
}

.single-select-wrap {
  h3 {
    margin: 0 0 20px 0;
    color: var(--primary-dark-color);
    padding: 4px 0 0 0;
    line-height: 18px;
  }

  .single-select-with-image {
    min-height: 80px;
  }

  .single-select-content {
    display: flex;
    justify-content: center;
    margin: 0 -10px;
    flex-wrap: wrap;

    > div {
      padding: 0px 10px 20px 10px;
      width: 100%;
      max-width: 260px;

      .MuiPaper-root {
        border: 0px;
        border-radius: 15px;
        box-shadow: none;
        text-align: center;
        align-items: center;
        min-height: 116px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 26px 14px;
        background: #f1f4f7;
        cursor: pointer;
        height: 100%;
        .MuiCardContent-root {
          padding: 0;

          h6 {
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            text-align: center;
            font-family: "TTCommons-Medium";
            color: var(--primary-dark-color);
            padding: 4px 0 0 0;
          }

          p {
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            font-family: "TTCommons-Regular";
            color: var(--primary-dark-color);
          }
        }

        &.selected {
          background: var(--primary-color);

          h6,
          p {
            color: #ffffff;
          }
        }

        &.disabled {
          cursor: not-allowed;
        }

        &.selected-disabled {
          background: var(--gray-outline-color);
          cursor: not-allowed;
          border: 0px;
        }
      }
    }
  }
}

.multiselect-wrap {
  h3 {
    margin: 0 0 16px 0;
    color: var(--primary-dark-color);
    padding: 4px 0 0 0;
    line-height: 18px;
  }

  .multiselect-content {
    display: flex;
    justify-content: center;
    margin: 0 -10px;
    flex-wrap: wrap;

    > div {
      padding: 0px 10px 10px 0px;
      width: 100%;
      max-width: 262px;

      .MuiPaper-root {
        border: 0px;
        border-radius: 15px;
        box-shadow: none;
        text-align: center;
        min-height: 116px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 15px;
        background: #f1f4f7;
        cursor: pointer;
        height: 100%;
        align-items: center;

        .MuiCardContent-root {
          padding: 0;
          img {
            margin-bottom: 4px;
            width: 35px;
            height: 35px;
          }
          h6 {
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 20px;
            text-align: center;
            font-family: "TTCommons-Medium";
            color: var(--primary-dark-color);
          }

          p {
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            font-family: "TTCommons-Regular";
            color: var(--primary-dark-color);
            min-height: 34px;
          }
        }

        &.selected {
          background: var(--primary-color);

          h6,
          p {
            color: #ffffff;
          }
        }
      }
    }
  }

  .small-card {
    > div {
      max-width: 150px !important;
    }

    .MuiPaper-root {
      min-height: 65px !important;
      padding: 11px 10px 6px !important;
      min-width: 140px;
    }
  }

  .dark-card {
    border-color: #607790 !important;

    h6 {
      word-break: break-all;
    }
  }

  &.error {
    .multiselect-content {
      > div {
        margin: 0 0 10px 0 !important;
      }
    }
  }
}

.buttons-wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    min-width: 140px;
    margin: 0 15px;
  }
}

.MuiDialog-container {
  background-color: transparent;
  opacity: 1 !important;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    height: 10px !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.5);
  }

  .MuiPaper-root {
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: var(--card-box-shadow) !important;
    padding: 0;
    max-width: 568px;
    width: 100%;
    margin: 0 16px;
    .MuiDialogTitle-root {
      padding: 0;

      h2 {
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
        font-family: "TTCommons-DemiBold";
        color: var(--primary-dark-color);
      }
    }
    .text-subheader {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
    }

    .MuiDialogContent-root {
      padding: 30px 30px 0px 30px;
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      overflow-y: scroll;
      .MuiTypography-root {
        padding: 0 !important;
      }
    }

    .MuiDialogActions-root {
      justify-content: space-between;
      padding: 0 30px 30px 30px;

      > div {
        width: 48%;

        button {
          width: 100%;
        }
      }
    }
  }
}
.book-appointment-modal {
  .MuiDialog-container {
    .MuiPaper-root {
      max-width: 743px;
      padding: 0;
    }
  }
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.radio-wrap {
  display: flex;
  align-items: center;

  .CustomRadio {
    height: 15px;
    width: 15px;
    position: relative;
    margin: -4px 8px 0 0;

    input[type="radio"] {
      height: 15px;
      width: 15px;
      opacity: 0;
      position: relative;
      z-index: 1;

      &:checked {
        + .checkmark:before {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          background: #0030b5;
          border-radius: 9px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .checkmark {
      height: 15px;
      width: 15px;
      border: 2px solid var(--primary-color);
      border-radius: 9px;
      display: inline-block;
      box-sizing: border-box !important;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  > span {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
  }
}

.add-button {
  button {
    border-radius: 18.5px !important;
    background-color: #f1f4f7 !important;
    color: #0030b5 !important;
    font-family: "TTCommons-Regular" !important;
    font-size: 15px !important;
    letter-spacing: 0 !important;
    line-height: 17px !important;
    min-height: 28px !important;
    padding: 5.5px 12px 3.3px 12px !important;
    min-width: 131px !important;
    border: 0 !important;
    margin: 15px 0 0 0 !important;

    i {
      font-size: 24px !important;
      font-style: normal;
      margin: 0 8px 0 0;
    }
  }

  &.mt-0 {
    button {
      margin-top: 0 !important;
    }
  }
}

#intro {
  height: 100px !important;
}

.textareaGroup {
  .MuiInputBase-root {
    padding: 0;

    textarea {
      height: 100px;
    }
  }
}

.DefaultWidthcard {
  .single-select-wrap {
    .single-select-content {
      > div {
        max-width: 262px !important;

        .MuiPaper-root {
          min-height: 102px !important;
        }
      }
    }
  }
}

.setCalendarContainer {
  text-align: center;
  h3 {
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    max-width: 354px;
    color: var(--primary-dark-color);
    width: 100%;
    margin: auto auto 10px auto;
  }
  h4 {
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 20px;
    width: 100%;
    margin: auto auto 30px auto;
    max-width: 392px;
    color: var(--primary-dark-color);
  }
  .button-container {
    display: flex;
    justify-content: center;
    .social-button {
      margin-right: 10px;
    }
  }
}

.MuiOutlinedInput-multiline {
  padding: 0 !important;
}

.PresenterDiv {
  .PresenterDiv-row {
    display: flex;
    justify-content: center;
    margin: 0 -10px;
    flex-wrap: wrap;

    > div {
      padding: 0 10px;
      width: 100%;
      max-width: 262px;
    }
  }
}

.timeZoneselect {
  display: flex;
  align-items: flex-start;
  margin: 0 -10px;
  justify-content: center;

  .form-group {
    padding: 0 10px;
    width: 100%;
    max-width: 188px;
  }

  .MuiFormHelperText-root.Mui-error {
    font-size: 14px;
    line-height: normal;
  }
}

.timezone-dropdown-wrapper {
  .dropdown-wrapper {
    .custom-dropdowninput {
      input {
        text-align: left;
        padding: 19.15px 10px 15.15px 25px;
      }

      > span {
        right: 8px;
      }
    }
  }
}

.remove-link {
  color: #0030b5;
  font-family: "TTCommons-Regular" !important;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
  border-bottom: 1px solid #0030b5;
  text-decoration: none !important;
  cursor: pointer;
}

.editor-wrap {
  max-width: 880px;
  margin: 0 auto;
}

.four-column {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -10px;

  .form-group {
    padding: 0 10px;
    width: 100%;
    max-width: 188px;
  }
}

.typeSelectionDiv {
  border-radius: 45px;
  background-color: #f1f4f7;
  max-width: 538px;
  margin: 0 auto;
  text-align: center;
  padding: 26px 30px 22px 30px;

  h4 {
    color: var(--primary-dark-color);
    margin: 0 0 6px 0;
  }

  h6 {
    color: var(--primary-dark-color);
  }
}

.typeSelectionContent {
  max-width: 538px;
  margin: 0 auto;

  .form-group {
    .row {
      width: auto !important;
      margin: 0 -10px;

      > div {
        padding: 0 10px;
      }
    }
  }
}

.GuidenceContentWrapper {
  h3 {
    color: var(--primary-dark-color);
    margin: 0 0 20px 0;
  }
  .drag-content-wrap {
    .bg-wrap {
      .drag-main-row {
        .drag-item {
          align-items: center;
          .left {
            width: 65%;
            h3 {
              font-size: 15px;
              letter-spacing: 0;
              line-height: 17px;
              color: var(--primary-dark-color);
            }
          }
          .DocType {
            width: 15%;
            text-align: right;
            p {
              margin: 0;
              font-size: 11px;
              font-weight: bold;
              letter-spacing: 1.5px;
              line-height: 16px;
              font-family: "brandon_grotesquebold";
              color: #8094ab;
            }
          }
          .actions-div {
            gap: 10px;
            width: 20%;
            justify-content: flex-end;
          }
        }
      }
    }
  }
  + .GuidenceContentWrapper {
    margin: 30px 0 0 0px;
  }
}

.AnalyticsMapWrapper {
  h3 {
    color: var(--primary-dark-color);
  }

  .bg-wrap {
    background-color: #f1f4f7;
    padding: 15px 15px;
    margin: 20px 0 0 0;
  }

  + .AnalyticsMapWrapper {
    margin: 50px 0 0 0;
  }
}

.MultipleFeaturescard {
  max-width: 750px;
  margin: 0 auto;
  .multiselect-wrap {
    .multiselect-content {
      > div {
        max-width: 188px;
        margin: 0 0 7px 0;
        padding: 0 10px 0 0;
      }
    }
  }
  > div {
    + div {
      margin-top: 30px;
    }
  }
}

.OnboardingWrapper {
  min-height: 100vh;
  display: flex;
  background: #000485;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    align-items: flex-start;
    padding-bottom: 60px;
    min-height: auto;
  }
  .right-content {
    width: 100%;
    max-width: 520px;
    position: relative;
    border-radius: 20px 0 0 58px;
    background-color: #fff;
    @media (max-width: 1250px) {
      max-width: 400px;
    }
    @media (max-width: 991px) {
      order: 2;
      max-width: 100%;
    }
    @media (max-width: 767px) {
      padding: 0 24px;
    }
    .right-content-container {
      width: 100%;
      max-width: 335px;
      margin: 0 auto;
      padding: 110px 0 40px;
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      @media (max-height: 750px) {
        padding: 60px 0 30px;
      }
      @media (max-width: 1250px) {
        padding: 60px 0 30px;
      }
      @media (max-width: 991px) {
        height: auto;
        padding: 50px 16px;
        width: 100%;
        max-width: 80%;
        border-radius: 20px;
        margin-top: -80px;
        position: relative;
        z-index: 11;
        background: #fff;
      }
      @media (max-width: 767px) {
        max-width: 100%;
        padding: 32px 16px;
      }
      .login-div,
      .forgot-password-section {
        width: 100%;
        align-self: flex-start;
        @media (max-width: 991px) {
          max-width: 335px;
          margin: 0 auto;
        }
      }
      h1 {
        color: var(--primary-dark-color);
        margin: 0 0 40px 0;
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 31px;
        font-family: "TTCommons-Medium";
        padding-top: 4px;
        @media (max-height: 630px) {
          margin: 0 0 24px 0;
        }
        @media (max-width: 991px) {
          margin: 0 0 24px 0;
        }
      }

      .text-paragraph {
        margin: 20px 0 0 0;
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        font-weight: normal;
      }

      form {
        margin: 0px auto 0px;

        .form-group {
          .MuiFormControl-root {
            width: 100%;
          }
        }
      }

      .KeepMeSignin {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0 38px 0;

        .custom-checkbox {
          height: 20px;
          margin: 0 8px 0 0;
          position: relative;

          input[type="checkbox"] {
            position: absolute;
            left: 0;
            width: 20px;
            height: 20px;
            opacity: 0;
            z-index: 1;
          }

          .checkmark {
            height: 20px;
            width: 20px;
            border: 0;
            border-radius: 100px;
            display: inline-block;
            position: relative;
            background-color: var(--gray-color-light);

            img {
              display: none;
            }
          }

          input[type="checkbox"]:checked {
            + .checkmark {
              background: var(--primary-dark-color);

              &:after {
                content: "";
                position: absolute;
                left: 8px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
              }
            }
          }
        }

        .label {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 16px;
          padding-top: 4px;
        }
      }
      .forgot-password {
        margin-bottom: 50px;
        @media (max-height: 750px) {
          margin-bottom: 30px;
        }
        a {
          color: var(--primary-dark-color);
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 18px;
          text-align: center;
          text-decoration: none;
          font-family: "TTCommons-Medium";
          border-bottom: 1px solid var(--primary-dark-color);
        }
      }
      .forgot-password-section {
        .back-to-url {
          background-color: var(--white-color);
          box-shadow: var(--card-box-shadow);
          text-decoration: none;
          color: var(--field-label-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          height: 40px;
          width: 80px;
          display: flex;
          align-items: center;
          border-radius: 25px;
          line-height: 19px;
          transition: all 0.3s;
          justify-content: center;
          position: absolute;
          left: 20px;
          @media (max-width: 1250px) {
            position: initial;
            justify-content: center;
            margin: 0 auto 16px;
          }
          img {
            margin: 2px 2px 0 -8px;
            height: 12px;
            transform: rotate(180deg);
          }
          span {
            padding-top: 6px;
          }
          &:hover {
            box-shadow: 0 1px 14px 3px #d1dde5;
          }
        }
        form {
          margin-top: 30px;
          .form-group {
            label {
              margin: 0 0 7px 0;
              padding: 5px 0 0 0;
              line-height: 12px;
              color: #8094ab;
              display: block;
              font-family: "TTCommons-Regular";
              font-size: 15px;
              text-align: center;
            }
          }
        }
        .verify-text {
          p {
            margin: 0;
            letter-spacing: 0;
            font-size: 15px;
            line-height: 16px;
            font-family: "TTCommons-Regular";
            color: #607790;
            font-weight: 400;
            text-align: center;
            a {
              color: #607790;
              text-decoration: none;
              border-bottom: 2px solid #8094ab;
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .left-content {
    width: 100%;
    overflow: hidden;
    @media (max-width: 991px) {
      border-radius: 0 0 58px 58px;
    }
    @media (max-width: 767px) {
      border-radius: 0 0 30px 30px;
    }
    .left-content-container {
      width: 100%;
      text-align: left;
      padding: 40px 84px;
      background-image: url("../assets/svg/login-bg.svg");
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
      background-position: left top;
      background-color: var(--primary-color);
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 1250px) {
        padding: 32px 48px;
      }
      @media (max-width: 991px) {
        padding-bottom: 120px;
        height: auto;
        justify-content: center;
        text-align: center;
      }
      @media (max-width: 767px) {
        padding: 24px 16px;
        padding-bottom: 100px;
      }
      .brand-name {
        align-self: flex-start;
        a {
          display: inline-block;
          img {
            height: 43px;
            max-width: 100%;
            vertical-align: middle;
          }
        }
      }
      .text-content {
        margin-top: auto;
        width: 100%;
        margin-bottom: 20vh;
        h1 {
          color: #ffffff;
          font-family: "TTCommons-DemiBold";
          font-size: 45px;
          font-weight: 600;
          letter-spacing: -0.16px;
          line-height: 43.1px;
          margin: 0px 0 22px 0px;
          max-width: 752px;
          @media (max-width: 1250px) {
            font-size: 38px;
            line-height: 35px;
          }
          @media (max-width: 991px) {
            max-width: 100%;
            margin: 48px 0 16px 0;
          }
          @media (max-width: 991px) {
            font-size: 24px;
            line-height: 24px;
            margin: 24px 0 16px 0;
          }
        }
        h4 {
          color: #ffffff;
          font-family: "TTCommons-Regular";
          font-size: 22px;
          letter-spacing: 0;
          line-height: 24px;
          font-weight: 400;
          @media (max-width: 991px) {
            font-size: 18px;
            line-height: 18px;
          }
          strong {
            font-family: "TTCommons-DemiBold";
            font-weight: 600;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .toc-text {
    color: var(--primary-dark-color);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 12px;
    margin: 0;
    font-family: "TTCommons-Regular";
    margin: auto 0 0px 0;
    margin: auto 0 0px 0;
    width: 100%;
    @media (max-width: 991px) {
      margin-top: 32px;
    }
    a {
      border-bottom: 1px solid var(--primary-dark-color);
      cursor: pointer;
      color: var(--primary-dark-color);
      text-decoration: none;
    }
    &.Mui-error {
      margin: 8px 0 0 0;
    }
  }
}

.notificationsWrapperContent {
  .drag-wrapper {
    .drag-content-wrap {
      .bg-wrap {
        .drag-main-row {
          .drag-item {
            .left {
              width: 65%;

              h6 {
                font-size: 11px;
                letter-spacing: 1.5px;
                line-height: 16px;
                font-family: "brandon_grotesquebold";
                text-transform: uppercase;
                min-width: 85px;
                color: var(--primary-dark-color);
                margin: 0 22px 0 0;
                max-width: 85px;
              }

              h3 {
                font-size: 18px;
                font-weight: 500;
                line-height: 19px;
              }

              .img-wrap {
                max-width: 44px;
              }
            }

            .actions-div {
              gap: 10px;
              display: flex;
              width: 35%;
              justify-content: flex-end;

              .ActionSwitch {
                margin-right: 32px;
              }

              a {
                img {
                  width: auto;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  + .notificationsWrapperContent {
    margin: 15px 0 0 0;
  }
}

.SwitchText {
  display: flex;
  align-items: center;

  > div {
    margin: -4px 10px 0 0;
  }
}

.roundedCustomfileuplod {
  .fileupload-custom {
    .dropzone {
      height: 94px;
      width: 94px;
      margin: 0 auto 10px;
      border-radius: 100px;
      min-height: auto;
      .placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          // max-width: 50px;
          height: auto;
        }
      }
    }
  }
}

.DiscoverAdd {
  justify-content: center;
}

.FilterSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 480px;
  margin: 0 auto 20px;

  .SearchIcon {
    min-width: 40px;
    text-align: center;
    cursor: pointer;
  }

  .filterIcon {
    cursor: pointer;
  }

  div {
    &:nth-child(2) {
      width: 100%;
      max-width: 250px;
      margin: 0 20px 0 10px;

      > div {
        width: 100%;

        input {
          padding: 0;
          height: 28px !important;
          min-height: auto;
          border-radius: 0 !important;
          border-left: 0;
          border-top: 0;
          border-right: 0;
          background: transparent;
          text-align: left;
        }
      }

      .filterIcon {
        height: 40px;
        width: 40px;
        border-radius: 3px;
        background-color: #ffffff;
        box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      }
    }
  }

  .clearBtn {
    position: fixed;
    margin-left: 380px;
  }
}

.FilterBgWrap {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px #d1dde5;
  padding: 20px;
  margin: 0 auto 0px;
  padding-bottom: 20px;
  max-width: 1040px;
  @media (max-width: 767px) {
    margin-bottom: 15px;
    padding: 24px;
  }
  &--manage-topic-career {
    max-width: 700px !important;
    margin: initial;
  }
  &--manage-topic-admissions {
    max-width: 362px !important;
    margin: initial;
  }
  .FilterTitleRow {
    margin: 0 0 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
      margin: 0 0 8px 0;
    }
    h3 {
      color: var(--primary-dark-color);
      font-size: 18px;
      line-height: 20px;
      position: relative;
    }

    > img {
      width: 12px;
      cursor: pointer;
    }

    .leftWrap {
      align-items: center;
      display: flex;

      .FilterList {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 20px;
        margin: 0 10px 0 20px;
        height: 20px;
      }
    }
  }

  .row {
    margin-left: -8px;
    margin-right: -8px;

    > div {
      padding-right: 8px;
      padding-left: 8px;
      + div {
        @media (max-width: 767px) {
          margin-top: 8px;
        }
      }
    }
  }

  .form-group {
    margin-bottom: 30px;
  }
}

.FilterResult {
  max-width: 640px;
  margin: 0 auto;
}

.ResultData {
  .ResultItem {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: var(--card-box-shadow);
    padding: 15px 30px 15px 15px;
    transition: all 0.3s;

    .ContentDiv {
      display: flex;
      align-items: center;
      justify-content: center;

      &.maxWidth {
        max-width: calc(100% - 74px);
        width: 100%;
        margin: 0 auto;
      }

      .imgWrap {
        height: 70px;
        width: 70px;
        margin: 0 10px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--icon_background);
        border-radius: 100px;
        min-width: 70px;
        overflow: hidden;

        img {
          width: 100%;
          max-width: 100px;
        }
      }

      .UserDetails {
        width: 100%;
        padding-right: 30px;

        h4 {
          color: var(--primary-dark-color);
          word-break: break-word;
          display: flex;
          align-items: center;
          margin: 0 0 4px 0;
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          .tag {
            border-radius: 12.5px;
            background-color: var(--icon_background);
            padding: 3px 8px 0;
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            font-size: 16px;
            letter-spacing: 0;
            line-height: 18px;
            display: flex;
            align-items: center;
            min-height: 25px;
            min-width: 62px;
            justify-content: center;
            margin-bottom: 0;
            margin-left: 8px;
            margin-top: -4px;
          }
        }
        .userTag {
          font-size: 15px;
          margin-top: 3px;
        }

        p {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          margin: 0 0 4px 0;
        }

        .flagDiv {
          display: flex;
          align-items: center;
          color: #607790;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;

          img {
            width: 22px;
            margin: -4px 8px 0 0;
          }
        }
      }

      .ButtonWrap {
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        button {
          height: 35px;
          min-height: 35px;
          width: 120px;
          min-width: 120px;
        }
      }
    }
    &:hover {
      .ButtonWrap {
        opacity: 1;
        visibility: visible;
      }
    }

    + .ResultItem {
      margin: 15px 0 0 0;
    }
  }
}

.filterTopics {
  .FilterBgWrap {
    max-width: 781px;
  }
}

.ChatFilter {
  .ChatSearch {
    margin: 0 0 12px 0px;
  }

  .ChatFilterOptions {
    .MuiPaper-root {
      box-shadow: none;
      padding: 0 !important;
      margin: 0;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      overflow: hidden;

      &:before {
        display: none;
      }

      .MuiAccordionSummary-root {
        min-height: auto;
        padding: 27px 25px 23px 25px;
        border-radius: 3px;
        background-color: #ffffff;

        .MuiAccordionSummary-content {
          margin: 0;

          .accordionHeader {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 22px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
            display: flex;

            span.badge {
              height: 20px;
              width: 20px;
              border-radius: 10px;
              background-color: #f76155;
              font-size: 16px;
              letter-spacing: 0;
              line-height: 16px;
              text-align: center;
              font-family: "TTCommons-Regular";
              color: #fff;
              padding: 4px 0 0 0px;
              font-weight: 300;
              margin: 0 0 0 15px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .MuiButtonBase-root {
          padding: 0;

          svg {
            fill: #0030b5;
          }
        }
      }

      + .MuiPaper-root {
        margin: 10px 0 0 0 !important;
      }
    }

    .MuiCollapse-container {
      padding: 0 0px;

      .MuiAccordionDetails-root {
        padding: 0;
      }
    }

    .chatUserItems {
      width: 100%;
      padding: 0 0px;
      overflow-y: scroll;
      max-height: 65vh;

      .ChatItem {
        display: flex;
        align-items: center;
        padding: 15px 20px;
        position: relative;
        cursor: pointer;

        .img {
          width: 50px;
          height: 50px;
          margin: 0 10px 0px 0;
          border-radius: 100px;
          text-align: center;
          overflow: hidden;
          min-width: 50px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .no-user-icon {
          img {
            width: 50px !important;
            height: 50px !important;
          }
        }

        .usreDescription {
          h4 {
            color: var(--primary-dark-color);
            margin: 0 0 3px 0;
            word-break: break-word;
          }

          p {
            margin: 0;
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 117px;
            width: 100%;
          }
        }

        .ChatStatus {
          margin-left: auto;
          text-align: right;
          min-width: 60px;

          span.time {
            color: #566d82;
            font-family: "TTCommons-Regular";
            font-size: 12px;
            letter-spacing: 0;
            line-height: 12px;
            width: 100%;
            display: block;
            text-align: right;
          }

          span.badge {
            height: 20px;
            width: 20px;
            border-radius: 10px;
            background-color: #f76155;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 16px;
            text-align: center;
            font-family: "TTCommons-Regular";
            color: #fff;
            padding: 4px 0 0 0px;
            font-weight: 300;
            margin: 0 0 0 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-bottom: 6px;
          }
        }

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          top: 0;
          height: 1.5px;
          background: #e8eef2;
          width: calc(100% - 40px);
        }

        &:hover,
        &.active {
          background-color: var(--primary-color);

          &:before {
            background: var(--primary-color);
          }

          .usreDescription {
            h4,
            p {
              color: #fff;
            }
          }

          .ChatStatus {
            span.time {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.chatboxWrapper {
  .chatboxWrapperheader {
    display: flex;
    align-items: center;
    margin: 0 0 12px 0;

    h3 {
      color: var(--primary-dark-color);
    }

    img {
      margin: 0 0 0 10px;
    }
  }
}

.Chatwrapwrapper {
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  border-radius: 3px;
  padding: 30px 16px 30px 30px;
}

.file-preview {
  margin: 15px 0 15px 25px;

  .preview-item {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    color: #f76155;
    font-family: "TTCommons-regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    color: #f76155;

    img {
      height: 15px;
      width: 15px;
      margin: -2px 10px 0 0 !important;
    }

    .progress {
      height: 6px;
      width: 150px;
      margin-left: 10px;
      border: 1px solid #f76155;
      border-radius: 5px;

      .progress-bar {
        border: 1.71px solid #f76155;
        border-radius: 5px;
        background-color: #f76155;
      }
    }

    + .preview-item {
      margin-top: 12px;
    }
  }
}

.Chatwrap {
  min-height: 711px;
  position: relative;
  padding-bottom: 80px;
  box-sizing: border-box;

  .chatmessageWrap {
    max-height: 711px;
    overflow-y: auto;
    overflow-x: hidden;

    h4 {
      color: var(--primary-dark-color);
      margin: 0 0 28px 0;
      font-weight: 500;
    }

    .chatsItem {
      .item {
        display: flex;
        max-width: 380px;

        .user-img {
          width: 50px;
          height: 50px;
          min-width: 50px;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 20px 0 0;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .userMessage {
          h4 {
            margin: 0;
            color: var(--primary-dark-color);
            margin: 15px 0 3px 0;
          }

          span {
            color: #566d82;
            font-family: "TTCommons-Regular";
            font-size: 12px;
            letter-spacing: 0;
            line-height: 12px;
            text-align: right;
            margin-left: 8px;
          }

          p {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            margin: 0 0 8px 0;
          }
        }

        + .item {
          margin: 16px 0 0 0;
        }

        + h4 {
          margin-top: 50px;
        }

        &.rightChat {
          margin-left: auto;
          justify-content: flex-end;

          .user-img {
            order: 2;
            margin: 0 0 0 20px;
          }

          .userMessage {
            text-align: right;
          }
        }
      }
    }
  }

  .TypingBox {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;

    > div {
      &:nth-child(2) {
        width: calc(100% - 84px);

        .MuiFormControl-root {
          width: 100%;
          padding-right: 20px;

          .MuiInputBase-input {
            text-align: left;
            padding-left: 67px;
          }
        }
      }

      &:last-child {
        button {
          width: 84px;
          min-width: 84px;
        }
      }
    }

    .AttachmentPin {
      position: absolute;
      width: auto !important;
      z-index: 2;
      margin-left: 27px;

      .fileupload-custom {
        .dropzone {
          background: transparent;
          height: auto;
          margin: 0;
          cursor: pointer;
        }
      }
    }
  }
}

.download-doc {
  border-bottom: 0;

  span {
    margin-left: 5px !important;
    font-size: 15px !important;
    letter-spacing: 0 !important;
    line-height: 17px !important;
    display: inline-block;
    border-bottom: 1px solid #f76155;
    color: #f76155 !important;
  }
}

.ChatUserAction {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 10px 0;

  img {
    cursor: pointer;
  }
}

.ChatUserProfile {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  padding: 30px;
  text-align: center;
  overflow: initial !important;

  .UserAvtar {
    width: 100px;
    height: 100px;
    margin: 0 auto 20px;
    border-radius: 100px;
    background: #bfced8;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &.no-user-image {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 50px;
        height: auto;
      }
    }
  }

  h3 {
    color: var(--primary-dark-color);
    font-weight: 500;
    margin: 0 0 15px 0;
    word-break: break-word;

    img {
      margin-left: 10px;
      margin-top: -4px;
    }
  }

  .detailsinfo {
    margin: 15px 0 32px 0;

    h5 {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
    }

    p {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      text-align: center;
      margin: 2px 0 0 0;
    }
  }

  .Description {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    max-width: 228px;
    margin: 0 auto 27px;
  }

  .total-participants {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 30px 0;

    h4 {
      color: var(--primary-dark-color);
    }

    .tag {
      margin-left: 10px;
      min-width: 70px;
    }
  }
}

.tag {
  border-radius: 18.5px;
  background-color: #f1f4f7;
  display: inline-block;
  padding: 7px 7px 2px 7px;
  color: var(--primary-dark-color);
  font-family: "TTCommons-Regular";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  min-width: 84px;
  margin: 0;
  min-height: 28px;
  text-transform: capitalize;
  width: fit-content;

  &--white {
    background-color: #ffffff;
    box-shadow: var(--card-box-shadow) !important;
  }
}

ul.SocialUl {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;

  li {
    margin: 0 15px;
    color: #607790;
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;

    .socialImg {
      height: 39px;
      width: 39px;
      border-radius: 29.41px;
      box-shadow: 0 1px 11px 2px rgba(209, 221, 229, 0.4);
      margin: 0 auto 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
    }
  }
}

.topic-creator {
  text-align: left;
  max-width: 200px;
  margin: 34px auto 0;

  .topic-creator-item {
    .item {
      display: flex;
      align-items: center;

      .img-wrap {
        height: 40px;
        width: 40px;
        min-width: 40px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        margin: 0 10px 0 0;

        img {
          width: 100%;
        }
        &.no-user-icon {
          img {
            width: 40px !important;
            height: 40px !important;
          }
        }
      }

      .name {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        row-gap: 6px;
        .topic-user {
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          color: var(--primary-dark-color);
          margin-top: 3px;
        }
        .topic-tag {
          display: flex;
          justify-content: center;
          padding: 6px 5px 0px 10px;
          height: 25px !important;
          background: #0030b5;
          letter-spacing: 0;
          line-height: 16px;
          border-radius: 12.5px;
          span {
            font-size: 15px;
            min-width: fit-content;
            color: #ffffff;
            font-family: "TTCommons-Regular";
            text-align: center;
          }
        }
      }

      + .item {
        margin-top: 15px;
      }
    }
  }

  .loadmore {
    margin: 20px 0 0 0;
  }
}

.PostContentWrap {
  .PostContentItem {
    background-color: #ffffff;
    box-shadow: var(--card-box-shadow);
    padding: 30px;
    margin: 0 0 20px 0;

    .PostHead {
      display: flex;
      align-items: center;
      margin: 0 0 10px 0;

      .profileImg {
        height: 40px;
        width: 40px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin: 0 10px 0 0;
        border-radius: 100px;

        img {
          width: 100%;
          object-fit: cover;
          object-position: center;
          height: 100%;
        }
        &.no-user-icon {
          img {
            width: 40px !important;
            height: auto !important;
          }
        }
      }

      .ProfileDiv {
        display: flex;
        align-items: center;

        h4 {
          color: var(--primary-dark-color);
          margin: 0 10px 0px 0;
        }

        p {
          color: var(--field-label-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
        }
      }
    }

    .PostBody {
      p {
        margin: 0 0 22px 0;
      }

      .imgWrap {
        margin: 0 0 15px 0;

        img {
          width: 100%;
        }
      }

      h3 {
        color: var(--primary-dark-color);
      }
    }

    .postComment {
      position: relative;
      padding-top: 24px;
      margin: 30px 0px 0 0;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        height: 1.5px;
        background: #d1dde5;
        top: 0;
      }

      .ImgWrap {
        height: 50px;
        width: 50px;
        background-color: #bfced8;
        min-width: 50px;
        border-radius: 100px;
        margin: 0 15px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .TypingBox {
        display: flex;
        align-items: center;

        > div {
          &:nth-child(2) {
            width: 100%;
            padding-right: 15px;

            .MuiFormControl-root {
              width: 100%;

              .MuiInputBase-input {
                height: 40px;
                min-height: auto;
                text-align: left;
                padding: 13px 20px 10px;
              }
            }
          }

          &:nth-child(3) {
            button {
              min-width: 84px;
            }
          }
        }
      }
    }

    .post-comments-wrapper {
      .postComment {
        max-width: 474px;
        margin: 30px auto 0 !important;
        border: 0 !important;
        padding: 0 !important;

        &:before {
          display: none;
        }
      }
    }
  }
}

.FilterMainWrap {
  padding: 0 60px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.clearBtn {
  color: #000c3d;
  font-family: "TTCommons-Regular";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 19px;
  border-bottom: 1px solid #000c3d;
  cursor: pointer;
  margin-left: 10px;
}

.FilterCheckList {
  h6 {
    color: var(--primary-dark-color);
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 16px;
    text-transform: uppercase;
    margin: 0 0 20px 0;
    font-family: "brandon_grotesquebold";
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      color: #607790;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;

      > div {
        margin: 0 15px 0 0;
      }

      + li {
        margin: 10px 0 0 0;
      }
    }

    &.admin-filters {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      li {
        width: 25%;
        margin: 0 0 15px 0;

        + li {
          margin-top: 0;
        }
      }
      &.chips-wrapper {
        li {
          width: auto;
          margin: 0 10px 10px 0;
          > div {
            margin: 0;
            border-radius: 15px;
            padding: 12px 12px 8px;
            height: auto;
            font-size: 18px;
            font-family: "TTCommons-Medium";
            font-weight: 500;
            line-height: 20px;
            background: var(--icon_background);
            color: var(--primary-dark-color);
            &:hover,
            &.MuiChip-clickableColorPrimary {
              background-color: var(--primary-color);
              color: var(--white-color);
            }
          }
        }
      }
    }
  }
}

.year-checkbox-ul {
  display: flex;
  flex-wrap: wrap;

  li {
    width: 16.66%;
    margin: 0 0 10px 0 !important;
  }
}

.ShowDiv {
  margin: 15px 0 0 0;

  span {
    color: #0030b5;
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    border-bottom: 1px solid #0030b5;
    cursor: pointer;
  }
}

.FilterSearchBox {
  margin: 0 20px 0 6px;
  .MuiInputBase-formControl {
    .MuiInputBase-input {
      padding: 0;
      height: 28px !important;
      min-height: auto;
      border-radius: 0 !important;
      border-left: 0;
      border-top: 0;
      border-right: 0;
      background: transparent;
      text-align: left;
      min-width: 250px;
      border-color: #607790;
      @media (max-width: 767px) {
        min-width: 180px;
        width: 100%;
      }
    }
  }
}

.CreatePostWrap {
  background-color: #ffffff;
  box-shadow: var(--card-box-shadow);
  padding: 20px;
  margin: 0 0 15px 0;
  border-radius: 10px;

  label {
    margin: 0 0 7px 0;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
  }

  .cancel {
    margin-left: auto;
    margin-top: -8px;
    display: flex;
    align-items: center;

    img {
      width: 14px;
      cursor: pointer;
    }
  }

  .post-loader {
    div {
      position: initial !important;
      transform: none !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
  }

  .TypingBox {
    display: flex;
    align-items: center;
    position: relative;

    .text-area-wrapper {
      width: 100%;
      position: relative;
      margin-bottom: -7px;
      textarea {
        resize: none;
        width: 100%;
        border: 2px solid var(--gray-color-light);
        border-radius: 10px;
        background-color: #ffffff;
        padding: 15px 20px 12px 48px;
        font-size: 18px;
        line-height: 24px;
        font-family: "TTCommons-Regular";
        color: var(--primary-dark-color);
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: var(--field-label-color) !important;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: var(--field-label-color) !important;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: var(--field-label-color) !important;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: var(--field-label-color) !important;
        }
        // --Focus style
        &:focus {
          border-color: var(--gray-color) !important;
        }
        &:hover {
          border-color: #bfced8;
        }
        /* scroll styling start */
        scrollbar-width: thin !important;
        scrollbar-color: var(--gray-color-light) transparent; /* scroll thumb and track */
        &::-webkit-scrollbar {
          width: 7px; /* width of the entire scrollbar */
          transition: all ease-in-out 500ms;
        }
        &::-webkit-scrollbar-track {
          background: transparent !important; /* color of the tracking area */
          border-radius: 50px;
          width: 7px;
          -webkit-box-shadow: inset 0 0 6px rgba(100, 100, 111, 0.2) 0px 7px
            29px 0px;
          padding: 10px 0 10px 0 !important;
          margin: 10px 0 10px 0 !important;
        }
        &::-webkit-scrollbar-thumb {
          background-color: var(
            gray-color-light
          ) !important; /* color of the scroll thumb */
          border-radius: 50px; /* roundness of the scroll thumb */
          height: 20px;
        }
        &::-webkit-scrollbar-corner {
          border-radius: 50%;
        }
        &::-webkit-scrollbar-thumb:hover {
          border: 0;
          background-color: var(
            --gray-color
          ) !important; /* color of the scroll thumb */
        }
        /* scroll styling end */
      }
    }

    .AttachmentPin {
      position: absolute;
      width: auto !important;
      left: 18px;
      z-index: 2;
      top: 50%;
      display: flex;
      transform: translateY(-50%);
      margin-top: 0px;

      .fileupload-custom {
        .dropzone {
          background: transparent;
          height: auto;
          margin: 0;
          cursor: pointer;
          min-height: auto;
        }
      }
    }

    > div {
      &:nth-child(2) {
        width: 100%;
        padding-right: 15px;

        .MuiTextField-root {
          width: 100%;

          .MuiInputBase-input {
            text-align: left;
            padding-left: 48px;
          }
        }
      }

      button {
        min-width: 84px;
        padding-top: 5px !important;
      }
    }
    .btn {
      padding: 0 !important;
    }
  }

  h3 {
    margin: 0 0 15px;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
  }

  h4 {
    margin: 0;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Regular";
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
  }
}

.PostheadingWrap {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  min-height: 40px;

  h3 {
    color: var(--primary-dark-color);
    margin: 0 10px 0 0;
    padding-top: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  button {
    padding: 0;
    color: var(--primary-dark-color);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    font-family: "TTCommons-Medium";
    font-weight: 500;
    background: transparent;
    box-shadow: var(--card-box-shadow);
    background-color: var(--white-color);
    border-radius: 100px;
    padding: 15px 15px 15px 15px;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    margin-right: 15px;
    .text-label {
      position: relative;
      top: 2px;
    }
    img {
      margin-left: 6px;
    }
    &:hover {
      box-shadow: var(--box-shadow);
      background-color: #fff;
    }
    .MuiTouchRipple-root {
      display: none !important;
    }
  }

  .RequestWrap {
    position: relative;

    button.btn-active {
      padding: 7px 10px 4px !important;
      display: flex;
      align-items: center;
    }

    .RequestWrapShowing {
      position: absolute;
      width: 100%;
      z-index: 1;
      border-radius: 3px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      min-width: 569px;
      top: 50px;
      padding: 25px;
      z-index: 11;

      &:before {
        content: "";
        position: absolute;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 15px solid white;
        top: -15px;
        left: 32px;
      }

      .RequestItem {
        display: flex;
        align-items: center;

        .ImgWrap {
          width: 40px;
          height: 40px;
          background: #bfced8;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          margin: 0 15px 0 0;
          min-width: 40px;
          overflow: hidden;

          img {
            width: 100%;
          }
        }

        .RequestContent {
          width: 100%;
          max-width: 205px;

          h4 {
            color: var(--primary-dark-color);
            margin: 0 0 2px 0;
          }

          p {
            margin: 0;
          }
        }

        .RequestAction {
          margin-left: auto;

          span {
            display: inline-block;
            cursor: pointer;

            + span {
              margin-left: 20px;
            }
          }
        }

        + .RequestItem {
          border-top: 1px solid #e5eaf7;
          margin-top: 24px;
          padding-top: 24px;
        }
      }
    }
  }
}

.EmptyMessage {
  margin: 116px 0 0 0;
  color: var(--primary-dark-color);

  h2 {
    margin: 0 0 17px 0;
  }
}

.PopupGraybg {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(128, 148, 171, 0.2);
  z-index: 10;
  overflow: hidden;
}

.webinar-registerd {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: var(--box-shadow);
  max-width: 670px;
  margin: 0 auto;

  .box-wrap {
    display: flex;
    align-items: center;

    .left {
      min-width: 162px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 162px;
      padding: 20px;
      img {
        width: 100%;
      }
    }

    .right {
      padding: 15px 15px 15px 0;
      position: relative;
      width: calc(100% - 144px);

      h4 {
        color: var(--primary-dark-color);
        max-width: 330px;
        position: relative;
        margin: 0 0 2px 0px;
        padding-right: 25px;

        img {
          margin: -2px 0 0 6px;
        }

        img.star {
          max-width: 18px;
          position: initial;
          margin: -1x 0 0 6px;
        }

        + h4 {
          font-family: "TTCommons-Regular";
          font-size: 16px;
          line-height: 18px;
          margin: 0 0 2px 0;
        }
      }

      h5 {
        color: var(--field-label-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        max-width: 330px;

        img {
          margin: -2px 10px 0 0;
        }

        + h5 {
          margin: 6px 0 6px 0;
        }
      }

      .rounded-btn {
        position: absolute;
        top: 34px;
        right: 30px;
      }
    }
    .actions-wrapper {
      display: flex;
      align-items: center;
      padding-right: 15px;
      button {
        min-width: auto !important;
        padding: 8px 18px 4px !important;
        height: 35px;
      }
      > img {
        margin-left: 15px;
      }
    }
  }

  &.webinar-resource {
    max-width: 670px;
    margin: 0 auto;

    .box-wrap {
      .left {
        border-radius: 10px 0 0 10px;
        background-color: rgba(241, 244, 247, 0.5);
        min-width: 174px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 18px 20px 16px;

        img {
          max-width: 75px;
        }
      }

      .right {
        padding: 20px 30px 20px 20px;

        h4 {
          display: flex;
          align-items: flex-start;
        }

        .rounded-btn {
          top: 50%;
          transform: translateY(-50%);
          min-width: 85px !important;
        }
      }
    }
  }

  &.webinar-student-services {
    max-width: 670px;
    margin: 0 auto;

    .box-wrap {
      min-height: 111px;

      .actions {
        display: flex;

        .rounded-btn {
          margin-left: 10px;
          margin-right: 20px;
          min-width: 115px !important;
        }
      }
    }
  }

  &.add-resource-preview {
    .default-image-100 {
      object-fit: contain;
    }

    .left {
      width: 180px;
      max-width: 180px;
      min-width: 180px;
    }
  }

  &.webinar-job-intern {
    max-width: 670px;
    margin: 0 auto;

    .box-wrap {
      .left {
        width: 50px;
        max-width: 50px;
        min-width: 50px;
        height: 50px;
        padding: 0;
        margin: 0 15px;
        border-radius: 100px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .right {
        width: calc(100% - 90px);

        .rounded-btn {
          top: 50%;
          transform: translateY(-50%);
          min-width: 85px !important;
        }
      }
    }
  }
}

.link-wrap {
  .link {
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: var(--card-box-shadow);
    padding: 14px 22px 10px;
    display: inline-block;
    border: 0;
    height: 40px;
    font-size: 16px;
    font-family: "TTCommons-Medium";
    color: var(--primary-dark-color) !important;
    transition: all 0.3s;
    &:hover {
      box-shadow: var(--box-shadow);
    }
    + .link {
      margin-left: 30px;
    }
  }
}

.preview-div {
  .preview-ul {
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      margin: 5px 5px 5px 5px;
      background-color: #f1f4f7;
      padding: 12px 22px 10px;
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      min-width: 63px;
      min-height: 40px;
      border-radius: 100px;
      height: 40px;
    }
    .preview-ul-see-more-less {
      cursor: pointer;
      color: #ffffff;
      background-color: #000485;
    }
  }
}

.post-card {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: var(--card-box-shadow);
  max-width: 280px;
  margin: 0 auto;

  .box-wrap {
    padding: 20px;

    .left {
      margin: 15px 0 15px 0;

      img {
        width: 100%;
      }
    }

    .right {
      h4 {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 21px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 23.1px;
        margin: 0 0 7px 0;
      }
      .text-break {
        margin-left: 10px;
        max-width: 430px;
        word-wrap: break-word;
      }

      h5 {
        color: #566d82;
        margin: 0 0 7px 0;
      }

      p {
        margin: 0;
        font-size: 11.2px;
        color: #566d82;
        line-height: 16.8px;

        span {
          color: var(--primary-dark-color);
          font-size: 14px;
          letter-spacing: 0;
          line-height: 16.8px;
          margin-left: 4px;
        }
      }
    }
  }

  h4 {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin: 0 0 12px 0;
  }

  &.main-post-card {
    max-width: 325px;

    .post-header {
      display: flex;
      align-items: center;

      .img-wrap {
        width: 35px;
        height: 35px;
        border-radius: 100px;
        overflow: hidden;
        margin: 0 10px 0 0;

        img {
          width: 100%;
        }
      }

      .img-content {
        h4 {
          color: var(--primary-dark-color);
          font-size: 12.6px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 13.3px;
          margin: 0 0 2px 0;
        }

        p {
          color: #607790;
          line-height: 10.5px;
          font-size: 10.5px;
          margin: 0;
        }
      }

      .right {
        h5 {
          margin: 0 !important;
        }
      }

      .MuiCardHeader-action {
        margin-left: auto;
      }
    }
  }
  &.only-for-homefeed {
    max-width: 100%;
    .post-header {
      p {
        margin-bottom: 0;
      }
    }
    .left {
      iframe {
        width: 100%;
      }
    }
  }
}

.circle-card {
  .box-wrap {
    border-radius: 45px;
    background-color: #f1f4f7;
    padding: 16px 20px 8px 20px;
    text-align: center;

    h4 {
      color: var(--primary-dark-color);
      margin: 0 0 6px 0;
    }

    h6 {
      color: var(--primary-dark-color);
      line-height: 18px;
    }

    + .box-wrap {
      margin: 15px 0 0 0;
    }
  }

  &.max-width-card {
    max-width: 538px;
    margin: 0 auto;
  }

  .max-width350 {
    max-width: 350px;
    margin: 0 auto;
  }

  .subtask-card {
    margin: 10px 0 0 0;

    .box-wrap {
      padding: 10px 20px 10px 20px;

      h4 {
        margin: 0;
      }

      + .box-wrap {
        margin: 10px 0 0 0;
      }
    }
  }
}
.meeting-btn {
  margin: -45px 0 0 0;
}
.calender-wrapper {
  /* margin: -45px 0 0 0; */
  background: var(--icon_background);

  .fc-header-toolbar {
    display: flex !important;
    justify-content: left !important;
    .fc-toolbar-chunk:first-child {
      display: none;
    }
    .fc-toolbar-chunk {
      margin-right: 16px;
      > div {
        align-items: center;
      }
      button {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 42px;
        min-height: 42px;
        &.fc-prev-button {
          order: 2;
        }
        &.fc-next-button {
          order: 3;
        }
      }
      .fc-toolbar-title {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        padding: 4px 0 0 0;
        order: 1;
        margin-right: 8px;
      }
      .fc-button-group {
        button {
          color: var(--primary-dark-color) !important;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 17px;
          font-family: "TTCommons-Medium";
          font-weight: 500;
          box-shadow: var(--card-box-shadow) !important;
          padding: 8px 16px 5px 16px;
          display: flex;
          align-items: center;
          transition: all 0.3s;
          background-color: #fff !important;
          border-radius: 100px !important;
          min-width: 80px;
          transition: all 0.3s;
          &:hover {
            box-shadow: var(--box-shadow) !important;
          }
          &.fc-button-active {
            background-color: var(--link-color) !important;
            color: #fff !important;
          }
        }
      }
    }
  }
  .fc-view {
    .fc-scrollgrid {
      > thead {
        > tr {
          > th {
            border: 0;
            .fc-col-header {
              th {
                border: 2px solid #fff;
              }
            }
          }
        }
      }
      > tbody {
        > tr {
          > td {
            border: 0;
          }
        }
      }
    }
  }
  .fc-toolbar-chunk > div {
    display: flex !important;
    justify-content: center !important;
  }
  .fc-dayGridMonth-button,
  .fc-timeGridWeek-button,
  .fc-timeGridDay-button {
    color: var(--primary-dark-color) !important;
    background-color: #f1f4f7 !important;
    margin-right: 5px !important;
    border: 1px solid #f1f4f7 !important;
    border-radius: 2px !important;
  }
  .fc-button-active {
    background-color: var(--primary-color) !important;
    color: #f1f4f7 !important;
    border-radius: 2px !important;
  }

  .fc-toolbar-title {
    font-size: 22px !important;
    font-family: "TTCommons-Medium" !important;
    color: var(--primary-dark-color) !important;
  }
  .fc-today-button {
    display: none !important;
  }
  .fc-toolbar-ltr {
    background: #fafbfd !important;
    margin: 0 !important;
    padding-bottom: 1em !important;
  }
  .fc-next-button {
    color: var(--primary-dark-color) !important;
    background: #fafbfd !important;
    border: none !important;
    padding-top: 0 !important;
  }
  .fc-prev-button {
    color: var(--primary-dark-color) !important;
    background: #fafbfd !important;
    border: none !important;
    padding-top: 0 !important;
  }
  .fc-popover {
    z-index: 9 !important;
  }
  .fc-more-popover {
    padding: 30px;
    .fc-popover-header {
      background-color: #fff;
    }
    .fc-popover-title {
      width: 100%;
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
    }
    .fc-popover-close {
      color: var(--primary-dark-color);
      opacity: 1;
      font-size: 20px;
      &:hover {
        color: #d82a24;
      }
    }
    .fc-popover-body {
      padding: 15px;
      .fc-event-title,
      .fc-event-title-container {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 25px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &:hover {
          color: #d82a24;
        }
      }
      .past-event {
        color: #607790;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 25px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .upcoming-event {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 25px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .fc-event-title.selected-event {
        color: #d82a24;
      }
      .selected-event {
        color: #d82a24;
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 25px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .fc-h-event {
      background-color: #fff;
      border: none;
    }
  }

  table {
    border: 0 !important;
    .cal-day-header {
      border: 2px solid #fff;
      height: 54px;
      vertical-align: middle;
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      border-bottom: 0;
      background-color: var(--icon_background);
      padding-top: 4px;
      a {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        text-decoration: none;
        padding: 0 !important;
      }
    }

    tbody {
      td {
        table {
          tr {
            .fc-timegrid-slot .fc-timegrid-slot-label-cushion {
              color: var(--primary-dark-color);
            }
          }
          td {
            border: 2px solid #fff;
            padding: 0;
            &.fc-daygrid-day {
              padding: 4px;
            }
            .fc-timegrid-event-harness {
              .fc-event-title {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 15px;
                line-height: 13px;
                padding: 4px 0 0 0;
                margin: 0 0 0px 0;
                font-family: "TTCommons-Regular";
                font-weight: 400;
                background-color: var(--gray-color) !important;
                color: #fff !important;
                padding: 8px 8px 4px 8px;
                border-radius: 4px;
              }
              .calendarEvent,
              .fc-event-past {
                color: #fff;
                background: #8094ab !important;
              }
            }

            .fc-daygrid-day-frame {
              .fc-daygrid-day-top {
                opacity: 1;

                a {
                  font-family: "TTCommons-Medium";
                  font-size: 22px;
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 24px;
                  text-align: right;
                  padding: 0 !important;
                  color: var(--primary-dark-color);
                  text-decoration: none;
                  width: 36px;
                  height: 36px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 100px;
                  padding: 5px 0 0 0 !important;
                }
              }

              .fc-daygrid-day-events {
                .fc-daygrid-event {
                  margin: 0 !important;
                  background: transparent;
                  border: 0;
                  .selected-event {
                    color: #d82a24 !important;
                    font-family: "TTCommons-Medium";
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 19px;
                  }
                  .fc-event-title {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: 15px;
                    line-height: 13px;
                    padding: 4px 0 0 0;
                    margin: 0 0 0px 0;
                    font-family: "TTCommons-Regular";
                    font-weight: 400;
                    background: var(--link-color) !important;
                    color: #fff !important;
                    padding: 8px 8px 4px 8px;
                    border-radius: 4px;
                    &.calendarEvent {
                      color: #fff;
                      background: #8094ab !important;
                    }
                  }
                  &.fc-event-past {
                    .fc-event-title {
                      background-color: var(--gray-color) !important;
                    }
                  }
                }

                .fc-more-link {
                  color: #607790;
                  font-family: "TTCommons-Medium";
                  font-size: 16px;
                  font-weight: 400;
                  letter-spacing: 0;
                  line-height: 19px;
                  &:hover {
                    text-decoration: none;
                    color: #d82a24;
                  }
                }
              }
            }

            &.fc-day-other {
              .fc-daygrid-day-frame {
                .fc-daygrid-day-top {
                  a {
                    color: #607790;
                  }
                }
              }
            }

            &.fc-day-today {
              background: #f1f4f7 !important;

              .fc-daygrid-day-frame {
                .fc-daygrid-day-top {
                  a {
                    background-color: var(--primary-color);
                    color: #fff !important;
                  }
                }
              }
            }
            &.fc-day-disabled {
              background: var(--icon_background) !important;
            }
            .fc-highlight {
              background: var(--icon_background) !important;
            }
            &.fc-ooo {
              background: rgb(225, 243, 252) !important;
            }
            &.fc-day-future {
              .fc-daygrid-day-events {
                .fc-daygrid-event {
                  .fc-event-title {
                    color: var(--primary-dark-color);
                  }
                }
              }
            }
            .fc-timegrid-col-frame {
              .fc-v-event.cal-event {
                background-color: var(--primary-color);
                margin-bottom: 2px;
                background: transparent;
                border: 0;
              }
              .fc-event-past {
                .fc-event-main {
                  background-color: var(--gray-color) !important;
                  border-radius: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.appointment-card {
  border-radius: 45px;
  background-color: #f1f4f7;
  max-width: 507px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  h3 {
    color: var(--primary-dark-color);
    margin: 0 0 8px 0;
  }

  h4 {
    color: var(--primary-dark-color);
    margin: 0;
  }

  .text-gray {
    color: #607790;
  }
  h5 {
    color: var(--primary-dark-color);
    font-size: 15px;
    line-height: 17px;
  }
  h5.mentor {
    height: 19.2px;
    width: 59.9px;
    letter-spacing: 0;
    text-align: center;

    .link {
      color: #f76155 !important;
      border-bottom-color: #f76155;
      margin-left: 4px;
    }
  }
  .appointment_description {
    max-width: 344px;
    margin: 0 0 5px 0;
  }
  .meeting_location {
    span {
      color: #f76155;
    }
  }
  .appointment-card-preview {
    width: 100% !important;
    min-width: 300px !important;
    max-width: 300px !important;
    margin: 0 auto !important;
    .UserDetails {
      margin-top: 0 !important;
    }
  }
}

.event-popop {
  .MuiPaper-root {
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4) !important;
    width: 100%;
    max-width: 420px;
    padding: 30px;
    .nevent-box {
      .close-btn {
        width: 14px;
        margin-left: auto;
        display: flex;
        height: 14px;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          width: 100%;
        }
      }

      .box-wrap {
        position: relative;
        h3 {
          color: var(--primary-dark-color);
          margin: 8px 90px 8px 0;
          font-size: 18px;
          line-height: 18px;
        }

        h4 {
          color: var(--primary-dark-color);
          margin: 0 0 8px 0;
        }
        .event-actions {
          position: absolute;
          right: 0;
          top: 0;
          img {
            width: 40px;
            height: 40px;
            + img {
              margin-left: 8px;
            }
          }
        }

        .text-gray {
          color: var(--gray-color) !important;
          margin: 0 0 8px 0 !important;
          font-size: 15px;
          line-height: normal;
        }

        h5 {
          color: var(--primary-dark-color);
          margin: 0 0 4px 0;
          .link,
          .pink-text {
            color: #f76155 !important;
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 15px;
            border-bottom-color: #f76155;
            margin-left: 4px;
          }
          &:last-child {
            margin-bottom: 0 !important;
          }
        }
        .fc-popover-body {
          padding: 10px 0 0;
        }
      }
    }
  }
}

.Toastify {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .Toastify__toast-container {
    left: auto;
    transform: none;
    bottom: 47px;
    width: 100%;
    max-width: fit-content;
    padding: 0;

    .Toastify__toast {
      margin: 0;
      border-radius: 7px;
      background-color: var(--primary-color);
      min-height: auto;
      padding: 21px 30px 15px;
      align-items: center;
      transform: none !important;

      .Toastify__toast-body {
        margin: 0;
        padding: 0;

        .d-flex {
          color: #ffffff;
          font-family: "TTCommons-Regular";
          font-size: 16px;
          letter-spacing: 0;
          line-height: 19px;
        }
      }

      .Toastify__close-button {
        opacity: 1;
        line-height: normal;
        position: relative;
        top: -2px;
      }

      .Toastify__progress-bar {
        visibility: hidden;
      }
    }
  }
}

.customscroll-div {
  > div {
    div {
      &:nth-child(2) {
        display: none !important;
      }

      &:nth-child(3) {
        width: 8px !important;
        border: 0;
        border-radius: 4px !important;
        right: 20px !important;

        div {
          background-color: #d1dde5 !important;
          border-radius: 100px !important;
        }
      }
    }
  }
}

.dropdown-wrapper {
  position: relative;

  + .dropdown-wrapper {
    margin: 15px 0 0 0;
  }

  .custom-dropdownul {
    position: absolute;
    width: 100%;
    z-index: 2;
    display: none;
    left: 50%;
    transform: translateX(-50%);

    &.no-option {
      margin: 0 !important;
      padding: 0 !important;
      border: 0 !important;

      li {
        justify-content: center;
      }
    }
  }
  .wide-dropdown {
    width: 250px;
  }

  &.open {
    .custom-dropdownul {
      display: block;
    }

    .custom-dropdowninput {
      span {
        transform: rotate(180deg);
      }
    }
  }

  &.multili {
    .custom-dropdownul {
      ul {
        li {
          padding: 0;
          background: transparent;
          color: #607790;

          + li {
            margin-top: 16px;
          }

          &.active {
            color: var(--primary-dark-color);
          }
        }
      }
    }
  }
}

.custom-dropdowninput {
  .MuiFormControl-root {
    width: 100%;
    margin: 0 0 0px 0;
  }

  > span {
    position: absolute;
    right: 15px;
    top: 12px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .MuiInputBase-formControl {
    .MuiInputBase-input {
      padding-right: 34px !important;
    }
  }
}

.new-color {
  input {
    background-color: #ffffff !important;
  }
  > span {
    background-color: #ffffff;
  }
}

.custom-dropdownul {
  padding: 20px 10px 20px 20px;
  border: 2px solid var(--icon_background);
  border-radius: 10px;
  background-color: #fafbfd;
  box-shadow: var(--box-shadow);
  max-width: 353px;
  margin: 10px auto 0;

  > ul {
    border-bottom: 1px solid #e8eef2;
    padding-bottom: 15px !important;
    margin-bottom: 15px !important;
  }

  > div {
    > div {
      &:last-child {
        right: 0 !important;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-right: 20px;
    li {
      display: flex;
      color: var(--gray-color);
      font-family: "TTCommons-Regular";
      font-size: 17px;
      letter-spacing: 0;
      line-height: 22px;
      align-items: center;
      margin-right: 8px;
      text-align: left;
      padding: 15px 14px 10px;
      border-radius: 100px;

      > div {
        margin: -4px 12px 0 0;
      }

      + li {
        margin: 0px 0px 0 0;
        word-break: break-word;
      }

      &.active {
        background-color: var(--primary-color);
        color: #fff;
      }
    }
  }
}

.dropdown-array-option {
  margin: 5px 0 0 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .MuiChip-root {
    border-radius: 100px;
    background-color: var(--icon_background);
    border: 0;
    height: 40px;
    padding: 8px 15px 10px 15px;
    margin: 5px 5px 5px;
    color: var(--primary-dark-color);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    max-width: 200px;
    font-family: "TTCommons-Regular";

    .MuiChip-label {
      padding: 6px 0 0 0;
      margin: 0 10px 0 0px;
    }

    svg {
      margin: 0px 0 0 0;
      width: 14px;
      height: 14px;
      color: var(--primary-dark-color) !important;
    }
  }

  &__see-more-less {
    .MuiChip-root {
      background-color: #000485 !important;
      color: #ffffff;
      .MuiChip-label {
        padding: 5px 5px 0 5px;
        margin: 0 !important;
        width: 100% !important;
      }
    }
  }
}

.MuiAlert-standardInfo {
  padding: 12px 40px 12px 30px !important;
  background-color: var(--primary-color) !important;
  color: #fff !important;
  position: fixed;
  left: 70px;
  top: 64px;
  z-index: 1050;
  right: 0;
  box-sizing: border-box !important;
  max-width: 100%;
  border-radius: 0 !important;

  .MuiAlert-icon {
    display: none;
  }

  .MuiAlert-message {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
    font-family: "TTCommons-Regular";

    .MuiAlertTitle-root {
      margin: 0 20px 0 0;
      font-size: 22px;
      font-weight: 500;
      font-family: "TTCommons-Medium";
      line-height: 24px;
      padding: 4px 0 0 0;
    }

    button {
      min-width: 117px;
      margin-left: 52px;
    }
  }

  .MuiAlert-action {
    padding-left: 0;
    margin-left: auto;
  }
}

.qa-table {
  tr {
    td {
      &:nth-child(2) {
        min-width: 450px;
        width: 450px;
      }

      &:last-child {
        width: 100px;
      }
    }
  }
}

.post-comments-wrapper {
  margin: 0 auto;
  border-top: 1.5px solid #d1dde5;
  margin-top: 25px;
  padding-top: 25px;

  .link-div {
    max-width: 474px;
    margin: 0 auto 30px;

    .showcomments {
      color: #607790;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 15px;
      border-bottom: 1px solid #607790;
      text-decoration: none;
    }
  }

  .comment-item {
    max-width: 474px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .profileImg {
      width: 33px;
      height: 33px;
      border-radius: 100px;
      overflow: hidden;
      margin: -18px 15px 0 0;

      img {
        width: 100%;
      }
    }

    .comment-content {
      width: calc(100% - 48px);

      h4 {
        color: var(--primary-dark-color);

        span {
          color: #607790;
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 15px;
          margin-left: 8px;
        }
      }

      p {
        margin: 0;
        color: var(--primary-dark-color);
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
      }
    }

    + .comment-item {
      margin-top: 25px;
    }
  }
}

.MuiBackdrop-root {
  background-color: rgba(128, 148, 171, 0.4) !important;
}

.MuiCardHeader-action {
  position: relative;

  button {
    height: 28px;
    width: 28px;
    border: 2px solid #d1dde5;
    border-radius: 31.5px;
    background-color: #ffffff;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: #607790;
    }
  }

  ul.sub-menu {
    margin: 8px 0px 0px;
    list-style: none;
    position: absolute;
    top: 100%;
    right: 2%;
    border-radius: 10px 1px 10px 10px;
    border: 2px solid #d1dde5;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(209, 221, 229, 0.4) 0px 1px 14px 3px;
    width: 200px;
    padding: 0px 20px;
    transition: opacity 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    z-index: 1;
    display: none;

    li {
      a {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        text-align: center;
        text-decoration: none;
        display: block;
        padding: 18px 0px 12.5px;
        color: var(--primary-dark-color);
        font-family: TTCommons-Medium;
        line-height: 21px;
        justify-content: center;

        b {
          font-weight: 500;
        }
      }

      + li {
        border-top: 1px solid #d1dde5;
      }
    }
  }

  &.open {
    ul.sub-menu {
      display: block;
    }
  }
}

.international-students-section {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
  padding: 30px 20px;
  max-width: 325px;

  .box-wrap {
    flex-wrap: wrap;

    h4 {
      margin: 0 0 19px 0;
      color: var(--primary-dark-color);
      font-weight: 500;
    }

    .d-flex {
      justify-content: space-between;
      width: 100%;

      h5 {
        color: #607790;
      }

      a {
        color: #f76155;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 19px;
        font-family: "TTCommons-Regular";
        text-decoration: none;
      }
    }
  }
}

.new-chat-dialogue {
  .MuiDialog-paper {
    max-width: 900px !important;
    width: 100%;
    padding: 50px !important;

    #confirmation-dialog-title {
      color: var(--primary-dark-color);
      width: 100%;
      display: flex;
      font-size: 30px;
      text-align: center;
      align-items: center;
      font-family: "TTCommons-Medium";
      font-weight: 500;
      line-height: 31px;
      justify-content: center;
      margin: 0 0 30px 0 !important;
    }

    .MuiDialogContent-root {
      margin: 0;

      .search-network {
        max-width: 320px;
        margin: 0 auto 25px;

        label {
          margin: 0 0 8px !important;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 15px;
          text-align: center;
        }

        .MuiFormControl-root {
          margin: 0;
        }
      }
    }

    .chat-group {
      padding: 0 15px;
      padding: 0 15px;
      max-width: 650px;
      margin: 0 auto;

      .chat-item {
        border-radius: 10px;
        background-color: rgb(255, 255, 255);
        box-shadow: rgba(209, 221, 229, 0.4) 0px 1px 14px 3px;
        padding: 23px 40px 23px 25px;
        display: flex;
        width: 100%;
        -webkit-box-align: center;
        align-items: center;
        min-height: 103px;

        .img {
          width: 50px;
          height: 50px;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 15px 0 0px;
          overflow: hidden;

          img {
            width: 100%;
          }

          &.no-user-image {
            background: #f1f1f1;

            img {
              width: auto;
            }
          }
        }

        .usreDescription {
          width: calc(100% - 215px);
          text-align: left;
        }

        .ChatStatus {
          margin-left: auto;

          button {
            min-width: auto;
          }
        }

        + .chat-item {
          margin-top: 20px;
        }
      }
    }
  }
}

.feeds-confirmation-dialogue-wrapper {
  .MuiDialog-container {
    .MuiPaper-root {
      padding: 0;
      max-width: 530px;
      > div {
        padding: 40px 30px 40px;
        overflow: auto;
        ::-webkit-scrollbar {
          width: 6px;
        }

        ::-webkit-scrollbar-track {
          border-radius: 5px;
        }

        ::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          height: 10px !important;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: rgba(0, 0, 0, 0.5);
        }
        .MuiDialogTitle-root {
          margin: 0 !important;
        }
      }
      #confirmation-dialog-title {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center !important;
        margin: 0 0 15px 0 !important;
      }
      .text-subheader {
        color: #000c3d;
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        max-width: 382px;
        margin: 0 auto 30px;
      }
      .MuiDialogContent-root {
        padding: 0;
      }
      .MuiDialogActions-root {
        justify-content: center;
        margin-top: 0 !important;
        padding: 0;
        > div {
          width: auto;
          button {
            min-width: 162px;
          }
          + div {
            margin-left: 30px;
          }
        }
      }
    }
  }
  &.delete-school-popup {
    .MuiDialog-container {
      .MuiPaper-root {
        padding: 0;
        #confirmation-dialog-title {
          max-width: 400px;
          margin: 0 auto 20px !important;
          display: block;
          width: 100%;
          line-height: normal;
        }
      }
    }
  }
}

// style-guid-css-over
.main-content {
  min-height: 100vh;
  padding: 90px 40px 110px 100px;

  .nav-sidebar {
    width: 70px;
    background-color: var(--primary-dark-color);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100vh;

    .nav-header {
      display: flex;
      padding: 12px 0 52px 0;
      margin: 0;

      a {
        display: block;
        width: 35px;
        overflow: hidden;
        margin: 0 auto;

        img {
          width: 160px;
        }
      }
    }

    .navmenu {
      display: flex;
      height: calc(100% - 105px);
      position: relative;
      padding-bottom: 0.8rem;

      .left-menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: column;
        position: relative;
        width: 100%;

        > div {
          > div {
            &:last-child {
              right: 0 !important;
              width: 2px !important;

              div {
                background: #0030b5 !important;
              }
            }
          }
        }

        .left-ulWrap {
          height: calc(100% - 140px);

          > div {
            height: 100% !important;
          }
        }

        ul.left-ul {
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            a {
              width: 40px;
              display: flex;
              height: 40px;
              justify-content: center;
              margin: 0 auto;
              line-height: 1.5;
              padding: 0;
              border-radius: 24px;
              text-decoration: none;

              &:hover {
                background-color: #0030b5;
              }

              &.active {
                background-color: #0030b5;
              }

              svg {
                width: 19px;
                fill: #fff;
                height: 19px;
              }
            }

            .left-main-menu {
              display: flex;
              flex-direction: column;
              display: flex;
              background-color: #000c3d;
              width: auto;
              height: auto;

              .icon-wrapper {
                width: 40px;
                display: flex;
                height: 40px;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                line-height: 1.5;
                padding: 0;
                border-radius: 24px;
                text-decoration: none;
              }

              &:hover {
                background-color: #000c3d;

                .icon-wrapper {
                  background-color: #0030b5;
                }
              }

              &.active {
                background-color: #000c3d;

                .icon-wrapper {
                  background-color: #0030b5;
                }
              }

              span {
                padding-top: 4px;
                color: #fff;
                font-family: "brandon_grotesquebold";
                font-size: 10px;
                font-weight: bold;
                letter-spacing: 1.11px;
                line-height: 15px;
                text-align: center;
                word-break: break-word;
              }
            }

            .right-menu {
              display: none;
            }
          }

          > li + li {
            margin-top: 15px;
          }

          &.bottom {
            position: absolute;
            bottom: 0;

            a {
              flex-direction: column;
            }
          }
        }
      }

      .right-menu {
        width: calc(100% - 40px);
        border-left: 1.5px solid #607790;
        padding-left: 10.5px;
        padding-right: 10.5px;
        display: none;

        h3 {
          color: #bfced8;
          font-family: "brandon_grotesquebold";
          font-size: 11px;
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 16px;
          text-align: left;
          text-transform: uppercase;
          margin: 0 0 10px 12px;
        }

        .divider {
          margin-top: 12px;
          border-top: 1.5px solid rgba(96, 119, 144, 0.51);
          padding-top: 18px;
        }

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          text-align: left;

          li {
            a {
              padding: 12px 11px 9px 11px;
              display: block;
              width: 100%;
              color: #ffffff;
              font-family: "TTCommons-Medium";
              font-weight: 500;
              font-size: 16px;
              letter-spacing: 0;
              line-height: 18px;
              border-radius: 3px;
              text-decoration: none;

              &:hover,
              &.active {
                background-color: #0030b5;
              }

              img {
                width: 12px;
                margin-left: auto;
              }
            }

            + li {
              margin: 5px 0 0 0;
            }

            &.submenu {
              ul {
                margin-top: 8px;
                padding: 0 0 0 20px;
                display: none;
              }

              &.open {
                ul {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  &.Sidebar-open {
    padding: 90px 40px 110px 300px;

    .nav-sidebar {
      width: 275px;

      .nav-header {
        a {
          width: auto;
        }
      }

      .left-menu {
        width: 70px;

        ul.left-ul {
          li {
            &.active {
              .right-menu {
                display: block;
                position: absolute;
                top: 0;
                left: 70px;
                border-left: 2px solid rgba(96, 119, 144, 0.2);
                height: 100%;
                padding: 0 11px;
                width: 205px;
                overflow: auto;
                &::-webkit-scrollbar {
                  width: 6px; /* width of the entire scrollbar */
                }

                &::-webkit-scrollbar-track {
                  background: transparent !important; /* color of the tracking area */
                  border-radius: 50px;
                  box-shadow: none;
                }

                &::-webkit-scrollbar-thumb {
                  background-color: #d1dde5 !important; /* color of the scroll thumb */
                  border-radius: 20px; /* roundness of the scroll thumb */
                  border: none;
                  height: 10px !important;
                }

                &::-webkit-scrollbar-corner {
                  border-radius: 50%;
                }

                &::-webkit-scrollbar-thumb:hover {
                  border: 0px;
                }
                ul {
                  li {
                    border-radius: 24px;
                    margin-bottom: 5px;

                    a {
                      width: auto;
                      height: auto;
                      padding: 9px 10px 7px;
                      line-height: 1.5;
                      justify-content: flex-start;
                      border-radius: 24px;

                      &:hover {
                        background-color: #0030b5;
                      }
                    }

                    &.submenu {
                      &.open {
                        a {
                          img {
                            transform: rotate(-180deg);
                            margin-left: auto;
                          }
                        }
                      }
                    }

                    &.SubMenu {
                      ul {
                        padding-left: 1.5rem;
                        display: none;
                      }

                      &.openMenu {
                        > a {
                          background-image: $angle-down-arrow-img;
                          background-size: 12px;
                          background-repeat: no-repeat;
                          background-position: calc(100% - 15px) 12px;
                        }

                        ul {
                          display: block;
                        }
                      }

                      &.closeMenu {
                        > a {
                          background-image: $angle-down-arrow-img;
                          background-size: 12px;
                          background-repeat: no-repeat;
                          background-position: calc(100% - 15px) 12px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .right-menu {
        display: block;
      }
    }

    header.header {
      width: calc(100% - 275px);
      left: 275px;
    }

    .MuiAlert-standardInfo {
      left: 275px;
    }
  }

  header.header {
    background: #fff;
    border-bottom: 2px solid var(--icon_background);
    padding: 8px 40px;
    position: fixed;
    top: 0;
    width: calc(100% - 70px);
    left: 70px;
    z-index: 10;
    height: 60px;
    align-items: center;
    display: flex;
    z-index: 1051;
    @media (max-width: 991px) {
      align-items: flex-start;
    }

    .HeaderLeft {
      display: flex;
      align-items: center;
      width: 40%;
      @media (max-width: 991px) {
        flex-wrap: wrap;
        margin-bottom: 44px;
      }
      &--extra-width {
        width: 100%;
      }

      > div {
        width: auto;
        @media (max-width: 991px) {
          width: 100%;
        }
        .dropdown-wrapper {
          min-width: 400px;
          @media (max-width: 991px) {
            min-width: auto;
          }
          .custom-dropdownul {
            min-width: 400px;
            transform: translateX(0);
            left: 0;
          }
        }
        &.switch-portal-tab {
          @media (max-width: 991px) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: auto;
            bottom: 6px;
          }
        }
      }

      .university-logo {
        margin: 0 16px 0 0;

        img {
          max-height: 44px;
        }
      }

      .dropdown-wrapper {
        .custom-dropdowninput {
          input {
            padding: 0;
            border-radius: 100px !important;
            overflow: hidden;
            padding: 16.15px 20px 12.15px;
            padding-right: 40px !important;
            min-height: 44px;
            text-align: left;
            background-color: var(--icon_background);
            border-color: var(--icon_background);
            font-family: "TTCommons-Medium";
            font-weight: 500;
          }

          > span {
            top: 50%;
            transform: translateY(-50%);
            background-color: transparent;
          }
        }
      }
    }

    .HeaderRight {
      margin: 0 0 0 auto;
      display: flex;
      > div {
        margin: 0 20px;
        @media (max-width: 991px) {
          margin: 0 4px;
        }
      }
      a {
        width: 40px;
        height: 40px;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--icon_background);
        border-radius: 100px;
        margin-right: 0 !important;
        transition: all 0.3s;
        + a {
          margin-left: 20px;
        }

        &.Question-Icon {
          svg {
            fill: var(--primary-dark-color);
          }

          &.active,
          &:hover {
            svg {
              path {
                fill: #fff;
              }
            }
          }
        }

        &.Bell-Icon {
          svg {
            stroke: var(--primary-dark-color);
          }

          &.active,
          &:hover {
            svg {
              path {
                stroke: #fff;
              }
            }
          }
        }

        &.User-Icon {
          svg {
            stroke: var(--primary-dark-color);
          }

          &.active,
          &:hover {
            svg {
              path {
                stroke: #fff;
              }
            }
          }
        }
        .notification-badge {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f76155;
          color: #fff;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          position: absolute;
          font-size: 11px;
          margin: -2px 0px 15px 15px;
          span {
            line-height: 12px;
          }
        }
      }
    }
  }

  &.alert-open {
    padding-top: 160px;
  }
}

.page-header {
  display: flex;
  align-items: center;
  margin: 0 0 16px 0;
  min-height: 40px;
  flex-wrap: wrap;

  h3 {
    color: var(--primary-dark-color);
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    font-family: "TTCommons-Medium";
    margin: 0px 0 0 0;
  }

  h4 {
    flex-basis: 100%;
    color: #607790;
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
  }

  .left {
    display: flex;
    align-items: center;
    padding: 4px 0 0 0;
  }

  .tabs-div {
    display: flex;
    align-items: center;
    margin-left: 40px;

    > div {
      color: var(--primary-dark-color);
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
      font-family: "TTCommons-Medium";
      margin: 0 20px 0 0 !important;
      padding: 0px 0 0 0 !important;
      border-bottom: 2px solid transparent;
      cursor: pointer;

      &.active {
        border-bottom: 2px solid var(--primary-dark-color);
      }
    }
  }

  .import-options {
    display: flex;
    button {
      padding: 0;
      color: var(--primary-dark-color);
      font-size: 16px;
      letter-spacing: 0;
      line-height: 17px;
      font-family: "TTCommons-Medium";
      font-weight: 500;
      background: transparent;
      box-shadow: var(--card-box-shadow);
      background-color: var(--white-color);
      border-radius: 100px;
      padding: 5px 16px 5px 5px;
      display: flex;
      align-items: center;
      transition: all 0.3s;
      > label {
        margin: 0 !important;
      }
      &.Mui-disabled {
        opacity: 0.4;
      }

      img {
        width: 30px;
        vertical-align: middle;
        margin: 0 5px 0 0;
      }

      .text-label {
        margin-top: 3px;
      }

      + button {
        margin-left: 10px;
      }

      &:hover {
        box-shadow: 0 1px 14px 3px #d1dde5;
      }
    }
    .icon-button {
      margin-top: 6px;
      cursor: pointer;
    }
    .btn-without-icon {
      button {
        padding: 12px 22px 8px 22px;
      }
    }
    .custom-secondary-btn {
      button {
        padding: 0;
        color: var(--primary-dark-color);
        font-size: 16px;
        letter-spacing: 0;
        line-height: 18px;
        font-family: "TTCommons-Medium";
        font-weight: 500;
        background: transparent;
        box-shadow: var(--card-box-shadow) !important;
        background-color: var(--white-color) !important;
        border-radius: 100px;
        padding: 12px 22px 8px 22px;
        display: flex;
        align-items: center;
        transition: all 0.3s;
        border-color: transparent;
        min-height: 40px !important;
        transition: all 0.3s;
        &:hover {
          box-shadow: var(--box-shadow) !important ;
        }
      }
    }
  }
  label {
    margin-top: 6px;
    cursor: pointer;
  }
  input {
    display: none;
  }
}

.filter-wrap {
  display: flex;
  align-items: center;
  margin: 0 0 16px 0;
  justify-content: space-between;

  &.disabled {
    .filter-left {
      a {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }

  .filter-left {
    display: flex;
    align-items: center;

    a {
      min-width: 50px;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: var(--card-box-shadow);
      background-color: var(--white-color);
      border-radius: 100px;
      transition: all 0.3s;

      + a {
        margin-left: 10px;
      }

      &.selected {
        background-color: #ffffff;
        box-shadow: 0 1px 14px 3px #d1dde5;
      }
      &:hover,
      &:focus {
        box-shadow: 0 1px 14px 3px #d1dde5;
      }
    }

    .FilterSorting {
      margin: 0 0px 0 10px;

      .FilterList {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 17px;
        margin-right: 10px;
      }
    }
    a.filter-button {
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      border-radius: 50px;
    }
  }

  .filter-right {
    display: flex;
    align-items: center;

    > div {
      .MuiButton-root.btn {
        padding: 16px 16px 12px;
        min-width: 110px;
      }
      + div {
        margin-left: 10px;
      }
    }
    &__manage-topics {
      button {
        color: var(--primary-dark-color);
        box-shadow: var(--card-box-shadow);
        display: flex;
        align-items: center;
        transition: all 0.3s;
        border-radius: 100px;
        background-color: var(--white-color);
        height: 50px;
        @media (max-width: 1024px) {
          height: 36px;
        }
        @media (max-width: 400px) {
          padding: 8px 16px;
        }
        .text-label {
          font-size: 18px;
          line-height: 18px;
          letter-spacing: 0;
          font-family: "TTCommons-Medium";
          font-weight: 500;
          height: 14px;
          @media (max-width: 1024px) {
            font-size: 15px;
            line-height: 15px;
            height: 11px;
          }
        }
        img {
          margin-right: 5px;
          @media (max-width: 400px) {
            display: none;
          }
        }
        &:hover {
          box-shadow: var(--box-shadow);
          background-color: #fff;
        }
        .MuiTouchRipple-root {
          display: none !important;
        }
      }
    }
  }
}

.custom-table {
  margin: 0px -15px 0;

  > .MuiPaper-root {
    border: 0;
    box-shadow: none !important;
    background: transparent;
    padding: 0 15px;

    > table {
      table-layout: fixed;
      border-spacing: 0 10px !important;
      border-collapse: initial;
    }

    table {
      table-layout: fixed;
    }

    .thead-row {
      td {
        padding: 0;
        border: 0;

        table {
          tr {
            td {
              padding: 25px 8px 18px;
              font-size: 11px;
              font-weight: bold;
              letter-spacing: 1.5px;
              line-height: 16px;
              color: #8094ab;
              text-transform: uppercase;
              font-family: "brandon_grotesquebold";
              border: 0;

              &:first-child {
                padding: 25px 8px 18px 25px;
              }

              &:last-child {
                padding-left: 0;
              }

              h5 {
                font-size: 11px;
                font-weight: bold;
                letter-spacing: 1.5px;
                line-height: 16px;
                color: #8094ab;
                text-transform: uppercase;
                font-family: "brandon_grotesquebold";
                margin: 0;
                display: flex;
                align-items: center;
                word-break: break-word;

                svg.custom-table-sort {
                  width: 12px;
                  vertical-align: middle;
                  margin: 0px 0 0 6px;
                  height: 12px;
                  fill: #8094ab;
                  width: 24px;
                  cursor: pointer;
                  margin-left: 0;
                  opacity: 0;
                }

                &:hover {
                  color: var(--primary-dark-color);

                  svg.custom-table-sort {
                    fill: var(--primary-color);
                    opacity: 1;
                  }
                }

                &.active {
                  color: var(--primary-dark-color);

                  svg.custom-table-sort {
                    fill: var(--primary-color);
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
        &:last-child {
          width: 170px;
        }
        table tr td.only-delete:last-child {
          width: 70px;
        }
      }

      .checkbox-all-cell {
        width: 70px;
        padding: 25px 8px 18px 10px !important;
      }

      .button-action-header {
        width: 55px !important;
      }
    }
    .button-action-row {
      opacity: 0;
      width: 55px !important;

      .MuiButton-root {
        min-width: 120px;
      }
    }

    .ambassador-status {
      .text-grey {
        color: #8094ab;
      }

      svg {
        width: 14px !important;
        margin: 0px 0 0 8px !important;
        height: 14px !important;
      }
    }

    .tbody-row {
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);

      .main-text {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
      }

      td {
        padding: 0;
        border: 0;

        table {
          tr {
            td {
              padding: 18px 9px 16px;
              color: var(--primary-dark-color);
              font-size: 16px;
              letter-spacing: 0;
              line-height: 17px;
              font-family: "TTCommons-Regular";
              border: 0;
              min-height: 74px;

              .MuiCheckbox-root {
                padding: 0;

                svg {
                  width: 24px;
                  height: 24px;
                  fill: #8094ab;
                  margin: 0;
                }

                &.Mui-checked {
                  svg {
                    fill: var(--primary-color);
                  }
                }
              }

              svg {
                width: 12px;
                vertical-align: middle;
                margin: -4px 0 0 8px;
                height: 12px;
              }

              &:last-child {
                padding-right: 15px;
                padding-left: 0;
                width: 170px;
              }
            }
            td.only-delete {
              width: 70px;
            }
            .checkbox-cell {
              width: 70px;
            }

            .table-actions {
              cursor: auto;
            }

            .table-cell-manage {
              display: flex;
              align-items: center;
              justify-content: space-between;
              &__button {
                padding-left: 10px;
                opacity: 0;
                button {
                  min-height: 40px !important;
                  height: 40px !important;
                  min-width: 95px !important;
                }
              }
            }

            &:hover {
              .table-actions,
              .button-action-row,
              .table-cell-manage__button {
                opacity: 1;
                transition: all 0.3s;
              }
            }
          }
        }

        .collapse-table {
          width: 100%;
          margin: 0 auto;
          text-align: left;

          tr {
            td {
              padding: 18px 10px 25px;
              border: 0;
              border-top: 1.5px solid #e8eef2;
              text-align: left !important;
              width: auto !important;

              &:last-child {
                text-align: center;
                padding-left: 0;
                padding-right: 0;
                border-top: 0;
                width: 42px !important;
              }

              h6 {
                color: #8094ab;
                font-family: "brandon_grotesquebold";
                font-size: 11px;
                font-weight: bold;
                letter-spacing: 1.5px;
                line-height: 16px;
                text-transform: uppercase;
                margin: 0 0 3px 0;
              }

              h5 {
                color: var(--primary-dark-color);
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
                font-family: "TTCommons-Regular";
                margin: 0;
              }
            }

            .export-td {
              width: 70px !important;
              text-align: center;
              padding-left: 20px;
              padding-right: 0;
              border-top: 0;
            }
          }
        }
      }
    }
  }

  &.country-insight {
    .thead-row {
      td {
        text-align: center;
        width: 96px;

        h5 {
          justify-content: center;
          min-width: auto !important;
        }

        // Removing selective actions as hotfix - TS
        &:nth-child(1) {
          text-align: left;
          width: 96px;

          h5 {
            justify-content: left;
          }
        }
      }
    }

    .tbody-row {
      td {
        text-align: center;
        width: 96px;

        &:nth-child(1) {
          text-align: left;
          width: 96px;
        }
      }
    }
  }
}

.table-without-checkbox {
  > .MuiPaper-root {
    .tbody-row {
      td {
        table {
          tr {
            td:first-child {
              padding: 18px 9px 16px 30px;
            }
          }
        }
        .collapse-table {
          tr {
            td {
              &:first-child {
                padding-left: 30px;
              }
            }
          }
        }
      }
    }
  }
}

.tbody-row {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);

  > td {
    border-radius: 10px;
  }

  td {
    padding: 0;
    border: 0;

    table {
      tr {
        td {
          padding: 18px 9px 16px;
          color: var(--primary-dark-color);
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          font-family: "TTCommons-Regular";
          border: 0;
          white-space: normal;
          word-break: break-word;

          .MuiCheckbox-root {
            padding: 0;

            svg {
              width: 24px;
              height: 24px;
              fill: #8094ab;
              margin: 0;
            }

            &.Mui-checked {
              svg {
                fill: var(--primary-color);
              }
            }
          }

          svg {
            width: 12px;
            vertical-align: middle;
            margin: -4px 0 0 8px;
            height: 12px;
          }
        }
      }
    }

    .collapse-table {
      width: 100%;
      margin: 0 auto;
      text-align: left;

      tr {
        td {
          padding: 18px 25px 18px;
          border: 0;
          border-top: 1.5px solid #e8eef2;
          text-align: left !important;
          width: auto !important;

          h6 {
            color: #8094ab;
            font-family: "brandon_grotesquebold";
            font-size: 11px;
            font-weight: bold;
            letter-spacing: 1.5px;
            line-height: 16px;
            text-transform: uppercase;
            margin: 0 0 3px 0;
          }

          h5 {
            color: var(--primary-dark-color);
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            font-family: "TTCommons-Regular";
            margin: 0;
          }
        }

        .export-td {
          width: 70px !important;
          text-align: center;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

.custom-checkbox {
  position: relative;
  width: 20px;
  height: 25px;
  margin: 0 auto;

  input[type="checkbox"] {
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 1;
    left: 0;
    opacity: 0;

    &:checked {
      + span.checkmark {
        img:nth-child(2) {
          display: block;
        }
      }
    }
  }

  span.checkmark {
    height: 25px;
    width: 20px;
    position: relative;
    overflow: hidden;
    display: block;
    img {
      width: 100%;
      height: 100%;
    }
    img:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
    }
  }
}

.drag-wrapper {
  + .drag-wrapper {
    margin: 15px 0 0 0;
  }
}

.drag-content-wrap {
  .bg-wrap {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: var(--card-box-shadow);
    transition: all 0.3s;
    &:hover {
      box-shadow: var(--box-shadow);
    }

    .drag-main-row {
      height: 74px;

      .drag-item {
        height: 74px;
        display: flex;
        padding: 16px 30px 16px;

        .left {
          display: flex;
          align-items: center;
          flex: 1;
          width: calc(100% - 91px);
          > .row {
            margin: 0;
            padding: 0;
          }
          .img-wrap {
            margin: 0 15px 0 0;
            min-width: 44px;
            height: 44px;
            width: 44px;
            border-radius: 100px;
            background-color: var(--icon_background);
            display: flex;
            align-items: center;
            padding: 4px;
            overflow: hidden;
          }
          .campus-map-image {
            margin: 0 15px 0 0;
            height: 44px;
            width: 44px;
            border-radius: 100%;
            background-color: var(--icon_background);
            position: relative;
            padding: 0 0 0 0 !important;
            img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
              object-fit: contain;
            }
          }
        }

        .drag-icon,
        .no-drag-icon {
          padding: 0;
          display: flex;
          align-items: center;
          cursor: move;
          + .row {
            width: calc(100% - 42px);
            margin: 0;
            .row {
              padding: 0;
            }
            .ellipsis-div-wrappper {
              width: 100%;
              flex-wrap: nowrap;
              align-items: center;
              h3 {
                max-width: 100%;
              }
              a {
                white-space: nowrap;
                font-family: "TTCommons-Regular";
                font-size: 16px;
              }
              h6 {
                margin-left: auto;
                color: var(--gray-color);
                font-family: "TTCommons-Regular";
                font-size: 18px;
                letter-spacing: 0;
                line-height: 14px;
                padding: 6px 0 0 30px;
                margin-right: 20px;
                white-space: nowrap;
                opacity: 0;
                visibility: hidden;
              }
            }
          }
        }

        .drag-icon {
          margin: 0 30px 0 0;
        }

        .card-icon {
          margin: 0 20px 0 0;
        }

        h3 {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 16px;
          margin: 0 15px 0 0;
          padding: 6px 0 0 0;
          max-width: 380px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .resource-title {
          margin: 0 5px 0 0;
        }

        a {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular";
          text-decoration: none;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 19px;
          padding: 6px 0 0 0;

          img {
            margin: 0 0 0 4.2px;
            height: 7px;
            width: 15px;
          }
        }

        .resource-anchor {
          font-size: 16px !important;
          line-height: 18px !important;
        }

        .actions-div {
          margin-left: auto;
          display: flex;
          align-items: center;
          opacity: 0;
          transition: all 0.3s;
          min-width: 112px;
          gap: 10px;

          a {
            cursor: pointer;
            min-width: 40px;
            width: 40px;
            height: 40px;
            padding: 0;

            img {
              width: 100%;
              margin: 0;
              height: 100%;
            }
          }
        }

        &.disabled {
          .drag-icon,
          .img-wrap {
            opacity: 0.8;
          }

          h6,
          h3,
          a {
            color: #8094ab !important;
          }

          .actions-div {
            opacity: 0.8;
          }
        }

        &:hover {
          .actions-div {
            opacity: 1;
            transition: all 0.3s;
          }
          .ellipsis-div-wrappper {
            h6 {
              opacity: 1 !important;
              visibility: visible !important;
            }
          }
        }
      }
    }

    .drag-collapse-row {
      padding: 0 30px 0 70px;

      .display-block {
        display: block;
      }

      > div {
        .collapse-item {
          padding: 0 0 0 22px;

          + .collapse-item {
            margin: 18px 0 0 0;
            padding-top: 18px;
            border-top: 2px solid #f1f4f7;
          }

          &:last-child {
            padding-bottom: 35px;
          }
        }
      }

      .collapse-item {
        display: flex;
        align-items: center;

        .drag-icon {
          margin: 0 30px 0 0;
          cursor: move;
        }

        .img-wrap {
          width: 143px;
          height: 82px;
          margin: 0 22px 0 0;
          position: relative;
          cursor: pointer;
          min-width: 143px;
          border: 1px solid #e8eef2;
          border-radius: 3px;

          img {
            width: 100% !important;
            height: 100%;
            object-fit: contain;
            object-position: center;
            cursor: pointer;
          }
        }

        .resources-img-wrap {
          width: 143px;
          height: 82px;
          margin: 0 22px 0 0;
          position: relative;
          cursor: pointer;
          min-width: 143px;
          border: 1px solid #e8eef2;
          border-radius: 3px;

          img {
            width: 100% !important;
            height: 100%;
            object-fit: contain;
            object-position: center;
            cursor: pointer;

            &.play-icon {
              width: 60px !important;
              height: 60px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              object-fit: initial;
              margin: 1px 0 0 0 !important;
            }
          }

          &.normal-image {
            width: 143px !important;
            height: 82px !important;
            min-width: 143px;
            background-color: rgba(241, 244, 247, 0.5);
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            img.fix-height {
              max-height: 52px;
            }
          }

          &.video-image {
            display: flex;

            img:first-child {
              height: 100% !important;
              object-fit: cover !important;
            }
          }
        }

        .collapse-content {
          max-width: 65%;
        }

        .collapse-content-tag {
          color: var(--field-label-color);
          font-family: "brandon_grotesquebold";
          font-size: 11px;
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 16px;
          margin: 0 0 6px 0;
          text-transform: uppercase;
        }

        .collapse-content-title-first {
          display: flex;
          flex-direction: column-reverse;
        }

        h3 {
          color: #8094ab;
          font-size: 11px;
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 16px;
          font-family: "brandon_grotesquebold";
          margin: 0 0 9px 0;
        }

        h4 {
          color: var(--primary-dark-color);
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          font-family: "TTCommons-Medium";
          margin: 0 0 4px 0;
        }

        p {
          color: #607790;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 18px;
          margin: 0;
          font-family: "TTCommons-Regular";
          margin: 0 0 4px 0;

          + p {
            margin: 4px 0 0 0;
          }
        }

        .actions-div {
          margin-left: auto;
          display: flex;
          align-items: center;
          transition: all 0.3s;
          min-width: 112px;
          gap: 10px;
          justify-content: flex-end;

          a {
            min-width: 40px;
          }
        }
      }
    }

    + .bg-wrap {
      margin-top: 10px;
    }
  }
}

.homefeed {
  .homefeed-content {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: var(--card-box-shadow);
    transition: all 0.3s;
    &:hover {
      box-shadow: var(--box-shadow);
    }

    .homefeed-item {
      display: flex;
      padding: 20px;
      align-items: center;
      cursor: pointer;
      .img-wrap {
        width: 100%;
        max-width: 190px;
        height: 104px;
        margin: 0 30px 0 20px;
        position: relative;
        cursor: pointer;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100% !important;
          max-height: 120px;
          object-fit: contain;
          object-position: center;
          cursor: pointer;

          &.play-icon {
            width: 50px !important;
            height: 50px;
            position: absolute;
            cursor: pointer;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -49%);
            object-fit: initial;
          }
        }
        > div {
          height: 100%;
        }
        &.video-image {
          video {
            width: 100% !important;
            height: 100% !important;
            vertical-align: middle;
          }
        }
        &.normal-image {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: auto;
            width: auto !important;
          }
        }
      }
      .multiple-attachments {
        border-radius: initial;
        height: auto;
        overflow: initial;
        margin-right: 35px;
        .slick-list {
          border-radius: 10px;
          overflow: hidden;
        }
        .image-preview-div {
          height: 104px;
          max-width: 190px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: auto !important;
            width: auto !important;
            max-width: 100% !important;
            max-height: 104px;
            object-fit: initial;
          }
        }
        .video-preview-div {
          height: 104px;
          max-width: 190px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .slide-remove-video {
            border-radius: 10px;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 104px;
          }
        }
        .pdf-preview-div {
          width: 100%;
          max-width: 190px;
          height: 104px;
          position: relative;
          cursor: pointer;
          border-radius: 10px;
          overflow: hidden;
          img {
            height: -webkit-fill-available;
            max-height: 120px;
            object-fit: contain;
            object-position: center;
            cursor: pointer;
          }
        }
      }
      .text-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        align-self: flex-start;
        .feedposted-details {
          display: flex;
          align-items: center;
          margin: 0 0 8px 0;
          flex-wrap: wrap;
          h5 {
            border-radius: 12.5px;
            background-color: var(--icon_background);
            padding: 3px 8px 0;
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            font-size: 16px;
            letter-spacing: 0;
            line-height: 18px;
            display: flex;
            align-items: center;
            min-height: 25px;
            min-width: 62px;
            justify-content: center;
            margin: 0 15px 0 0;
          }
          p {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 14px;
            margin: 0 15px 0 0;
            padding: 4px 0 0 0;
          }
          h6 {
            color: var(--gray-color);
            font-family: "TTCommons-Regular";
            font-size: 16px;
            letter-spacing: 0;
            line-height: 14px;
            margin: 0 15px 0 0;
            padding: 4px 0 0 0;
            min-height: auto;
          }
        }

        .feedposted-user {
          display: flex;
          align-items: center;
          margin: 0 0 8px 0;
          flex-wrap: wrap;
          h6 {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            padding: 4px 0 0 0;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 14px;
            margin: 0 15px 0 0;
          }
          h5 {
            color: var(--gray-color);
            font-family: "TTCommons-Regular";
            padding: 4px 0 0 0;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 14px;
            margin: 0 15px 0 0;
          }

          .presenter {
            color: var(--primary-dark-color);
          }
        }

        h3 {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 16px;
          margin: 0 0 8px 0;
          padding: 4px 0 0 0;
          min-height: 20px;
        }
        .announcement-description {
          white-space: break-spaces;
          margin-bottom: 0;
          line-height: 20px;
          min-height: 70px;
        }

        p {
          margin: 0 0 8px 0;
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;

          &.tag {
            margin-top: -4px;
          }
        }

        h6 {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 19px;
          min-height: 44px;
          white-space: break-spaces;
        }

        .tagline-text {
          color: #8094ab;
          font-size: 11px;
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 16px;
          font-family: "brandon_grotesquebold";
          text-transform: uppercase;
          margin: 0 0 7px 0;
        }

        .title-text {
          color: var(--primary-dark-color);
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          margin: 0 0 7px 0;
        }

        .description-text {
          color: var(--primary-dark-color);
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          margin: 0 0 9px 0;
        }

        .time-text {
          color: var(--primary-dark-color) !important;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          margin: 0 0 6px 0;
        }

        .by-author {
          font-family: "TTCommons-Regular";
          font-size: 15px !important;
          letter-spacing: 0 !important;
          line-height: 17px;
          margin: 0 !important;
          text-transform: initial;
          font-weight: normal;
        }

        .webinar-button {
          .rounded-btn {
            min-width: 110px !important;
            padding: 3px 15px 0 15px !important;
          }
        }
      }

      &:hover {
        .actions-div {
          opacity: 1;
          transition: all 0.3s;
        }
      }

      .actions-div {
        margin-left: auto;
        display: flex;
        align-items: center;
        opacity: 0;
        transition: all 0.3s;
        justify-content: flex-end;
        span {
          display: flex;
          align-items: center;
          width: 40px;
          height: 40px;
          justify-content: center;
          img {
            width: 100%;
          }
          + span {
            margin-left: 10px;
          }
        }

        a {
          cursor: pointer;
          min-width: 40px;
          text-align: center;
          width: 40px;
          height: 40px;
          img {
            width: 100%;
            height: 100%;
          }
          + a {
            margin-left: 10px;
          }
        }
      }

      .unpin-wrapper {
        margin-left: 10px;
      }
    }
  }

  + .homefeed {
    margin: 15px 0 0 0;
  }
  &.webinar-feeds-wrapper {
    .homefeed-content {
      border-radius: 20px;
      .homefeed-item {
        align-items: center;
        .img-wrapper {
          max-width: 205px;
          height: 104px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background-color: #d8d8d8;
          img {
            max-width: 100%;
            max-height: 104px;
            height: auto;
          }
        }
        .text-content {
          padding-right: 30px;
          .feedposted-details {
            h5 {
              margin-right: 10px;
            }
            p {
              font-size: 18px;
            }
          }
          p {
            font-size: 16px;
            letter-spacing: 0;
            line-height: 18px;
          }
        }
      }
    }
  }

  &.socialmedia-list {
    .homefeed-content {
      .homefeed-item {
        padding: 16px 30px 16px;
        align-items: center;
        > span {
          margin-right: 15px;
        }
        .text-content {
          h5 {
            color: var(--field-label-color);
            font-family: "brandon_grotesquebold";
            font-size: 11px;
            font-weight: bold;
            letter-spacing: 1.5px;
            line-height: 16px;
            margin: 0 0 6px 0;
            text-transform: uppercase;
          }
          h3 {
            color: var(--primary-dark-color);
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            font-family: "TTCommons-Medium";
            padding: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// new engagefeed draggables
.engagefeed-container {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
}
.engagefeed {
  width: calc(25% - 18px);
  @media (max-width: 1300px) {
    width: calc(33.33% - 18px);
  }
  @media (max-width: 767px) {
    width: calc(50% - 18px);
  }
  @media (max-width: 480px) {
    width: 100%;
  }
  .engagefeed-content {
    padding: 20px;
    background-color: #ffffff;
    box-shadow: var(--card-box-shadow);
    transition: all 0.3s;
    width: 100%;
    border-radius: 10px;
    &:hover {
      box-shadow: var(--box-shadow);
    }
    .engagefeed-item {
      .image-wrapper {
        min-width: auto;
        min-height: auto;
        max-width: initial;
        max-height: initial;
        margin: 0 0 10px 0;
        background-color: #fafbfd;
        border-radius: 10px;
        min-height: 200px;
        img {
          max-width: 100%;
          max-height: initial;
          object-fit: initial;
          border-radius: 0;
          max-height: 200px;
        }
      }
      .video-wrapper {
        min-width: auto;
        min-height: auto;
        max-width: initial;
        max-height: initial;
        justify-content: center;
        margin: 0 0 10px 0;
        min-height: 200px;
        background-color: #fafbfd;
        border-radius: 10px;
        position: relative;
        img {
          max-width: 100%;
          max-height: 200px;
        }
        video {
          max-width: 100%;
          max-height: 200px;
          border-radius: 0;
        }
        .play-icon {
          position: absolute;
          max-width: 80px !important;
        }
      }
      .pdf-wrapper,
      .xml-wrapper,
      .html-wrapper,
      .ppt-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 200px;
        margin: 0 0 10px 0;
        background-color: #fafbfd;
        border-radius: 10px;
        img {
          max-height: 200px;
          max-width: 100%;
        }
      }
      .xml-wrapper,
      .html-wrapper {
        img {
          height: 100px;
        }
      }
      .text-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        h5 {
          margin: 0;
          font-size: 15px;
          font-family: "TTCommons-Regular";
          font-weight: 400;
          line-height: 17px;
          text-transform: uppercase;
        }
        h3 {
          color: var(--primary-dark-color);
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 17px;
          font-family: "TTCommons-Regular";
          padding: 0;
          margin-bottom: 0;
        }
      }
      .actions-div {
        display: flex;
        gap: 10px;
        justify-content: left;
        align-items: center;
        @media (hover: hover) {
          height: 0;
          opacity: 0;
          overflow: hidden;
          transform: scaleY(0);
          transform-origin: top;
          transition: transform 0.3s ease, height 0.3s ease, opacity 0.3s ease;
        }
        span {
          &:first-child {
            margin: 0 8px 0 0;
          }
        }
        a {
          width: 40px;
          height: 40px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.engagefeed:hover {
  .engagefeed-content > .engagefeed-item > .actions-div {
    height: auto;
    opacity: 1;
    transform: scaleY(1);
    margin: 10px 0 0 0;
  }
}

.engagefeed-preview-wrapper {
  .cancel-icon {
    top: 15px;
    right: 15px;
  }
  #confirmation-dialog-title {
    display: none;
  }
  .MuiDialogContent-root {
    padding: 15px 30px !important;
  }
  .MuiDialogActions-root {
    margin-top: 0 !important;
    padding: 0 !important;
  }
  .engagefeed-dialog-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .kits-image,
    video {
      max-height: 60dvh;
      overflow: hidden;
      height: inherit;
    }
  }
}

// home page CSS
.welcome-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin: 0 98px 0 138px;
  margin: 0 4px 0 16px;
  h2 {
    font-size: 26px;
    color: var(--primary-dark-color);
    margin: 0 0 40px 0;
  }

  .lookingFor {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin: 0 0 20px 0;
      color: var(--primary-dark-color);
    }

    ul {
      display: flex;
      align-items: center;
      width: 860px;
      padding: 0;
      margin: 0;
      list-style: none;
      color: #607790;
      font-size: 17px;
      letter-spacing: 0;
      line-height: 19px;
      gap: 15px 20px;

      li {
        cursor: pointer;
      }

      &.topics {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          text-align: center;
          padding: 20px 10px;
          background-color: #ffffff;
          border-radius: 10px;
          box-shadow: var(--card-box-shadow);
          width: calc(33.33% - 20px);
          min-height: 140px;
          transition: all 0.3s;
          max-width: 240px;
          position: relative;
          .notification-dot {
            position: absolute;
            top: -5px;
            right: -5px;
            height: 25px;
            min-width: 25px;
            padding: 3px 4px;
            border-radius: 15px;
            background-color: var(--secondary-color);
            color: #ffff;
            font-family: "TTCommons-Medium";
            font-size: 12px;
          }
          &:hover {
            box-shadow: var(--box-shadow);
          }
          img {
            width: 50px;
            height: 50px;
            margin-bottom: 10px;
          }
          h5 {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            min-height: 40px;
          }
        }
      }

      &.admissions {
        width: 640px !important;
      }

      &.missed-topics {
        color: var(--primary-dark-color);

        li {
          margin: 0 0 18px 0;
        }
      }
    }

    .copy-icon {
      width: 25px;
      height: 25px;
    }
  }

  .platform-div {
    + .platform-div {
      margin: 30px 0 0 0;
    }

    h3 {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0 0 30px 0;
    }

    .bg-div {
      border-radius: 3px;
      background-color: #e5eaf7;
      text-align: center;
      padding: 30px 30px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      min-height: 171px;

      h1 {
        width: 100%;
        color: var(--primary-dark-color);
      }

      p {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
        margin: 0;
      }
    }

    &.n-plateform-div {
      .row {
        .col-md-2 {
          flex: 0 0 20%;
          max-width: 20%;
        }
      }
    }
  }
}

.page-title {
  color: var(--primary-dark-color);
  margin: 0 0 30px 0;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
}

div .page-subTitle {
  color: var(--primary-dark-color);
  margin-bottom: 16px !important;
}

div .last-step-subTitle {
  color: var(--primary-dark-color);
  margin-bottom: 40px !important;
}

div .page-label {
  color: var(--primary-dark-color);
  font-family: "TTCommons-Medium";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  padding: 8px 0 0 0;
}

.profile-content {
  .page-title {
    margin-bottom: 40px;
  }

  .profile-form {
    max-width: 950px;
    margin: 0 auto;
    margin-bottom: 50px;

    h3 {
      color: var(--primary-dark-color);
      margin: 0 0 30px 0;
    }

    .row {
      margin: 0 -50px;

      .col-md-6 {
        padding: 0 50px;

        + .col-md-6 {
          border-left: 1.5px solid #d1dde5;
        }
      }
    }

    .form-group {
      margin-bottom: 0;

      > div {
        width: 100%;

        .MuiFormControl-root {
          width: 100%;
        }
      }

      .fileupload-custom {
        .dropzone {
          width: 111px;
          height: 111px;
          border-radius: 100px;
          margin: 0 auto 10px;
          min-height: 111px;
          .upload-img {
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }

      + .form-group {
        margin: 15px 0 0 0;
      }
    }
  }
}
.change-password-page-content {
  margin-top: -70px;
  .page-title {
    margin-bottom: 30px;
  }
}
.profile-upload {
  text-align: center;
}

.nlink-primary {
  margin: 10px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: #000c3d;
    text-decoration: none;
    cursor: pointer;
    color: var(--primary-dark-color);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    font-family: "TTCommons-Regular";
    border-bottom: 2px solid var(--primary-dark-color);
    display: inline-block;
    text-decoration: none;
  }
}

.close-account {
  margin: 15px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    cursor: pointer;
    color: var(--primary-dark-color);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    font-family: "TTCommons-Regular";
    border: 0;
    border-bottom: 2px solid var(--primary-dark-color);
    display: inline-block;
    text-decoration: none;
    background: none;
    padding: 0;
  }
}

//added by anand for drag and drop
.collapse-item {
  display: flex;
  align-items: center;

  .drag-icon {
    margin: 0 30px 0 0;
  }

  .img-wrap {
    width: 100%;
    max-width: 145px;
    margin: 0 22px 0 0;

    img {
      width: 100%;
    }
  }

  .resources-img-wrap {
    width: 153.63px;
    height: 86.43px;
    margin: 0 15px 0 0;
    position: relative;
    cursor: pointer;

    img {
      width: 100% !important;
      height: 100%;
      object-fit: contain;
      object-position: center;

      &.play-icon {
        width: 60px !important;
        height: 60px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        object-fit: initial;
      }
    }

    &.normal-image {
      width: 153.63px !important;
      height: 86.43px !important;
    }

    &.video-image {
      display: flex;

      img:first-child {
        height: 100% !important;
        object-fit: cover !important;
      }
    }
    &__pdf {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      &__preview {
        max-height: 90% !important;
        max-width: 90% !important;
      }
      &__school-logo {
        position: absolute;
        bottom: 2px;
        right: 2px;
        height: 30px;
        width: 50px;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  h3 {
    color: #8094ab;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1.5px;
    line-height: 16px;
    font-family: "brandon_grotesquebold";
    margin: 0 0 9px 0;
  }

  h4 {
    color: var(--primary-dark-color);
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    font-family: "TTCommons-Medium";
    margin: 0 0 10px 0;
  }

  p {
    color: #607790;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    margin: 0;
    font-family: "TTCommons-Regular";

    + p {
      margin: 10px 0 0 0;
    }
  }

  &:hover {
    .actions-div {
      opacity: 1;
      transition: all 0.3s;
    }
  }

  .actions-div {
    padding: 0 10px 0 22px;
    display: flex;
    align-items: center;
    margin-left: auto;
    opacity: 0;
    transition: all 0.3s;
  }
}

.wizzard-content {
  position: relative;

  .back-div {
    color: #607790;
    font-family: "TTCommons-Regular";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    position: absolute;
    left: 0;
    cursor: pointer;

    button {
      padding: 14px 12px 8px 12px;
      min-height: 40px;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      min-width: 164px;
    }

    img {
      margin: -5px 8px 0 0;
    }
  }

  .step-title {
    color: var(--primary-dark-color);
  }

  .wizzard-form {
    min-height: calc(100vh - 310px);
    padding-bottom: 50px;

    .max-width {
      max-width: 472px;
      margin: 0 auto;
      min-width: 235px;

      &--large {
        max-width: 70%;
      }
    }

    .MuiFormControl-root {
      .MuiInputBase-root:not(.MuiInputBase-adornedEnd) {
        padding: 0 !important;
      }
    }

    .form-group {
      > div {
        width: 100%;

        .MuiFormControl-root {
          width: 100%;
        }
      }
    }

    .file-upload-sample-note {
      text-align: center;
      margin-top: 9px;
      color: var(--primary-dark-color);
      font-size: 17px;
      letter-spacing: 0;
      line-height: 18px;
      font-family: "TTCommons-Regular";
      > a {
        color: #0030b5;
        text-decoration: none;
        border-bottom: 1px solid #0030b5;
      }
    }

    .single-select-content {
      > div {
        max-width: 188px;
      }
    }

    &.step-two {
      .single-select-content {
        > div {
          max-width: 260px;
        }
      }
    }
  }

  .wizard-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 10px;
    border-top: 1px solid #e8eef2;
    position: relative;
    overflow-x: hidden;
    &:before {
      content: "";
      height: 1px;
      background: #e8eef2;
      width: 50%;
      position: absolute;
      top: -1px;
      left: -50%;
      z-index: -1;
    }

    &:after {
      content: "";
      height: 1px;
      background: #e8eef2;
      width: 50%;
      position: absolute;
      top: -1px;
      right: -50%;
      z-index: -1;
    }

    button {
      min-width: 162px;
      margin: 10px 15px;
    }
  }
  .footer-wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-right: 300px;
    background-color: #fff;
    .wizard-button {
      padding: 4px 0 4px;
    }
  }
  .drawerOpen {
    padding-right: 150px;
  }
}

.full-width-control {
  max-width: 353px;
  margin: 0 auto;
  min-width: 235px;
}

.loading-text {
  display: block;
  margin: 0 auto 15px;
  text-align: center;
  color: var(--primary-dark-color);
  font-family: "TTCommons-Medium";
  font-size: 18px;
  font-weight: normal;
  line-height: 18px;
}

.Sidebar-open {
  .loader {
    margin-left: 120px !important;
    @media (min-width: 1280px) {
      margin-left: 135px !important;
    }
  }
  .loader.position-absolute {
    margin-left: 0 !important;
  }
  .infinite-loading-loader {
    .loader {
      margin-left: 0 !important;
    }
  }
}

@media (max-width: 1400px) {
  .MuiAlert-standardInfo {
    .MuiAlert-message {
      font-size: 15px;

      .MuiAlertTitle-root {
        line-height: 18px;
      }

      button {
        margin-left: 40px;
      }
    }
  }
}

.mgTopEvent {
  margin-top: 100px !important;
}

.naction-btn {
  background-color: transparent !important;
  padding: 0;
  border: 0;
}

.MuiPagination-ul {
  justify-content: flex-end;
  margin: 15px 0 15px 0 !important;

  li {
    margin: 0 5px;

    button {
      margin: 0;
      border-radius: 0;
      border: 2px solid rgba(96, 119, 144, 0.1);
      opacity: 1 !important;
      border-radius: 4px;
      color: var(--primary-dark-color);
      font-family: "brandon_grotesquebold";

      &.Mui-selected,
      &:hover {
        background: #0030b5 !important;
        color: #fff !important;
        border-color: #0030b5 !important;
      }

      &.Mui-disabled {
        opacity: 0.4 !important;
      }
    }
  }

  .MuiPaginationItem-ellipsis {
    color: #0030b5;
    font-size: 24px;
    margin: -15px 0 0 0;
  }
}

.table-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .pagination-dropdown {
    display: flex;
    align-items: center;

    span {
      color: #607790;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      margin-right: 10px;
    }

    .dropdown-wrapper {
      max-width: 120px;

      .MuiOutlinedInput-input {
        min-height: 40px;
        padding: 15px 30px 10px 15px;
        text-align: left;
        border-radius: 4px !important;
      }

      .custom-dropdowninput {
        span {
          right: 0px;
          top: 5px;
        }

        .MuiFormControl-root {
          margin: 0;
        }
      }

      .custom-dropdownul {
        padding: 8px;
        ul {
          padding-right: 0;
          li {
            &:first-child {
              display: none;
            }
          }
        }
      }
    }
  }

  .MuiPagination-root {
    margin: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.profile-action {
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    margin: 0 15px;
  }
}

.table-actions {
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transition: all 0.3s;
  a {
    width: 40px;
    height: 40px;
    display: inline-block;
    min-width: 40px;
    img {
      width: 100%;
      height: 100%;
    }
    + a {
      margin-left: 10px;
    }
  }

  &.three-actions {
    min-width: 100px;
  }
}

.checklist-wrapper {
  .drag-content-wrap {
    .bg-wrap {
      .drag-collapse-row {
        border-top: 2px solid #e8eef2;
        margin: 0 30px 0px 70px;
        padding: 0;
        padding-top: 25px;
        padding-bottom: 25px;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          .main-cat {
            display: flex;
            align-items: center;

            span {
              color: var(--primary-dark-color);
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 19px;
            }

            .actions {
              margin-left: auto;
            }
          }

          .sub-cat {
            margin-left: 40px;
            margin-top: 0;

            li {
              display: flex;
              align-items: center;

              span {
                color: var(--primary-dark-color);
                font-family: "TTCommons-Regular";
                font-size: 15px;
                letter-spacing: 0;
                line-height: 19px;
              }

              .actions {
                margin-left: auto;
                margin-right: 80px;

                svg {
                  width: 30px;
                  height: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.country-insights-table-wrapper {
  .custom-table {
    .thead-row {
      td {
        h5 {
          justify-content: center;
          text-align: center;
        }
      }
    }

    .thead-row {
      td {
        &:nth-child(1) {
          width: 70px;
        }
        &:nth-child(2) {
          width: 160px;
          text-align: left;
          h5 {
            justify-content: flex-start;
          }
        }
        &:nth-child(6),
        &:nth-child(10) {
          width: 80px;
        }
        &:nth-child(7),
        &:nth-child(11) {
          width: 100px;
        }
        &:nth-child(9) {
          width: 120px;
        }
        &:nth-child(12) {
          width: 110px;
        }
        &:last-child {
          width: 140px;
        }
      }
    }

    .tbody-row {
      td {
        text-align: center;
        color: var(--primary-dark-color) !important;
        font-family: "TTCommons-Medium" !important;
        font-size: 18px !important;
        font-weight: 500 !important;
        letter-spacing: 0 !important;
        line-height: 20px !important;
        &:nth-child(1) {
          width: 70px;
        }
        &:nth-child(2) {
          width: 160px;
          text-align: left;
        }
        &:nth-child(6),
        &:nth-child(10) {
          width: 80px;
        }
        &:nth-child(7),
        &:nth-child(11) {
          width: 100px;
        }
        &:nth-child(9) {
          width: 120px;
        }
        &:nth-child(12) {
          width: 110px;
        }
        &:last-child {
          width: 150px;
        }

        .collapse-table {
          .country-insights-links-wrapper {
            h6 {
              margin: 0 !important;
            }

            h5 {
              a {
                color: var(--primary-dark-color);
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
                font-family: "TTCommons-Regular";
                margin: 0;
                text-decoration: none;
                border-bottom: 1px solid transparent;

                &:hover {
                  border-bottom: 1px solid var(--primary-color);
                }
              }

              span {
                color: #607790;
                font-family: "TTCommons-Regular";
                font-size: 15px;
                letter-spacing: 0;
                line-height: 17px;
              }
            }

            .row {
              + .row {
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }
}

.chat-wrapper {
  .filter-wrap {
    margin: -60px 0 20px 0;
  }

  .ChatSearch {
    margin: 0 0 10px 0;
  }

  .hide-search {
    margin: 0 0 50px 0;
  }
}

.custom-table > .MuiPaper-root .thead-row td table tr td:first-child h5 {
  justify-content: flex-start;
}

.default-image-100 {
  width: 100px !important;
  height: 67px;
}

.default-preview-image {
  width: 300px !important;
  height: 240px !important;
  margin: auto;
  display: flex;
}

.scroll-fix {
  position: fixed;
  display: contents;
}

.img-wrapper {
  width: 100%;
  max-width: 120px;
  margin: 0 15px 0 0;
}

.webinar-img {
  margin: auto;
  display: block;
  max-width: 85%;
  height: 90px;
  max-height: 100%;
}

.checklist-dnd {
  .checklist-categories {
    ul {
      padding: 0;
    }
  }

  li#checklist-category-li {
    margin: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 1px 5px 3px rgba(209, 221, 229, 0.4);

    a {
      padding: 24px 15px 18px;
      color: var(--primary-dark-color) !important;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      font-size: 22px;
      font-family: "TTCommons-Medium";
      line-height: 24px;
      cursor: pointer;
      text-decoration: none;
      border: 0;

      > div {
        margin-top: -4px;
      }

      span {
        word-break: break-word;
      }

      .actions-div {
        display: flex;
        margin-left: auto;
        margin-right: -10px;
        opacity: 0;

        a {
          padding: 0;

          svg {
            width: 32px;
            height: 32px;
          }
        }
      }
    }

    + li {
      margin-top: 10px;
    }

    &:hover {
      .actions-div {
        opacity: 1;
      }
    }

    &.active {
      a {
        background: #0030b5;
        color: #fff !important;

        .actions-div {
          a {
            svg {
              * {
                fill: white !important;
              }
            }

            &:hover {
              svg {
                * {
                  fill: white !important;
                }
              }
            }
          }
        }

        .actions-div {
          opacity: 1;
        }
      }
    }
  }
}

.checklist-dnd {
  #checklist-li {
    margin-bottom: 15px;
    list-style: none;

    .checklist-card {
      padding: 15px 30px 15px 20px !important;
      border-radius: 10px !important;
      box-shadow: var(--card-box-shadow) !important;
      transition: all 0.3s;
      background: #fff;
      &:hover {
        box-shadow: var(--box-shadow) !important;
      }
      .checklist-task {
        display: flex;
        align-items: center;
        span {
          margin: -2px 20px 0 0 !important;
          cursor: move;
        }
        .checklist-control {
          width: 100% !important;
          display: flex;
          align-items: center;
          .title {
            width: 100% !important;
            .primaryTask {
              color: var(--primary-dark-color);
              font-family: "TTCommons-Medium";
              font-size: 18px !important;
              font-weight: 500 !important;
              letter-spacing: 0 !important;
              line-height: 16px !important;
              padding: 4px 0 0 0;
            }
            a {
              color: var(--gray-color) !important;
              font-family: "TTCommons-Regular" !important;
              font-size: 16px !important;
              letter-spacing: 0 !important;
              line-height: 18px !important;
              border-bottom: 1px solid var(--gray-color) !important;
              padding: 0 !important;
            }
          }
          .wizzard-form {
            width: 100%;
            margin-left: 0 !important;
            form {
              .max-width {
                max-width: 100% !important;
                margin: 0 !important;
                .heading-wrapper {
                  width: 100%;
                  margin: 0 0 40px 0;
                  .task-preview {
                    width: 100%;
                    h4 {
                      color: var(--primary-dark-color);
                      font-size: 22px;
                      font-family: "TTCommons-Medium";
                      line-height: 20px;
                      word-break: break-word;
                      margin-bottom: -4px;
                    }
                    p {
                      color: var(--gray-color) !important;
                      font-family: "TTCommons-Regular" !important;
                      font-size: 16px !important;
                      letter-spacing: 0 !important;
                      line-height: 18px !important;
                      border-bottom: 1px solid var(--gray-color) !important;
                      padding: 0 !important;
                      display: inline-block;
                      margin: 0;
                    }
                  }
                  .add-task-actions {
                    margin: 0 !important;
                    display: flex;
                    align-items: center;
                    button {
                      height: 40px;
                      min-height: 40px;
                      min-width: 116px;
                      margin-right: 10px;
                    }
                    a {
                      height: 40px;
                      width: 40px;
                      min-height: 40px;
                      min-width: 40px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 100px;
                      background-color: #f1f4f7;
                      border: 0 !important;
                      img {
                        padding: 0;
                        width: 15px;
                      }
                    }
                  }
                }
                .form-group {
                  align-items: flex-start;
                  h4 {
                    white-space: nowrap;
                    color: var(--gray-color);
                    font-family: "TTCommons-Regular";
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 18px;
                    min-width: 70px;
                    margin: 18px 15px 14px 0;
                  }
                  .MuiInputBase-input {
                    font-family: "TTCommons-Medium";
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
          .actions-div {
            opacity: 0;
            transition: all 0.4s;
            display: flex;
            align-items: center;
            margin-left: auto;
            a {
              display: inline-block;
              width: 40px;
              height: 40px;
              min-width: 40px;
              + a {
                margin-left: 10px;
              }
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          &:hover {
            .actions-div {
              opacity: 1;
              transition: all 0.4s;
            }
          }
        }
      }
    }
  }
}

.checklist-items {
  padding: 0px;
  list-style: none;
}

.fix-height {
  max-height: 85px !important;
  max-width: 85px;
}

.subservices-img {
  width: 95px;
  height: 95px;
}

.post-img {
  height: 90px;
  width: 90px;
}

.checklist-tasks-wrapper .loader {
  position: initial !important;
  transform: none !important;
  margin: 50px auto 0 !important;
  display: flex;
  justify-content: center;
}

.Content-Div {
  display: flex;
  align-items: center;
  justify-content: center;
  &.max-width {
    max-width: calc(100% - 74px);
    width: 100%;
    margin: 0 auto;
  }
  .imgWrap {
    height: 70px;
    width: 70px;
    margin: 0 15px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f1f4f7;
    border-radius: 100px;
    min-width: 70px;
    overflow: hidden;
    img {
      width: 100%;
    }
    .user-svg-icon {
      width: 70px;
    }
  }

  .UserDetails {
    width: 100%;
    padding-right: 15px;
    h4 {
      color: var(--primary-dark-color);
      font-weight: 500;
      margin: 0 0 2px 0;
    }
    p {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      margin: 0;
    }
    .extra-div {
      top: -6px;
      color: var(--primary-dark-color);
      background: #f1f4f7;
      margin-left: 6px;
      border-radius: 18.5px;
      padding: 0px 6px;
      position: relative;
    }
  }
}
.Content-Div.itemListRow {
  margin: 0 0 0 15px;
}

.filter-selected {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
}
.error-message {
  color: var(--secondary-color);
  text-align: center;
  font-family: "TTCommons-Medium";
  font-weight: 500;
}
.forgot-section {
  padding: 60px 0;
  display: flex;
  align-items: center;
  min-height: 100vh;
  @media (max-width: 1367px) {
    padding: 32px 16px;
  }
  > div {
    width: 100%;
  }
  .back-url {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    a {
      display: inline-block;
      img {
        width: 18px;
        margin: 0;
        @media (max-width: 767px) {
          width: 14px;
        }
      }
    }
  }
  .BrandLogo {
    margin: 0 auto 32px;
    position: relative;
    a {
      display: inline-block;
    }
  }
  .title {
    margin-bottom: 24px;
    @media (max-width: 767px) {
      font-size: 32px;
      line-height: 32px;
      margin: 0 0 16px 0;
    }
  }
  form {
    .form-group {
      div {
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      .mt-5 {
        margin-top: 24px !important;
      }
    }
  }
}

.reset-section {
  width: 100%;
  max-width: 335px;
  margin: 0 auto;
  padding: 110px 0 40px;
  .title {
    color: var(--primary-dark-color);
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 31px;
    font-family: "TTCommons-Medium";
  }
  .subtitle {
    margin: 15px 0 0 0 !important;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    font-weight: normal;
  }
  form {
    margin: 30px 0 0 0;
  }
  .form-group {
    label {
      margin: 0 0 7px 0;
      padding: 5px 0 0 0;
      line-height: 12px;
      color: #8094ab;
      display: block;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      text-align: center;
    }
    .MuiFormControl-root {
      width: 100%;
    }
  }
  .btns-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 38px 0 38px 0;
    a {
      background-color: var(--white-color);
      box-shadow: var(--card-box-shadow);
      text-decoration: none;
      color: var(--field-label-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      height: 40px;
      display: flex;
      align-items: center;
      border-radius: 25px;
      line-height: 20px;
      transition: all 0.3s;
      justify-content: center;
      height: 50px;
      margin-right: 8px;
      width: 45%;
      padding: 15px 12px 12px;
      transition: all 0.3s;
      img {
        margin: -1px 2px 0 -8px;
        height: 14px;
        transform: rotate(180deg);
      }
      span {
        border: 0;
        margin: 3px 0 0 0;
        font-size: 18px;
      }
      &:hover {
        box-shadow: 0 1px 14px 3px #d1dde5;
      }
    }
    .forgot-button {
      padding: 16px 12px 12px;
      min-width: 180px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      text-transform: none;
      font-family: "TTCommons-Medium";
      border-radius: 3px;
      border: 1px solid;
      box-shadow: none !important;
      min-height: 50px;
      transition: all 0.3s;
      border-radius: 100px;
      background-color: #0030b5;
      border-color: #0030b5;
      color: #fff;
      width: 100%;
      margin-left: 8px;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}

h3.checklist-category-name {
  color: var(--primary-dark-color);
}

.pending-req-wrapper table {
  .thead-row {
    table {
      td {
        &:nth-child(7),
        &:nth-child(8) {
          h5 {
            justify-content: center;
          }
        }
      }
    }
  }

  .tbody-row {
    table {
      td {
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9) {
          text-align: center;

          .table-actions {
            justify-content: center;
          }
        }
      }
    }
  }
}

.infinite-loading-loader {
  .loader {
    position: initial !important;
    transform: none !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

.kits-list {
  .image-wrapper {
    width: 100%;
    min-width: 143px;
    min-height: 79px;
    max-width: 143px;
    max-height: 79px;
    margin: 0 15px 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: rgba(241, 244, 247, 0.5);
    img {
      max-width: 100%;
      max-height: 79px;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  .video-wrapper {
    width: 100%;
    min-width: 200px;
    min-height: 115px;
    max-width: 200px;
    max-height: 115px;
    margin: 0 15px 0 15px;
    display: flex;
    align-items: center;
    border-radius: 5px;

    video {
      max-width: 200px;
      max-height: 115px;
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
}

.file-name-preview {
  align-items: center;
  white-space: normal;
  word-break: break-all;
  padding: 10px 10px;
  color: var(--primary-dark-color);
}

.other-type {
  img {
    width: 352;
    height: 242;
  }
}

div.square-croppper {
  .cropper-crop-box,
  .cropper-view-box {
    border-radius: 5%;
  }
}

div.circle-croppper {
  .cropper-crop-box,
  .cropper-view-box {
    border-radius: 50% !important;
  }
}

.fileupload-custom {
  img.other-type-preview {
    padding: 10px;
    // max-width: 50px;
    // height: auto;
  }
}

.archived-checklist {
  .primaryTask {
    color: var(--primary-dark-color);
    font-size: 22px;
    font-family: "TTCommons-Medium";
    line-height: 28px;
    word-break: break-word;
  }

  a.task-link {
    color: #f76155;
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    text-align: right;
    border-bottom: 1px solid #f76155;
    text-decoration: none;
  }
}

.archived-checklist {
  .drag-main-row {
    height: auto !important;
  }
}

.topics-wrapper {
  .CreatePostWrap {
    .MuiTextField-root {
      width: 100%;
    }

    .MuiInputBase-input {
      text-align: left;
    }

    .description-link {
      a {
        color: #0030b5;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        font-family: "TTCommons-Regular";
        border-bottom: 1px solid #0030b5;
        display: inline-block;
        text-decoration: none;
      }
    }
  }

  .file-name-preview {
    padding: 10px 10px 0px !important;
  }

  .wizzard-form {
    min-height: auto !important;
    padding-bottom: 20px !important;
    .preview-item {
      margin-top: 15px;
      display: inline-block;
      color: #f76155;
      border-bottom: 1px solid #f76155;
      font-family: "TTCommons-Medium";
      font-size: 18px;
    }
  }

  .PostContentItem {
    .post-actions {
      margin-left: auto;
      text-align: right;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
      img {
        cursor: pointer;

        + img {
          margin-left: 10px;
        }
      }
    }
    &:hover {
      .post-actions {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.preview-nbox-wrappe {
  margin: 30px 0 0;

  .img-wrap {
    img {
      width: 100%;
    }
  }

  h3 {
    margin: 0 0 15px;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
  }

  h4 {
    margin: 0;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Regular";
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
  }

  h5 {
    margin: 0;
    color: var(--gray-text-color);
    font-family: "TTCommons-Regular";
    font-size: 15px;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    line-height: 15px;
  }

  .topic-preview-video-section {
    margin: 0 0 30px;

    > div {
      width: 100% !important;
    }
  }
}

.topic-post {
  .header-primary {
    position: relative;
    &__content {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      white-space: break-spaces;
      margin: 0 0 10px 0;
    }
    &__attachment {
      img {
        width: 100%;
      }
    }
    &__url {
      background: #f1f4f7;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
      &__image {
        img {
          width: 100%;
        }
      }
      &__title {
        margin: 10px !important;
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        line-height: normal;
        white-space: break-spaces;
      }
      &__description {
        width: 100%;
        padding-inline: 10px;
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        display: inline-block;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.no-user-icon {
  background: #f1f4f7;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 24px !important;
    height: auto !important;
  }
}

.archived-content-wrapper {
  .homefeed-content {
    margin: 0 0 20px 0;

    .homefeed-item {
      .img-wrap {
        width: 80px;
        height: 80px;
        border-radius: 80px;
        overflow: hidden;
        min-width: 80px;
        margin: 0 15px 0 0;
        &.no-user-icon {
          img {
            width: 80px !important;
          }
        }
      }

      .text-content {
        h3 {
          word-break: break-word;
          align-items: center;
          display: flex;
          margin: 0 0 4px 0;
          .tag {
            border-radius: 12.5px;
            background-color: var(--icon_background);
            padding: 3px 8px 0;
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            font-size: 16px;
            letter-spacing: 0;
            line-height: 18px;
            display: flex;
            align-items: center;
            min-height: 25px;
            min-width: 62px;
            justify-content: center;
            margin-bottom: 0;
            margin-left: 8px;
          }
        }
        p {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 19px;
        }
        h6 {
          color: var(--gray-color);
          font-family: "TTCommons-Regular";
          font-size: 16px;
          letter-spacing: 0;
          line-height: 18px;
        }
      }
    }
  }
}

img.rss-logo {
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

p.date-width {
  max-width: max-content;
}

.webinar-item {
  display: flex;
  grid-template-columns: 1fr auto;
  align-items: baseline;
}

.country-insights-cities-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
}
.school_name_align {
  padding-right: 10px !important;
}

.custom-icon-btn {
  text-align: center;
  button {
    box-sizing: border-box;
    border: 1px solid var(--gray-color-light) !important;
    border-radius: 50px;
    padding: 15px 17px 15px 17px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 19px;
    background-color: transparent !important;
    text-transform: none;
    img {
      height: 20px;
      margin-top: -2px;
      margin-right: 8px;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.align-promote-center {
  text-align-last: center;
  padding: 0px 20px 20px 20px;
}

.height-align {
  word-wrap: break-word;
  line-height: 22px !important;
}

.image-align {
  margin: 0 75px 15px 75px !important;
}

.promote-head-content {
  display: flex;
  grid-column-gap: 10px;

  .action-color {
    color: red !important;
  }
}

.desc-size {
  font-size: 15px !important;
}

.homefeed {
  .homefeed-content {
    .homefeed-item {
      &.promote-item {
        padding: 15px 30px 15px;
        align-items: center;
        .drag-icon {
          margin: 0 22px 0 0;
          span {
            display: flex;
          }
        }
        .text-content {
          h3 {
            margin: 0 0 4px 0;
            min-height: auto;
          }
          .desc-size {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            font-size: 18px !important;
            letter-spacing: 0;
            line-height: 15px;
            padding: 4px 0 0 0;
            margin: 0 0 6px 0;
          }
          .promote-head-content {
            h6 {
              color: var(--gray-color);
              font-family: "TTCommons-Regular";
              font-size: 16px;
              letter-spacing: 0;
              line-height: 18px;
            }
            h6 {
              min-height: auto;
            }
          }
        }
        .img-wrap {
          height: 68px;
          width: 166px !important;
          max-width: 166px !important;
          min-width: 166px;
          img {
            max-width: 100%;
            width: auto !important;
            height: auto !important;
            object-fit: initial !important;
            border-radius: 10px;
            max-height: 68px !important;
          }
        }
      }
    }
  }
}

.promote-img-wrap {
  margin-left: 16px !important;
  max-width: 70px !important;
}

.school_name_align {
  padding-right: 10px !important;
}

input[placeholder] {
  text-overflow: ellipsis;
}

.star-img-width {
  max-width: 18px !important;
}

.change-star-position {
  display: flex;
}

.cancel-icon {
  position: absolute;
  right: 45px;
  top: 20px;
  cursor: pointer;
}

.resource-dialog {
  .MuiDialog-container {
    box-sizing: border-box;

    .MuiPaper-root {
      width: 100% !important;
      overflow: hidden;
      padding: 0px 26px 0px 26px;
    }

    .MuiDialog-paperWidthXs {
      max-width: 960px;
    }

    .model-body {
      max-width: 100%;
      margin: 20px;
      .video-image {
        vertical-align: middle;
      }
    }
  }
  .video-image {
    width: 100%;
    max-height: calc(100vh - 160px);
  }
}

.linkedin-column-combined {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 5px;
}

.webinars__add-rss-tooltip {
  position: absolute;
  margin: -68px -15px 0px -3px;
  svg {
    color: $color-brand-blue;
  }
}

.guidance-resource-wrapper {
  .editor-wrapper {
    max-width: 600px;
    margin: 0 auto;

    span.grey-text {
      margin-bottom: 15px;
      color: #607790;
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
    }
  }

  .html-content-preview {
    font-size: 18px;
    font-family: "TTCommons-Regular";
    color: #607790;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
    max-width: 670px;
    margin: 0 auto;
    padding: 20px 20px 5px 20px;

    a {
      color: #f76155cc;
      text-decoration: none;
    }
  }
}

.GuidenceContentWrapper {
  h3 {
    color: var(--primary-dark-color);
    margin: 0 0 12px 0;

    p {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: normal;
      margin: 0 0 12px 0;
    }
  }

  .drag-content-wrap {
    .bg-wrap {
      .drag-main-row {
        height: auto;

        .drag-item {
          align-items: center;
          height: 100% !important;
          min-height: 70px;

          .left {
            width: 70%;

            h3 {
              max-width: 100% !important;
              font-size: 15px;
              letter-spacing: 0;
              line-height: 17px;
              color: var(--primary-dark-color);
              overflow: visible;
              white-space: normal;

              a {
                color: #f76155cc;
                text-decoration: none;
              }
            }
          }

          .DocType {
            width: 15%;
            text-align: right;

            p {
              margin: 0;
              font-size: 11px;
              font-weight: bold;
              letter-spacing: 1.5px;
              line-height: 16px;
              font-family: "brandon_grotesquebold";
              color: #8094ab;
            }
          }

          .actions-div {
            width: 15%;
            justify-content: flex-end;
          }
        }
      }

      .empty {
        height: 70px !important;
      }
    }
  }

  + .GuidenceContentWrapper {
    margin: 15px 0 0 0px;
  }
}

.announcement-with-emoji {
  position: relative;
  .homefeed-emoji-picker-wrapper {
    width: auto !important;
    position: absolute;
    right: 15px;
    bottom: 15px;
    .emoji-icon {
      cursor: pointer;
    }
    .emoji-picker {
      position: relative;
      z-index: 1;
    }
  }
  .announcement-error {
    bottom: 40px;
  }
}

.homefeed-artical-preview {
  &__icon {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 3px;
    cursor: pointer;

    &--margin {
      margin-top: 10px;
    }
  }

  &__title {
    margin: 0 0 9px;
    font-size: 18px;
    line-height: 19px;
    color: var(--primary-dark-color);
  }

  &__desc {
    color: #607790;
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 17px;
    white-space: break-spaces;
  }
}

.homefeed-final-preview {
  &--youtube {
    max-width: 360px !important;
  }

  &__avatar {
    height: 28px;
    width: 28px;
    margin: 0 8px 0 0;
    border-radius: 100px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    .initial-avatar {
      font-size: 15px;
      width: 100%;
      height: 100%;
    }
  }
}
.facelift-homefeed-preview {
  width: 100%;
  max-width: 469px !important;
  .box-wrap {
    padding: 14px 30px 21px;
    .post-header {
      margin-bottom: 8px;
      .img-content {
        h4 {
          font-size: 12.6px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 13.3px;
          span {
            color: #8094ab;
            font-size: 10.5px;
            letter-spacing: 0;
            line-height: 11.9px;
            margin-left: 4px;
          }
        }
      }
    }
    .slider-container-preview {
      margin: 0 0 30px 0 !important;
    }
    .left {
      margin: 0 0 12px 0;
      img {
        border-radius: 4px;
      }
      iframe {
        width: 100%;
      }
      video {
        width: 100%;
      }
    }
    .right {
      h4 {
        color: #000c3d;
        font-size: 15.4px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16.8px;
      }
      h5 {
        color: #000c3d;
        font-size: 12.6px;
        letter-spacing: 0;
        line-height: 13.3px;
        margin-bottom: 0;
        white-space: break-spaces;
      }
    }
  }
  .pdf-preview-div {
    max-width: 360px;
    width: 100%;
  }
}

.records-selection-bar {
  box-sizing: border-box;
  width: 100%;
  border: 2px solid #0030b5;
  background-color: #0030b5;
  position: fixed;
  bottom: 0px;
  z-index: 5;
  display: flex;
  align-items: center;
  width: calc(100vw - 70px);
  left: 70px; // closed drawer's width
  padding: 8px 108px 8px 32px;

  .selction-text {
    color: #ffffff;
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 15px;
  }

  .clear-all {
    margin-left: 20px;
    color: #ffffff;
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 15px;
    border-bottom: 1px solid #ffffff;
    text-decoration: none;
  }

  .left-content {
    margin-left: auto;
    display: flex;

    .selective-actions-container {
      display: flex;
      gap: 5px;
    }

    .MuiIconButton-root {
      padding: 0;
      color: #ffffff;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 17px;
      font-family: "TTCommons-Regular";
      background: transparent;

      img {
        width: 40px;
        margin: -4px 0px 0 0;
        vertical-align: middle;
      }

      + button {
        margin-left: 20px;
      }
    }
  }
}

.Sidebar-open {
  .records-selection-bar {
    width: calc(100vw - 275px);
    left: 275px; // open drawer's width
  }
}

body {
  .ambassador-profile-dialog {
    .MuiPaper-root {
      padding: 0 !important;
      max-width: 673px;
      width: 100%;
      margin: 0 !important;
      .MuiDialogContentText-root {
        padding: 0 !important;
      }
      .MuiDialogTitle-root {
        display: none;
        + .MuiDialogContent-root {
          margin: 0;
          margin: 0;
        }
      }
      button {
        margin-top: 16px !important;
      }
      .cancel-icon {
        right: 20px !important;
      }
    }
    .MuiDialog-container {
      .MuiDialog-paper {
        overflow: hidden;
        > div {
          overflow: auto;
          position: relative;
        }
        .MuiDialogContent-root {
          padding: 0 !important;
          overflow-x: hidden !important;
          overflow-y: auto !important;
          padding: 30px 24px !important;
        }
        .MuiDialogActions-root {
          padding: 0;
          margin: 0 !important;
        }
      }
    }
  }
  .nadmin-card-modal {
    .MuiPaper-root {
      padding: 0 !important;
      max-width: 325px !important;
      width: 100%;
      margin: 0 !important;
      .MuiDialogContentText-root {
        padding: 0 !important;
      }
      .MuiDialogTitle-root {
        display: none;
        + .MuiDialogContent-root {
          margin: 0;
          margin: 0;
        }
      }
      button {
        margin-top: 16px !important;
      }
      .cancel-icon {
        right: 20px !important;
      }
    }
    .MuiDialog-container {
      .MuiDialog-paper {
        overflow: hidden;
        > div {
          overflow: auto;
          position: relative;
        }
        .MuiDialogContent-root {
          padding: 0 !important;
          overflow-x: hidden !important;
          overflow-y: auto !important;
          padding: 30px 24px !important;
        }
        .MuiDialogActions-root {
          padding: 0;
          margin: 0 !important;
        }
      }
    }
  }
  .monitor-message-modal {
    .MuiDialog-paper {
      padding: 30px;
      max-width: 560px;
      .MuiDialogTitle-root {
        margin: 0 !important;
      }
      .cancel-icon {
        top: 30px;
        right: 30px;
      }
      .MuiDialogActions-root {
        margin-top: 0 !important;
      }
    }
  }
}

.engagement-section {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 40px;

  .engagement-title {
    margin: 0 0 28px 0 !important;
  }

  .section-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 22px !important;
    margin: 0 0 8px 0 !important;
  }

  .demographics-title {
    font-size: 22px !important;
    margin: 40px 0 20px 0 !important;
  }

  .section-title-container {
    display: flex;
    align-items: center;

    a {
      margin: 0px 0px 2px 10px;
    }
  }

  .secondary-note {
    color: #f76155;
    margin-bottom: 10px;
  }
}

.clickable-cards-horizontal {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.npopover-wrapper {
  .MuiPopover-paper {
    max-width: 600px;
    border-radius: 1px 10px 10px 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 0.4) !important;
  }

  &__content {
    padding: 0;
    margin: 15px;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Regular";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 20px;

    a {
      color: var(--primary-dark-color);
      display: inline-block;
      text-decoration: none;
      font-family: "TTCommons-Demibold";
    }
  }

  &__title {
    margin-bottom: 10px;
    text-align: center;
  }
}

.features-list-container {
  display: flex;
  margin: 0 -10px;
  .features-list {
    padding: 0 10px;
    .drag-wrapper-header {
      padding: 0 20px 0 0;
      margin: 0 0 15px 0;
      align-items: center;
      h3 {
        padding: 0 !important;
      }
    }
    .drag-main-row {
      padding: 24px 20px;
      .collapse-items-container {
        gap: initial;
        .collapse-item {
          + .collapse-item {
            margin-top: 30px !important;
          }
        }
        .collapse-action-group {
          position: relative;
          .collapse-content {
            h4 {
              padding-right: 48px;
              margin: 0 0 16px 0;
            }
            p {
              color: var(--gray-color);
              font-family: "TTCommons-Regular";
              font-size: 18px;
              letter-spacing: 0;
              line-height: 19px;
              margin: 0;
            }
          }
          .actions-div {
            position: absolute;
            right: 0;
            min-width: auto;
          }
        }
      }
    }
  }
}

.collapse-items-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.features-list {
  flex: 1;

  .bg-wrap {
    border-radius: 10px !important;
  }

  .drag-collapse-row {
    padding: 0 !important;
    width: 100%;
  }

  .drag-main-row {
    height: auto !important;
    padding: 24px 20px;
  }

  .drag-item {
    height: auto !important;
    padding: 0 !important;
  }

  .drag-wrapper-header {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
  }

  .actions-div {
    opacity: 1 !important;
    align-items: flex-start !important;
    margin-top: -4px;
    padding-right: 0 !important;
  }

  .collapse-action-group {
    display: flex;
    justify-content: space-between;
    padding-right: 0px;
    width: 100%;
    p {
      font-size: 18px !important;
    }
  }

  .collapse-item {
    padding: 0 !important;
    border: none !important;
  }

  .collapse-item + .collapse-item {
    margin: 0 !important;
  }

  &.max-w-40 {
    max-width: 40%;
  }
}

.manage-version-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  .MuiFormControl-root {
    min-width: 350px;
  }
  .action-items {
    display: flex;
    justify-content: center;
  }
}

.admin-profile-text {
  font-family: "TTCommons-Medium";
  color: #000c3d;
}

.admin-profile-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .admin-profile-photo-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px !important;
    span {
      cursor: pointer;
      color: #607790;
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
    }
  }
  .admin-profile-first-last-name-wrapper {
    display: flex;
    width: 60%;
  }
  .admin-profile-first-last-name-wrapper > div:first-child {
    padding-right: 0.5rem;
  }
  .admin-profile-first-last-name-wrapper > div:nth-child(2) {
    padding-left: 0.5rem;
  }

  .admin-profile-introduction-wrapper {
    width: 60%;
  }

  .admin-profile-position-email-wrapper {
    display: flex;
    width: 60%;
  }
  .admin-profile-position-email-wrapper > div:first-child {
    padding-right: 0.5rem;
  }
  .admin-profile-position-email-wrapper > div:nth-child(2) {
    padding-left: 0.5rem;
  }

  .admin-profile-change-password-wrapper {
    width: 477px;
    display: flex;
    flex-direction: column;
    text-align: center;
    label {
      font-size: 21px;
    }
  }

  .admin-profile-time-zone-wrapper {
    display: flex;
    width: 420px;
  }

  .admin-profile-password-wrapper {
    display: flex;
    flex-direction: column;
    width: 420px;
  }

  .admin-profile-phone-number-wrapper {
    width: 420px;
  }
}
.color-blue {
  background-color: var(--primary-color);
}
.info-icon {
  height: 17px;
  cursor: pointer;
}
.info-icon-large {
  height: 20px;
  cursor: pointer;
}

.popover-wrapper {
  background-color: rgba(128, 148, 171, 0.4);
  .MuiPopover-paper {
    max-width: 390px;
    padding: 20px;
    border-radius: 0 10px 10px 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(128, 148, 171, 40%);
    margin: 0 0 0 10px;

    p {
      color: #607790;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: left;
      margin: 0;
    }
  }
}

.header {
  .switch-portal-tab {
    display: flex;
    margin: 0;
    &__button {
      text-decoration: none;
      border: 2px solid var(--icon_background);
      padding: 10px 16px 5px;
      box-shadow: none;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
      text-align: center;
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      text-transform: capitalize;
      min-height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 116px;
      @media (max-width: 991px) {
        min-height: 40px;
      }
      @media (max-width: 360px) {
        padding: 10px 18px 5px;
      }
      &--left {
        border-radius: 32.5px 0 0 32.5px;
      }

      &--right {
        border-radius: 0px 32.5px 32.5px 0px;
      }

      &--active {
        background-color: var(--link-color);
        color: #fff;
        border-color: var(--link-color);
      }
    }
  }
}

.notifications__sidebar {
  width: 33%;
  height: 100%;
  position: fixed;
  top: 60px;
  right: -40%;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background: #ffffff;
  padding: 24px 30px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  z-index: 99;
  z-index: 1051;
  @media (min-width: 320px) {
    width: 84%;
    right: -84%;
  }
  @media (min-width: 375px) {
    width: 86%;
    right: -86%;
  }
  @media (min-width: 425px) {
    width: 88%;
    right: -88%;
  }
  @media (min-width: 768px) {
    width: 35%;
    right: -35%;
  }
  @media (min-width: 1024px) {
    width: 35%;
    right: -35%;
  }
  @media (min-width: 1440px) {
    width: 35%;
    right: -35%;
    top: 60px;
  }
  @media (min-width: 2560px) {
    width: 35%;
    right: -35%;
  }
}
.notifications__sidebar_active {
  right: 0% !important;
}

.notification-content {
  margin-top: 1rem;

  .notification-content-empty {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: normal;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0 1px 5px 3px rgba(209, 221, 229, 0.4);
    margin: 0.5rem 0 0.5rem 0;
    padding: 60px 24px;
  }

  .MuiGrid-container {
    margin: 0 -10px;
    > .MuiGrid-item {
      padding: 0 10px;
    }
  }

  .notification-content-categories-wrapper {
    .notification-content-categories-item-wrapper {
      display: flex;
      align-items: center;
      background-color: #ffffff;
      box-shadow: var(--card-box-shadow);
      margin: 0 0 15px 0;
      padding: 15px 25px;
      border-radius: 20px;
      cursor: pointer;
      .image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        width: 44px;
        min-width: 44px;
        border-radius: 100px;
        background-color: var(--icon_background);
        margin: 0 15px 0 0;
        img {
          max-width: 30px;
          width: 100%;
        }
      }
      .notification-content-categories-item-title {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 16px;
        padding: 4px 0 0 0;
      }
    }
    .notification-content-categories-item-wrapper:hover {
      background-color: var(--primary-color);
      .notification-content-categories-item-title {
        color: #ffffff;
      }
    }
    .notification-content-categories-item-wrapper-selected {
      background-color: var(--primary-color) !important;
      .notification-content-categories-item-title {
        color: #ffffff !important;
      }
    }
  }
  .notification-content-list-wrapper {
    > h3 {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 22px;
      font-weight: normal;
      line-height: 22px;
      margin: 0;
      margin-bottom: 20px;
    }
  }
}

.notifications-listing-list-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: var(--card-box-shadow);
  padding: 17px 30px;
  border-radius: 10px;
  margin: 0 0 15px 0;
  .notifications-listing-list-item-content {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 345px);
    h3 {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 22px;
      margin: 0 0 0px 0 !important;
    }
    .notifications-listing-list-item-content-title {
      color: #607790;
      font-family: "TTCommons-Regular";
      font-size: 15px;
      letter-spacing: 0px;
      line-height: 17px;
      margin: 0 0 0px 0 !important;
    }
    .notifications-listing-list-item-group-options-wrapper {
      margin-top: 0.5rem;
      .notifications-listing-list-item-group-options-list {
        display: flex;
        flex-direction: column;
        .notifications-listing-list-item-group-options-item {
          display: flex;
          align-items: center;
          span {
            padding-left: 0.5rem;
            padding-top: 2px;
          }
        }
      }
    }
  }
  .notifications-listing-list-item-controls {
    min-width: 180px;
    &-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0px;
      }
      &-title {
        color: var(--primary-dark-color);
      }
      &-control {
        display: flex;
        align-items: center;
        &-text {
          color: #607790;
          height: 21px;
          margin-left: 10px;
        }
      }
      &-control-active {
        span {
          color: var(--primary-dark-color) !important;
        }
      }
    }
  }

  .notifications-listing-list-item-email-frequency {
    margin-top: 24px;
    font-size: 15px;
    font-family: "TTCommons-Regular";
    color: #000485;

    .notifications-listing-list-item-email-frequency-title {
      color: #000485;
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 19px;
    }

    .notifications-listing-list-item-email-frequency-options {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 18px;

      li {
        display: flex;
        align-items: center;
        margin-right: 35px;
      }
    }
  }
}

.notification-settings-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  &-title {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    h3 {
      height: 26px;
      color: var(--primary-dark-color);
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 30px;
      font-family: "TTCommons-Medium";
      margin: 0;
      overflow: hidden;
    }
  }
  > h3 {
    margin-right: -413px;
  }
  &-controls {
    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-label {
        color: var(--gray-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 17px;
        margin: 0 12px 0 0;
      }
      &-active {
        > span {
          color: var(--primary-dark-color) !important;
        }
      }
      + div {
        margin-top: 4px;
      }
    }
  }
}

.profile-image-wrapper {
  > div {
    .fileupload-custom {
      .dropzone {
        width: 111px !important;
        border-radius: 100px;
        margin: 0 auto 10px;
        min-height: 111px;
      }
    }
  }
  // .fileupload-custom {
  //   .placeholder {
  //     img {
  //       width: 60px !important;
  //     }
  //   }
  // }
}
.notification-overlay {
  position: fixed;
  left: 100%;
  top: 58px;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 148, 171, 0.2);
  transition: all 0.1s;
  -webkit-transition: all 0.1s !important;
  z-index: 1050;
  &.notification-overlay-open {
    left: 0;
  }
}
.notifications-drawer {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    &__left {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      h3 {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        line-height: 20px;
        padding-top: 4px;
        margin: 0;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      button {
        margin-left: 5px;
      }
      svg {
        margin-top: 0px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-left: 12px;
        * {
          fill: var(--primary-dark-color);
        }
      }
      .nlink-secondary {
        width: auto !important;
        height: auto !important;
        background: transparent !important;
        border-radius: 0 !important;
        margin: 0 0 0px 12px !important;
      }
    }
  }
  &__content {
    margin-top: 1rem;
    margin-bottom: 1rem;

    &__empty {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Medium";
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border-radius: 3px;
      box-shadow: var(--card-box-shadow);
      margin: 0;
      padding: 24px 24px;
      border-radius: 10px;
    }

    &__view-more {
      text-align: center;
      margin-top: 10px;
      span {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 15px;
        border-bottom: 1px solid var(--primary-color);
        text-decoration: none;
      }
    }
  }
}

.notifications-page {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 24px;

    &__title {
      color: var(--primary-dark-color);
      font-size: 26px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 30px;
      font-family: "TTCommons-Medium";
      padding: 4px 0 0 0;
    }
    &__settings {
      button {
        padding: 0;
        color: var(--primary-dark-color);
        font-size: 16px;
        letter-spacing: 0;
        line-height: 17px;
        font-family: "TTCommons-Medium";
        font-weight: 500;
        background: #fff;
        box-shadow: var(--card-box-shadow);
        background-color: var(--white-color);
        border-radius: 100px;
        padding: 5px 16px 5px 5px;
        display: flex;
        align-items: center;
        transition: all 0.3s;
        &:hover {
          box-shadow: var(--box-shadow);
          background: #fff;
        }
        img {
          width: 30px;
          vertical-align: middle;
          margin: 0 5px 0 0;
        }
        .text-label {
          margin-top: 3px;
        }
      }
    }
  }

  &__list {
    margin-bottom: 1rem;
    &__new-notifications {
      margin-bottom: 24px;
      &__title {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        margin-bottom: 10px;
      }

      &__empty {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border-radius: 3px;
        box-shadow: var(--card-box-shadow);
        margin: 0;
        padding: 24px 24px;
        border-radius: 10px;
      }
    }

    &__past-notifications {
      margin-bottom: 2rem;

      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 10px;
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";

        &__clear-all {
          font-size: 18px;
          line-height: 16px;
          color: var(--link-color);
          font-family: "TTCommons-Medium";
          font-weight: 500;
          text-decoration: none;
          border-bottom: 2px solid var(--link-color) !important;
          cursor: pointer;
          padding-top: 3px;
          padding-left: 0;
          padding-right: 0;
          transition: all 0.3s;
          text-decoration: none !important;

          &:hover {
            text-decoration: none;
          }
        }
      }

      &__empty {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border-radius: 3px;
        box-shadow: 0 1px 5px 3px rgba(209, 221, 229, 0.4);
        margin: 0.5rem 0 0.5rem 0;
        padding: 24px 24px;
      }
    }
  }
}

.notification-item {
  .notification-container1 {
    box-shadow: var(--card-box-shadow);
    border-radius: 10px;
    background-color: #ffffff;
    transition: all 0.3s;
    &:hover {
      box-shadow: var(--box-shadow);
    }

    .small-notification-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 10px;
      .small-actions-div {
        opacity: 0;
        a {
          background-color: transparent !important;
        }
      }
      &:hover {
        .small-actions-div {
          opacity: 1;
        }
      }
    }

    .notification-container2 {
      display: flex;
      padding: 15px 25px;
      align-items: center;

      .img-wrap {
        background-color: var(--icon_background);
        min-width: 44px;
        min-height: 44px;
        border-radius: 100px;
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        margin: 0 15px 0 0;

        img {
          max-width: 24px;
          width: 100%;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
        }
      }
      a {
        width: 100%;
      }
      .small-text-content {
        h3 {
          font-size: 18px !important;
        }
        .tag-label {
          font-size: 15px;
          background-color: #f1f4f7;
          border-radius: 12.5px;
          padding: 4px 9px 0px;
          margin-right: 8px;
        }
        .time-label {
          font-size: 15px;
          padding: 0px;
          margin: 5px 0px 0px;
          color: var(--gray-color);
        }
      }

      .text-content {
        width: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding-top: 4px;
        h2 {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          letter-spacing: 0;
          line-height: 19px;
          max-width: 125px;
          margin-right: 24px;
          min-width: 125px;
        }
        h3 {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          margin: 0 8px 0 0;
          max-width: 65vw;
        }
        img {
          width: 5px;
          margin: -2px 2.5rem 0 0;
        }
        h6 {
          color: var(--gray-color);
          font-family: "TTCommons-Regular";
          font-size: 16px;
          letter-spacing: 0;
          line-height: 19px;
          margin: 0 15px 0 auto;
          min-width: 7rem;
        }
      }

      .actions-div {
        margin-left: auto;
        display: flex;
        align-items: center;
        opacity: 0;
        a {
          cursor: pointer;
          min-width: 40px;
          text-align: center;
          width: 40px;
          height: 40px;
          border-radius: 100px;
          overflow: hidden;
          + a {
            margin-left: 10px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    &:hover {
      .actions-div {
        opacity: 1;
      }
    }
  }

  + .notification-item {
    margin: 15px 0 0 0;
  }
}

// Common tooltip customization style
.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    margin: 10px 0 0 15px !important;
    padding: 12px 16px 9px 16px;
    letter-spacing: 0;
    color: var(--primary-dark-color);
    max-width: 500px;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    line-height: 17px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 14px 0 rgba(209, 221, 229, 0.5);
  }
  .MuiTooltip-arrow {
    color: #fafbfd !important;
    transform: translate(20px, 0px) !important;
  }
}

.engagement-details {
  .page-content {
    margin: auto 10%;

    .user-name {
      font-family: "TTCommons-Medium";
      font-size: 30px;
      font-weight: normal;
      line-height: 30px;
      color: var(--primary-dark-color);
      margin: 0 0 25px 0;
    }
    .events {
      .drag-content-wrap {
        .drag-collapse-row {
          padding: 0 20px 20px !important;
        }
      }
    }
  }
}

.drag-icon {
  min-width: 12px;
  img {
    cursor: pointer;
  }
}

.content-footer {
  background-color: #fafbfd;
  border-top: 1px solid #e8eef2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  position: fixed;
  bottom: 0;
  padding: 0;
  width: stretch;
  width: -moz-available;
  margin-right: 30px;

  div[type="primary"] {
    button {
      width: 100%;
      background-color: #0030b5 !important;
      border-color: #0030b5 !important;
      border-radius: 45px !important;
      width: 188px;
      height: 50px;
      font-size: 18px !important;
      padding: 4px 0 0 0px;
      color: #fff;
      &:hover {
        opacity: 0.9;
      }
    }
  }

  div[type="secondary"] {
    button {
      width: 100%;
      background-color: #fff !important;
      border-color: #fff !important;
      border-radius: 20px !important;
      width: 188px;
      height: 50px;
      font-size: 18px !important;
      padding: 4px 0 0 0px;
      color: #000c3d !important;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4) !important;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}

.profile-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  .profile-title {
    position: relative;
    margin: auto;
  }

  div[type="secondary"] {
    button {
      position: absolute;
      right: 0;
      top: -8px;
      background-color: #fff !important;
      border-color: #fff !important;
      border-radius: 20px !important;
      width: 111px;
      height: 40px;
      font-size: 18px !important;
      padding: 4px 0 0 0px;
      color: var(--primary-dark-colo) !important;
      min-height: 40px;
      min-width: 111px;
      box-shadow: var(--card-box-shadow) !important;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}

.change-password-footer-content {
  display: flex;
  gap: 30px;
}

.onboarding-profile-dialog {
  .MuiDialog-paper {
    .MuiDialogContent-root {
      overflow: visible !important;
    }
  }
  .MuiDialog-container {
    .MuiDialog-paper {
      > div {
        padding: 30px 30px 40px 30px;
        overflow: auto;
      }
      .MuiDialogTitle-root {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
      }
      .text-subheader {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Regular";
        font-size: 18px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
      }
      .MuiDialogContent-root {
        padding: 0;
      }
    }
  }
}
.onboarding-profile-wrapper {
  max-width: 443px;
  margin: 40px auto 0;
  .admin-profile-photo-wrapper {
    margin-bottom: 30px;
    .fileupload-custom {
      .dropzone {
        width: 111px;
        height: 111px;
        min-height: auto;
        border-radius: 100px;
        margin: 0 auto 10px;
        .placeholder {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            // width: 63px;
            height: auto;
          }
        }
      }
    }
    span {
      color: var(--primary-dark-color);
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
    }
  }
  .row {
    margin: 0 -5px;
    justify-content: center;
    > div {
      padding: 0 5px;
    }
    .form-group {
      .MuiTextField-root {
        .MuiInputBase-formControl {
          padding: 0;
          textarea {
            min-height: 100px;
            height: auto !important;
          }
        }
      }
    }
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .btns-wrapper {
    margin-top: 15px;
    button {
      width: 100%;
      max-width: 186px;
    }
  }
}

.confirmation-dialogue-wrapper {
  .MuiDialog-container {
    .MuiPaper-root {
      padding: 50px 30px 40px;
      max-width: 560px;
      .MuiDialogTitle-root {
        margin-bottom: 50px !important;
      }
      h2 {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
        text-align: center;
      }
      .MuiDialogActions-root {
        max-width: 340px;
        margin: 0 auto !important;
        padding: 0;
      }
    }
  }
}

#confirmation-dialog-title {
  text-align: center !important;
}

.add-form-logo {
  margin-bottom: 50px;
  .fileupload-custom {
    .dropzone {
      width: 111px;
      height: 111px;
      border-radius: 100px;
      margin: 0 auto 10px;
      min-height: 111px;
    }

    .upload-img {
      &.placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 100% !important;
          height: auto !important;
          margin: 0;
        }
      }
    }
  }
}

.form-header-right-content {
  position: absolute;
  right: 0;
  top: -4px;
}

.form-header-step-counter {
  top: 12px;
}

.form-header-title {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 4px 0 0 0;
  .page-title {
    margin-bottom: 0;
    padding: 4px 0 0 0;
  }
}

.admin-access {
  .multiselect-wrap .multiselect-content > div {
    max-width: 168px;
  }
}

.sketch-picker {
  border-radius: 15px !important;
}

.refresh-intro-tutorial {
  color: var(--primary-dark-color) !important;
  border: none !important;
  min-height: 30px !important;
  span {
    margin-top: 2px;
  }
}

.add-email-template-upload {
  .dropzone {
    border-radius: 18.63px;
    min-height: 192px;
  }
}

.chart_card {
  width: 100%;
  padding: 30px 30px 20px 30px;
  background-color: #ffffff !important;
  margin-top: 20px;
  box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.5);
  border-radius: 10px;
}

.dashboard_switchable_charts {
  .chart_card__title {
    color: #000c3d;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center !important;
  }

  .world_chart_wrapper {
    width: 100%;
    border-radius: 3px;
    background-color: #ffffff !important;
    text-align: center;
    padding: 0 !important;
    margin-bottom: 0 !important;
    .chart_card {
      padding: 0 !important;
      margin: 0 !important;
      box-shadow: none;
    }
  }
}

.dashboard_resources_stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  .section-title {
    margin: 0 0 20px 0 !important;
    font-size: 22px !important;
  }
}

.dashboard_resources_list {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin: auto;
  max-width: 100%;
  .white-bg-card {
    // width: calc(20% - 20px);
    padding: 22px 10px 18px 10px !important;
  }
}

.dashboard_usage_stats {
  padding: 0;
  .section-title {
    margin: 40px 0 40px 0 !important;
    font-size: 26px !important;
  }
  .usage-stats-container-wrapper {
    > h3 {
      margin-bottom: 16px;
    }
    .usage-stats-container {
      .chart-selector {
        margin-top: -45px;
      }
    }
  }
  .usage-stats-container {
    display: flex;
    .chart-selector {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0;
      width: 100%;
      max-width: 200px;
    }
    .blue-bg-card {
      height: 106px !important;
      width: 200px !important;
      display: flex;
      flex-direction: column;
      padding: 20px 10px;
      gap: 5px !important;
      h1 {
        margin: 0 !important;
      }
    }
    .chart-container {
      display: flex;
      flex-direction: column;
      padding: 0 0 0 20px !important;
      flex-grow: 1;
      align-items: flex-start;
      width: 100%;
      h3 {
        margin: 0 auto 20px calc(200px + 7vw);
      }

      .chart_card {
        margin: 0 !important;
      }
    }
  }
}

.manage-categories-wrapper {
  .homefeed {
    .homefeed-content {
      margin: 0 0 15px 0;
      .homefeed-item {
        align-items: center;
        .image-wrapper {
          height: 100px;
          width: 197px;
          min-width: 197px;
          border-radius: 7.81px;
          background-color: #f1f4f7;
          margin-right: 20px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .text-content {
          h3 {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 22px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
            margin: 0 0 4px 0;
          }
          p {
            color: var(--gray-color);
            font-family: "TTCommons-Regular";
            font-size: 18px;
            letter-spacing: 0;
            line-height: 19px;
            margin: 0;
          }
        }
        .actions-div {
          min-width: 90px;
          a {
            cursor: pointer;
            min-width: 40px;
            width: 40px;
            height: 40px;
            padding: 0;
            img {
              width: 100%;
              margin: 0;
              height: 100%;
            }
            + a {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

.nlink-secondary {
  font-size: 18px;
  line-height: 16px;
  color: var(--link-color);
  font-family: "TTCommons-Medium";
  font-weight: 500;
  text-decoration: none;
  border-bottom: 2px solid var(--link-color) !important;
  cursor: pointer;
  padding-top: 3px;
  padding-left: 0;
  padding-right: 0;
  transition: all 0.3s;
  text-decoration: none !important;
}

.promote-modal-wrapper {
  padding: 0 50px;
  .action-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .main-title {
    color: #000c3d;
    font-family: "TTCommons-DemiBold";
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    margin: 30px 0 12px 0 !important;
  }
  .main-description {
    color: #000c3d;
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    max-width: 449px;
    margin: auto;
  }
  .section-wrapper {
    margin: 30px 0;
    &.step-1 {
      margin: 20px 0;
      img {
        width: 100%;
      }
    }

    &.step-2 {
      margin: 20px 0;
      img {
        width: 100%;
      }
    }

    .item {
      display: flex;
      margin: 0 0 30px 0;
      .left {
        min-width: 136px;
        img {
          width: 100%;
          margin: auto;
        }
      }

      .right {
        margin: 0 0 0 20px;
        max-width: 384px;
        color: #000c3d;
        font-family: "TTCommons-Regular";
        text-align: left;
        h3 {
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 21px;
          margin: 0 0 5px 0;
        }
        h4 {
          font-size: 18px;
          letter-spacing: 0;
          line-height: 20px;
        }
      }
    }
  }
}

.checklist-main-wrapper {
  display: flex;
  width: 100%;
  .checklist-left-section {
    width: 100%;
    max-width: 350px;
    .checklist-categories {
      ul {
        li {
          border-radius: 20px !important;
          background-color: #ffffff !important;
          box-shadow: var(--card-box-shadow) !important;
          transition: all 0.3s;
          &:hover {
            box-shadow: var(--box-shadow) !important;
          }
          + li {
            margin-top: 15px !important;
          }
          > a {
            padding: 18px 20px 16px !important;
            > div.mr-3 {
              margin: 2px 20px 0 0 !important;
            }
            span {
              color: var(--primary-dark-color);
              font-family: "TTCommons-Medium";
              font-size: 22px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 24px;
              padding: 4px 0 0 0;
            }
            .actions-div {
              margin: 0 0 0 auto !important;
              a {
                height: 40px;
                width: 40px;
                min-width: 40px;
                border-radius: 100px !important;
                overflow: hidden;
                svg {
                  width: 100% !important;
                  height: 100% !important;
                }
                + a {
                  margin-left: 10px;
                }
              }
            }
          }
          &.active {
            a {
              background-color: var(--primary-color) !important;
              span {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  .checklist-right-section {
    padding-left: 20px;
    width: 100%;
    > .d-flex {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      .filter-right {
        button {
          color: var(--primary-dark-color);
          font-size: 16px;
          letter-spacing: 0;
          line-height: 17px;
          font-family: "TTCommons-Medium";
          font-weight: 500;
          background: transparent;
          box-shadow: var(--card-box-shadow) !important;
          background-color: var(--white-color);
          border-radius: 100px;
          padding: 6px 16px 2px 5px;
          display: flex;
          align-items: center;
          transition: all 0.3s;
          border: 0 !important;
          min-height: 40px;
          min-width: 116px;
          transition: all 0.3s;
          &:hover {
            box-shadow: var(--box-shadow) !important;
          }
          img {
            width: 30px;
            height: 30px;
            margin-right: 5px;
            margin-top: -4px;
          }
        }
      }
    }
    .checklist-items {
      li {
        .checklist-card {
          padding: 15px 30px 15px 20px !important;
          border-radius: 10px !important;
          box-shadow: var(--card-box-shadow) !important;
          transition: all 0.3s;
          &:hover {
            box-shadow: var(--box-shadow) !important;
          }
          .checklist-task {
            display: flex;
            align-items: center;
            span {
              margin: -2px 20px 0 0 !important;
            }
            .checklist-control {
              width: 100% !important;
              .title {
                width: 100% !important;
                .primaryTask {
                  color: var(--primary-dark-color);
                  font-family: "TTCommons-Medium";
                  font-size: 18px !important;
                  font-weight: 500 !important;
                  letter-spacing: 0 !important;
                  line-height: 16px !important;
                  padding: 4px 0 0 0;
                }
                a {
                  color: var(--gray-color) !important;
                  font-family: "TTCommons-Regular" !important;
                  font-size: 16px !important;
                  letter-spacing: 0 !important;
                  line-height: 18px !important;
                  border-bottom: 1px solid var(--gray-color) !important;
                  padding: 0 !important;
                }
              }
              .wizzard-form {
                width: 100%;
                margin-left: 0 !important;
                min-height: auto;
                form {
                  .max-width {
                    max-width: 100% !important;
                    margin: 0 !important;
                    .heading-wrapper {
                      width: 100%;
                      margin: 0 0 40px 0;
                      .task-preview {
                        width: 100%;
                        h4 {
                          color: var(--primary-dark-color);
                          font-size: 22px;
                          font-family: "TTCommons-Medium";
                          line-height: 20px;
                          word-break: break-word;
                          margin-bottom: -4px;
                        }
                        p {
                          color: var(--gray-color) !important;
                          font-family: "TTCommons-Regular" !important;
                          font-size: 16px !important;
                          letter-spacing: 0 !important;
                          line-height: 18px !important;
                          border-bottom: 1px solid var(--gray-color) !important;
                          padding: 0 !important;
                          display: inline-block;
                          margin: 0;
                        }
                      }
                      .add-task-actions {
                        margin: 0 !important;
                        button {
                          height: 40px;
                          min-height: 40px;
                          min-width: 116px;
                        }
                        a {
                          height: 40px;
                          width: 40px;
                          min-height: 40px;
                          min-width: 40px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          border-radius: 100px;
                          background-color: #f1f4f7;
                          border: 0 !important;
                          img {
                            padding: 0;
                            width: 15px;
                          }
                        }
                      }
                    }
                    .form-group {
                      align-items: center;
                      h4 {
                        white-space: nowrap;
                        color: var(--gray-color);
                        font-family: "TTCommons-Regular";
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 18px;
                        min-width: 70px;
                        margin: 0 15px 0 0;
                      }
                      .MuiInputBase-input {
                        font-family: "TTCommons-Medium";
                      }
                      &:last-child {
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
              .actions-div {
                display: flex;
                align-items: center;
                margin-left: auto;
                a {
                  display: inline-block;
                  width: 40px;
                  height: 40px;
                  min-width: 40px;
                  + a {
                    margin-left: 10px;
                  }
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.add-student-card {
  .single-select-wrap {
    .single-select-content {
      > div {
        .MuiPaper-root {
          min-height: 60px !important;
          padding: 18px 14px 14px;
        }
      }
    }
  }
}

.facelift-topics-wrapper {
  .topics-loader {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    .loader {
      left: auto !important;
    }
  }
  .filter-wrap {
    margin: -60px 0 5px 0;
  }
  .chatModule {
    margin-top: 10px;
    .row {
      > .col-md-3 {
        flex: 0 0 100%;
        max-width: 420px;
      }
      > .col-md-6 {
        flex: 0 0 100%;
        max-width: calc(100% - 420px);
        padding-right: 110px;
        @media (max-width: 1200px) {
          padding-right: 10px;
        }
      }
    }
    .ChatSearch.hide-search {
      display: none;
    }
    .ChatFilter {
      margin-top: 15px;
      position: sticky;
      top: 90px;
      .ChatFilterOptions {
        .MuiAccordion-root {
          background: transparent;
          border-radius: 0;
          padding: 0;
          box-shadow: none;
          overflow: visible;
          .MuiAccordionSummary-root {
            padding: 0;
            border-radius: 0;
            background: transparent;
            margin: 0 0 20px 0;
            .MuiButtonBase-root {
              padding: 0;
              border-radius: 0;
              background: transparent;
              margin: 0 0 15px 0;
              .MuiAccordionSummary-content {
                .MuiTypography-root {
                  .accordionHeader {
                    color: var(--primary-dark-color);
                    font-family: "TTCommons-Medium";
                    font-size: 22px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 24px;
                  }
                }
              }
            }
            .MuiAccordionSummary-expandIconWrapper {
              svg {
                color: var(--primary-dark-color);
              }
            }
          }
          .MuiAccordionDetails-root {
            padding: 0;
            margin: -15px -10px;
            margin-right: 0;
          }
        }
        .chatUserItems {
          padding: 15px 15px;
          overflow-y: scroll;
          max-height: calc(100vh - 200px);
          scrollbar-width: thin;
          -ms-overflow-style: auto;
          overscroll-behavior: none;
          scrollbar-width: thin; /* "auto" or "thin" */
          scrollbar-color: $color-light-grey-2 transparent; /* scroll thumb and track */
          transition: scrollbar-color 0.3s ease-out;

          &::-webkit-scrollbar {
            width: 8px; /* width of the entire scrollbar */
          }

          &::-webkit-scrollbar-track {
            background: transparent !important; /* color of the tracking area */
            border-radius: 50px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $color-light-grey-2 !important; /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
            border: none;
          }

          &::-webkit-scrollbar-corner {
            border-radius: 50%;
          }

          &::-webkit-scrollbar-thumb:hover {
            border: 0px;
          }
          .ChatItem {
            border-radius: 10px;
            background-color: #ffffff;
            box-shadow: var(--card-box-shadow);
            transition: all 0.3s;
            padding: 10px 15px 10px 10px;
            .img {
              width: 50px;
              height: 50px;
              border-radius: 100px;
              overflow: hidden;
              margin: 0 10px 0 0;
            }
            &:hover {
              box-shadow: var(--box-shadow);
            }
            &:before {
              display: none;
            }
            .usreDescription {
              h4 {
                margin: 0;
                color: var(--primary-dark-color);
                font-family: "TTCommons-Medium";
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 15px;
                padding: 4px 0 0 0;
              }
            }
            .ChatStatus {
              .badge {
                margin: 0 0 0 auto;
                height: 25px;
                width: 25px;
                border-radius: 100px;
                padding: 4px 0 0 0;
                font-size: 15px;
              }
            }
            + .ChatItem {
              margin-top: 10px;
            }
            &.active {
              background-color: var(--primary-color);
              .usreDescription {
                h4 {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
    .chatboxWrapper {
      .PostheadingWrap {
        margin: 0 0 10px 0;
        display: flex;
        align-items: center;
        min-height: 50px;
        h3 {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          padding: 4px 0 0 0;
        }
        .RequestWrap {
          button {
            height: 25px !important;
            min-height: 25px;
            padding: 3px 12px 0 !important;
            min-width: auto;
            color: #ffffff;
            font-family: "TTCommons-Regular";
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            background: var(--secondary-color) !important;
            border-color: var(--secondary-color) !important;
            margin-right: 8px;
            margin-left: -5px;
            white-space: nowrap;
          }
        }
        > button {
          margin-left: auto;
        }
      }
    }
  }
}

.topics-requests-popup {
  .MuiDialog-paper {
    padding: 0px;
    > div {
      padding: 30px;
      overflow: auto;
      position: relative;
      .MuiDialogContent-root {
        padding: 0;
      }
      .MuiDialogActions-root {
        padding: 0;
        margin: 0 !important;
        .cancel-icon {
          right: 35px;
        }
      }
    }
    .MuiDialogTitle-root {
      margin: 0 !important;
    }
    .RequestWrapShowing {
      h3 {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        margin: 0px 0 30px 0;
      }
      .RequestItem {
        display: flex;
        align-items: center;
        .ImgWrap {
          height: 40px;
          width: 40px;
          min-width: 40px;
          margin-right: 15px;
          border-radius: 100px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
          &.no-user-icon {
            img {
              width: 40px !important;
            }
          }
        }
        .RequestContent {
          width: 100%;
          text-align: left;
          h4 {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Medium";
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 20px;
          }
          p {
            color: var(--primary-dark-color);
            font-family: "TTCommons-Regular";
            font-size: 16px;
            letter-spacing: 0;
            line-height: 18px;
            margin: 0;
          }
        }
        .RequestAction {
          display: flex;
          align-items: center;
          span {
            height: 40px;
            width: 40px;
            min-width: 40px;
            border-radius: 100px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
            + span {
              margin-left: 20px;
            }
          }
        }
        + .RequestItem {
          margin-top: 24px;
          padding-top: 24px;
          border-top: 1px solid var(--icon_background);
        }
      }
    }
    .cancel-icon {
      top: 32px;
      right: 30px;
    }
  }
}

.facelift-topic-profile-popup {
  .MuiDialog-paper {
    div {
      overflow-y: scroll;
    }
    .MuiDialogContent-root {
      > div {
        overflow-y: initial;
      }
    }
    .MuiDialogTitle-root {
      margin: 0;
    }
    .ChatUserProfile {
      padding: 0;
      box-shadow: none;
      .UserAvtar {
        width: 90px;
        height: 90px;
        border-radius: 100px;
        margin: 0 auto 12px;
        overflow: hidden;
        &.no-user-image {
          img {
            width: 90px;
            height: 90px;
          }
        }
      }
      h3 {
        color: var(--primary-dark-color);
        font-family: "TTCommons-Medium";
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        margin: 0 0 6px 0;
      }
      .Description {
        color: var(--field-label-color);
        font-family: "TTCommons-Regular";
        font-size: 15px;
        letter-spacing: 0;
        line-height: 17px;
        margin: 0 0 15px 0;
        text-align: center;
        width: 100%;
        max-width: 100%;
      }
      .detailsinfo {
        margin: 0 0 15px 0;
        h5 {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Regular";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 17px;
          text-align: center;
          span {
            color: var(--field-label-color);
          }
        }
      }
      .total-participants {
        margin: 0 0 9px 0;
        h4 {
          color: var(--primary-dark-color);
          font-family: "TTCommons-Medium";
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 17px;
        }
      }
      .topic-creator {
        margin: 0 auto;
        max-width: 300px;
        align-items: center;
        display: flex;
        flex-direction: column;
      }
    }
    .cancel-icon {
      top: 30px;
      right: 30px;
    }
  }
}

.file-upload-content {
  .MuiDialogTitle-root {
    + .MuiDialogContent-root {
      overflow-x: hidden;
    }
  }
  .MuiDialog-container {
    .MuiPaper-root {
      overflow: hidden;
      > div {
        overflow: auto;
      }
      .MuiDialogActions-root {
        padding: 0 50px 30px 60px;
      }
    }
  }
}

.invite-employer-modal {
  .MuiDialog-container {
    .MuiPaper-root {
      padding: 0;
      max-width: 568px;
      .MuiDialogContent-root {
        width: 100% !important;
        padding: 40px 30px 40px;
        overflow-x: hidden;
        .MuiTypography-root {
          padding: 0 !important;
        }
      }
    }
  }
}

.profile-detailspx-popup {
  .MuiDialog-paper {
    padding: 20px;
    max-width: 325px;
    .MuiDialogTitle-root {
      margin: 0 !important;
    }
    .cancel-icon {
      top: 30px;
      right: 30px;
    }
    .MuiDialogActions-root {
      margin-top: 0 !important;
    }
  }
}
.active-features {
  color: #607790;
  font-size: 18px;
  line-height: 19px;
  margin-top: -16px;
}

.notification-unsubscribe {
  padding: 60px 0;
  display: flex;
  min-height: 100vh;
  @media (max-width: 1367px) {
    padding: 32px 16px;
  }
  > div {
    width: 100%;
  }
  .BrandLogo {
    margin: 0 auto 32px;
    position: relative;
    a {
      display: inline-block;
    }
  }
  .title {
    margin-bottom: 24px;
    @media (max-width: 767px) {
      font-size: 32px;
      line-height: 32px;
      margin: 0 0 16px 0;
    }
  }
}

.search-icon-btn {
  box-shadow: var(--card-box-shadow);
  border-radius: 50%;
}

.actions-div-wrapper {
  min-width: 180px;
  display: flex;
  justify-content: right;
  margin-top: auto;
  margin-bottom: auto;
}

.rounded-dropzone {
  border-radius: 42.5px !important;
  max-height: 85px !important;
  min-height: 85px !important;
  max-width: 85px !important;
  min-width: 85px !important;
  img {
    width: 120px;
    height: 120px;
  }
}

.rotate180 {
  transform: rotate(180deg);
  transition: all 0.3s;
}

.announcement-textbox {
  .MuiInputBase-formControl textarea.MuiInputBase-input {
    padding-top: 18px;
  }
}

.profile-details-popup {
  .MuiDialog-container {
    > .MuiPaper-root {
      max-width: 325px;
      overflow: hidden;
      padding: 0;
      > div {
        padding: 30px 30px 30px;
        overflow: auto;
        ::-webkit-scrollbar {
          width: 6px;
        }

        ::-webkit-scrollbar-track {
          border-radius: 5px;
        }

        ::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          height: 10px !important;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: rgba(0, 0, 0, 0.5);
        }
        .MuiDialogTitle-root {
          margin: 0 !important;
        }
        .MuiDialogContent-root {
          padding: 0;
        }
        .MuiDialogActions-root {
          margin: 0 !important;
          .cancel-icon {
            right: 20px;
            top: 20px;
          }
        }
      }
    }
  }
}

.report-chat-popup {
  .MuiDialog-container {
    > .MuiPaper-root {
      max-width: 500px !important;
      overflow: hidden;
      padding: 0;
      .MuiDialogActions-root {
        padding: 0 !important;
      }
    }
  }
  .report-chat-popup-loader {
    min-height: 181px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.assign-message-popup {
  .MuiDialog-container {
    > .MuiPaper-root {
      padding: 0 !important;
      height: 600px;
      max-width: initial;
      @media (min-width: 770px) {
        max-width: 85%;
      }
      @media (min-width: 900px) {
        max-width: 850px;
      }
      @media (min-width: 1000px) {
        max-width: 900px;
      }
      > div {
        overflow: auto;
        ::-webkit-scrollbar {
          width: 6px;
        }
        ::-webkit-scrollbar-track {
          border-radius: 5px;
        }
        ::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          height: 10px !important;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: rgba(0, 0, 0, 0.5);
        }
        .MuiDialogTitle-root {
          margin: 0 !important;
        }
        .MuiDialogContent-root {
          padding: 30px !important;
        }
        .MuiDialogActions-root {
          margin: 0 !important;
          .cancel-icon {
            right: 20px;
            top: 20px;
          }
        }
      }
    }
  }
}

.email-tempates-form-wrapper {
  margin-top: -67px;
}

.homefeed-final-preview.main-post-card {
  .box-wrap {
    .post-header {
      margin-bottom: 16px;
    }
    .right {
      h5 {
        margin-bottom: 0;
      }
    }
  }
  .slider-container-preview {
    margin: 0 20px 40px 20px;
  }
}

.notification-switch {
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;
  margin-bottom: 10px;
  @media (max-width: 480px) {
    flex-wrap: wrap;
  }
  .notification-switch-content {
    background-color: #ffffff;
    box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
    border-radius: 100px;
    padding: 18px 20px 13px;
    display: flex;
    align-items: center;
    color: #000c3d;
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    + .notification-switch-content {
      margin-left: 10px;
      @media (max-width: 480px) {
        margin-left: 0;
      }
    }
    @media (max-width: 480px) {
      padding: 12px 12px 10px;
      margin: 0 8px;
    }
    .MuiSwitch-root {
      margin-left: 10px;
    }
  }
  .MuiFormGroup-root {
    margin-left: 10px;
    @media (max-width: 480px) {
      margin-left: 0;
    }
  }

  &-top {
    display: flex;
    &-span {
      margin-right: 115px;
    }
  }
  &-bottom {
    margin-top: 7px;
    display: flex;
    &-span {
      margin-right: 25px;
    }
  }
}

.panel-actions {
  margin-left: auto;
  margin-bottom: auto;

  .panel-action-label {
    display: flex;
    align-items: center;
    white-space: nowrap;
    .switch-container {
      background-color: #ffffff;
      box-shadow: 0 1px 14px 3px rgba(209, 221, 229, 0.4);
      border-radius: 100px;
      padding: 18px 20px 13px;
      display: flex;
      align-items: center;
      color: #000c3d;
      font-family: "TTCommons-Medium";
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      @media (max-width: 767px) {
        padding: 13px 12px 10px;
      }
      + .switch-container {
        margin-left: 10px;
      }
      .MuiSwitch-root {
        margin-left: 10px;
      }
    }
    .MuiFormGroup-root {
      margin-left: 10px;
      @media (max-width: 767px) {
        margin-left: 2px;
      }
    }
  }
}

.MuiInputBase-multiline {
  overflow: hidden;
  border-radius: 20px !important;
  .MuiInputBase-inputMultiline {
    &::-webkit-scrollbar {
      width: 8px; /* width of the entire scrollbar */
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      background: transparent !important; /* color of the tracking area */
      border-radius: 50px;
      box-shadow: none;
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d1dde5 !important; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: none;
      height: 10px !important;
      cursor: pointer;
      width: 8px;
    }

    &::-webkit-scrollbar-corner {
      border-radius: 50%;
    }

    &::-webkit-scrollbar-thumb:hover {
      border: 0px;
      cursor: pointer;
    }
  }
}

.company-logo-upload {
  .dropzone {
    width: 111px;
    max-width: 111px !important;
    height: 111px !important;
    border-radius: 100px;
    margin: 0 auto 10px;
    min-height: 111px;
    img {
      max-width: 100% !important;
      height: auto !important;
      margin: 0;
    }
  }
}

.add-school-logo {
  .dropzone {
    border-radius: 5px !important;
    width: auto !important;
    height: auto !important;
    margin: 0 auto 10px;
    min-height: auto !important;
  }
}

.discover-details {
  display: flex;
  align-items: center;
  gap: 20px;
  .creator-info {
    display: flex;
    align-items: center;
    gap: 7px;
    color: var(--gray-color);
    .created-by-name {
      color: var(--primary-dark-color);
    }
    h5 {
      margin: 5px 0 0 0;
    }
  }
  p {
    margin: 5px 0 0 0 !important;
    font-family: "TTCommons-Medium" !important;
  }
}

#topics {
  cursor: initial;
}

.image-video-gallery-popup {
  .MuiDialog-container {
    .MuiPaper-root {
      padding: 0;
      max-width: 900px;
      #confirmation-dialog-title {
        display: none;
      }
      > div {
        padding: 30px;
        position: relative;
        overflow: auto;
        .MuiDialogContent-root {
          overflow: hidden;
          padding: 25px 0 0 0;
          .model-body {
            margin: 0;
            img {
              max-height: calc(100vh - 160px);
            }
            iframe {
              width: 100%;
              max-height: calc(100vh - 160px);
            }
          }
        }
        .MuiDialogActions-root {
          margin: 0 !important;
          padding: 0;
          img {
            right: 30px;
            top: 30px;
          }
        }
      }
    }
  }
}

.notification-filters {
  max-width: 80%;
  display: flex;
  justify-content: center;
  margin: auto;
}
.file-upload-pdf-preview {
  width: 100%;
  max-width: 300px;
  height: 242px;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  img {
    height: auto;
    max-height: 242px;
    object-fit: contain;
    object-position: center;
    cursor: pointer;
  }
  .pdf-preview-tag {
    left: 62px !important;
  }
}
.resource-pdf-preview {
  width: 100%;
  max-width: 300px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  img {
    height: auto;
    max-height: 242px;
    object-fit: contain;
    object-position: center;
    cursor: pointer;
  }
  .pdf-preview-tag {
    left: 8px !important;
  }
}

.widget-instructions-wrapper {
  .main-title {
    color: #000c3d;
    font-family: "TTCommons-DemiBold";
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    margin: 12px 0 12px 0 !important;
  }
  .main-description {
    color: #000c3d;
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    max-width: 449px;
    margin: auto;
  }
  ol {
    padding-inline-start: 16px;
    li {
      color: #000c3d;
      font-family: "TTCommons-Regular";
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: left;
      margin-top: 0.5rem;
      a {
        color: var(--primary-dark-color);
        display: inline-block;
        text-decoration: none;
        font-family: "TTCommons-Demibold";
      }
      code {
        color: #188038;
      }
    }
  }
  pre {
    margin: 12px 0 18px 0 !important;
    &::-webkit-scrollbar {
      width: 8px; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: transparent !important; /* color of the tracking area */
      border-radius: 50px;
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d1dde5 !important; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: none;
      height: 10px !important;
    }

    &::-webkit-scrollbar-corner {
      border-radius: 50%;
    }

    &::-webkit-scrollbar-thumb:hover {
      border: 0px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0 30px 0;
    button {
      padding: 16px 20px 12px 12px;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
      font-family: "TTCommons-Medium";
      font-weight: 500;
      background: transparent;
      color: var(--primary-dark-color);
      box-shadow: var(--card-box-shadow) !important;
      background-color: var(--white-color);
      border-radius: 100px;
      display: flex;
      align-items: center;
      transition: all 0.3s;
      height: 50px;
      > label {
        margin: 0 !important;
      }
      &.Mui-disabled {
        opacity: 0.4;
      }

      img {
        width: 32px;
        vertical-align: middle;
        margin: -3px 2px 0 0;
      }

      .text-label {
        margin-top: 0px;
      }

      + button {
        margin-left: 10px;
      }

      &:hover {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
      }
    }
    .icon-button {
      margin-top: 6px;
      cursor: pointer;
    }
  }
}

.center-align {
  text-align: center;
}

.chat-reaction-tooltip {
  &__emoji {
    text-align: center;
    font-family: "TTCommons-Regular", "NotoColorEmoji-Regular";
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 0 0;
  }
  &__text {
    margin: 8px 0 0 0;
    color: var(--primary-dark-color);
    font-family: "TTCommons-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }
}
